import { useEffect, useRef, useState } from 'react'
import { logo, theme_bank } from '@src/constants/bank.constants'
import { getActivePageColor } from '@src/constants/navbar/navbar.constant'
import { getPWADisplayMode } from '@src/utils/common'
import {
  isBrowserAndroidChrome,
  isBrowserAndroidFacebook,
  isBrowserIOSChrome,
  isBrowserIOSInAppFacebook,
  isBrowserIOSInAppInstagram,
  isBrowserIOSInAppLinkedin,
  isBrowserIOSInAppThreads,
  isBrowserIOSInAppTwitter,
  isBrowserIOSSafari,
  isDesktopChrome,
  isDesktopEdge,
  isDesktopSafari,
  isDeviceAndroid,
  isDeviceIOS,
} from '@src/utils/install-pwa-guide'
import { getInstallPWA, setIsWantToInstallPWA } from '@src/utils/local-storage'
import { getThemeBank, setThemeColor } from '@src/utils/theme-color'

import ModalBottom from '../modal/modal-bottom'

import InstallButton from './install-button'
import {
  IDesktopSafariDock,
  IDesktopSafariMenu,
  IGenericMoreButton,
  IGenericMoreButton2,
  IIosChromeMoreButton2,
  IIosSafariAddToHomeScreenButton2,
  IIosSafariSharingApiButton2,
  IOpeninsafariButton,
  IUpload,
} from './install-icon'

const InstalPwa = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isInstalled, setIsInstalled] = useState(false)
  const installPrompt = useRef<any>(null)
  const [content, setContent] = useState<boolean | JSX.Element[]>(false)

  useEffect(() => {
    const installPWA = (event: any) => {
      event.preventDefault()
      installPrompt.current = event
    }
    window.addEventListener('beforeinstallprompt', installPWA)
    return () => {
      window.removeEventListener('beforeinstallprompt', installPWA)
    }
  }, [])

  const handleClose = () => {
    setIsWantToInstallPWA(false)
    setIsOpen(false)
    setThemeColor(getActivePageColor().themeColor)
  }

  useEffect(() => {
    async function checkInstalled() {
      let _isInstalled = false
      if ('getInstalledRelatedApps' in window.navigator) {
        const relatedApps = await (navigator as any).getInstalledRelatedApps()
        console.log(relatedApps)

        relatedApps.forEach((_: any) => {
          setIsInstalled(true)
          _isInstalled = true
        })
      }
      const contentData = getContentGuide()
      setContent(contentData)
      setIsOpen(getPWADisplayMode() === 'browser' && contentData != false && (getInstallPWA() || _isInstalled))
    }

    checkInstalled()
  }, [])

  return (
    <>
      <ModalBottom disableChangeColorTheme isOpen={isOpen} onClose={handleClose}>
        <div className='w-full px-6'>
          <div className='flex items-center gap-4 border-b border-soft-200 py-4'>
            <div className='h-6'>
              <img src={logo[getThemeBank()]} className='h-full' />
            </div>
            <div className='flex-1'>
              <p className='text-lg font-semibold text-strong-950'>{theme_bank[getThemeBank()]?.htmlTitle}</p>
              <p className='mt-1 text-sm font-normal text-soft-400'>
                Cài đặt {theme_bank[getThemeBank()]?.htmlTitle} trên thiết bị của bạn
              </p>
            </div>
          </div>

          <div className='flex flex-col gap-6 py-4'>
            <p>
              Trang web này hỗ trợ tính năng ứng dụng. Thêm nó vào màn hình chính để có trải nghiệm mượt mà và truy cập
              dễ dàng.
            </p>

            {typeof content == 'boolean' ? (
              <InstallButton installPrompt={installPrompt} isInstalled={isInstalled} handleClose={handleClose} />
            ) : (
              <>
                {content.map((item, index) => (
                  <div key={index} className='flex items-center gap-3'>
                    <p className='flex size-6 items-center justify-center rounded-[7.5px] border border-primary text-sm font-semibold text-secondary'>
                      {index + 1}
                    </p>
                    <p className='flex-1 text-wrap text-sm font-medium text-strong-950'>{item}</p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </ModalBottom>
    </>
  )
}

export default InstalPwa

function getContentGuide() {
  let shouldShowModal = true
  if (isDeviceIOS()) {
    // IOS
    if (isBrowserIOSSafari()) {
      return [
        <>
          Bấm nút <IIosSafariSharingApiButton2 /> tại thanh công cụ.
        </>,
        <>
          Chọn <IIosSafariAddToHomeScreenButton2 /> từ menu đã hiển thị. Bạn có thể cần phải cuộn xuống để tìm mục này.
        </>,
      ]
    } else if (isBrowserIOSChrome()) {
      return [
        <>
          Bấm nút <IIosChromeMoreButton2 /> tại góc phía trên bên phải.
        </>,
        <>
          Chọn <IIosSafariAddToHomeScreenButton2 /> từ menu đã hiển thị. Bạn có thể cần phải cuộn xuống để tìm mục này.
        </>,
      ]
    } else if (isBrowserIOSInAppFacebook() || isBrowserIOSInAppLinkedin()) {
      return [
        <>
          Bấm nút <IGenericMoreButton /> phía dưới.
        </>,
        <>Mở trong trình duyệt.</>,
      ]
    } else if (isBrowserIOSInAppInstagram()) {
      return [
        <>
          Bấm nút <IUpload /> phía dưới.
        </>,
        <>
          Chọn <IOpeninsafariButton /> từ menu đã hiển thị. Bạn có thể cần phải cuộn xuống để tìm mục này.
        </>,
      ]
    } else if (isBrowserIOSInAppThreads() || isBrowserIOSInAppTwitter()) {
      return [
        <>
          Bấm nút <IOpeninsafariButton /> phía dưới để mở trình duyệt từ hệ thống.
        </>,
      ]
    } else {
      shouldShowModal = false
    }
  } else if (isDeviceAndroid()) {
    // Android
    if (isBrowserAndroidChrome()) {
      shouldShowModal = true
      // return [
      //   <>
      //     Bấm <IAndroidChromeMoreButton2 /> tại thanh trình duyệt.
      //   </>,
      //   <>
      //     Bấm <IAndroidChromeAddToHomeScreenButton2 /> thêm vào màn hình chính
      //   </>,
      // ]
    } else if (isBrowserAndroidFacebook()) {
      return [
        <>
          Bấm nút <IGenericMoreButton2 /> phía trên.
        </>,
        <>Mở trong trình duyệt.</>,
      ]
    } else {
      shouldShowModal = false
    }
  } else {
    // Desktop
    if (isDesktopChrome() || isDesktopEdge()) {
      shouldShowModal = true
    } else if (isDesktopSafari()) {
      return [
        <>
          Bấm <IDesktopSafariMenu /> tại thanh công cụ.
        </>,
        <>
          Chọn <IDesktopSafariDock /> Thêm vào Dock
        </>,
      ]
    }
  }
  return shouldShowModal
}
