export function IWarning() {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M22.2605 15.92L15.8605 4.4C15.0005 2.85 13.8105 2 12.5005 2C11.1905 2 10.0005 2.85 9.14047 4.4L2.74047 15.92C1.93047 17.39 1.84047 18.8 2.49047 19.91C3.14047 21.02 4.42047 21.63 6.10047 21.63H18.9005C20.5805 21.63 21.8605 21.02 22.5105 19.91C23.1605 18.8 23.0705 17.38 22.2605 15.92ZM11.7505 9C11.7505 8.59 12.0905 8.25 12.5005 8.25C12.9105 8.25 13.2505 8.59 13.2505 9V14C13.2505 14.41 12.9105 14.75 12.5005 14.75C12.0905 14.75 11.7505 14.41 11.7505 14V9ZM13.2105 17.71C13.1605 17.75 13.1105 17.79 13.0605 17.83C13.0005 17.87 12.9405 17.9 12.8805 17.92C12.8205 17.95 12.7605 17.97 12.6905 17.98C12.6305 17.99 12.5605 18 12.5005 18C12.4405 18 12.3705 17.99 12.3005 17.98C12.2405 17.97 12.1805 17.95 12.1205 17.92C12.0605 17.9 12.0005 17.87 11.9405 17.83C11.8905 17.79 11.8405 17.75 11.7905 17.71C11.6105 17.52 11.5005 17.26 11.5005 17C11.5005 16.74 11.6105 16.48 11.7905 16.29C11.8405 16.25 11.8905 16.21 11.9405 16.17C12.0005 16.13 12.0605 16.1 12.1205 16.08C12.1805 16.05 12.2405 16.03 12.3005 16.02C12.4305 15.99 12.5705 15.99 12.6905 16.02C12.7605 16.03 12.8205 16.05 12.8805 16.08C12.9405 16.1 13.0005 16.13 13.0605 16.17C13.1105 16.21 13.1605 16.25 13.2105 16.29C13.3905 16.48 13.5005 16.74 13.5005 17C13.5005 17.26 13.3905 17.52 13.2105 17.71Z'
        fill='currentColor'
      />
    </svg>
  )
}
