import { useNavigate } from 'react-router-dom'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import { IScan } from '@src/constants/icons/transaction.icon'
import { OPEN } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { TTransaction } from '@src/types/transaction'
import { formatCurrency, formatTransactionStatus, formatUserNumber, hh_mm_dd_mm_yyyy } from '@src/utils/transaction'

import TransactionDetail from './transaction-detail'

export const TransactionCard = ({ transaction }: { transaction: TTransaction }) => {
  const { getParams, setParams, deleteParams } = useQueryParams()
  const isOpenDetail = getParams(OPEN) === transaction.bill_id
  const navigate = useNavigate()

  return (
    <div
      data-status={transaction.status}
      className='group data-[status=canceled]:text-faded-base data-[status=failed]:text-error-base data-[status=pending]:text-secondary data-[status=success]:text-primary'
    >
      <div
        className='flex cursor-pointer items-center gap-3 px-3 py-2 active:bg-soft-200/30'
        onClick={() => {
          setParams({ [OPEN]: transaction.bill_id })
        }}
      >
        <IconWrapper isNew size={34} rounded='12px'>
          <IScan />
        </IconWrapper>
        <div className='flex flex-1 flex-col gap-0.5'>
          <div className='flex items-center text-sm'>
            {/* id */}
            <p className='flex-1 font-semibold tracking-[-0.14px]'>{formatUserNumber('123138183713271')}</p>
            {/* amount */}
            <p className='font-bold tracking-[-0.14px] text-strong-950'>
              {transaction.status === 'success' ? '+' : ''}
              {formatCurrency(transaction.amount)}đ
            </p>
          </div>

          <div className='flex items-center'>
            {/* user name */}
            <p className='flex-1 text-sm font-medium tracking-[-0.14px] text-strong-950'>
              {transaction?.from?.name || 'Tran Van A'}
            </p>

            {/* status */}
            <p className='text-xs font-normal tracking-[-0.12px] group-data-[status=success]:text-success-base'>
              {formatTransactionStatus(transaction.status).short}
            </p>
          </div>
          <p className='text-xs leading-4 tracking-[-0.12px] text-soft-400'>
            {hh_mm_dd_mm_yyyy(new Date(transaction.created_at))}
          </p>
        </div>
      </div>

      <TransactionDetail
        isOpen={isOpenDetail}
        onClose={() => {
          try {
            navigate(-1)
          } catch (error) {
            deleteParams(OPEN)
          }
        }}
        transaction={transaction}
      />
    </div>
  )
}

export const SkeletonTransactionCard = () => {
  return (
    <div className='w-full'>
      <div className='flex w-full cursor-pointer items-center gap-3 px-3 py-2'>
        <div className='size-[34px] animate-pulse rounded-xl bg-soft-200'></div>
        {/* <IconWrapper isNew size={34} rounded='12px'>
          <IScan />
        </IconWrapper> */}
        <div className='flex flex-1 flex-col gap-0.5'>
          <div className='flex h-5 items-stretch justify-between'>
            <p className='w-[50%] animate-pulse rounded bg-soft-200'></p>
            <p className='w-[30%] animate-pulse rounded bg-soft-200'></p>
          </div>

          <div className='flex items-center justify-between'>
            <p className='h-5 w-[40%] animate-pulse rounded bg-soft-200'></p>
            <p className='h-4 w-[20%] animate-pulse rounded bg-soft-200'></p>
          </div>
          <p className='h-4 w-[60%] animate-pulse rounded bg-soft-200'></p>
        </div>
      </div>
    </div>
  )
}
