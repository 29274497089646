import { TPaymentMethod, TTransaction } from '@src/types/transaction'

export const formatUserNumber = (userId: string) => {
  return userId.slice(0, 4) + 'X'.repeat(userId.length - 8) + userId.slice(-4)
}
export const formatCurrency = (amount: number) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatTime = (dateString: Date) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

export const formatTransactionStatus = (status: TTransaction['status']) => {
  switch (status) {
    case 'success':
      return { full: 'Giao dịch thành công', short: 'Thành công' }
    case 'pending':
      return { full: 'Chờ xử lý', short: 'Chờ xử lý' }
    case 'fail':
      return { full: 'Giao dịch thất bại', short: 'Thất bại' }
    // case 'canceled':
    //   return { full: 'Đã hủy', short: 'Đã huỷ' }
    default:
      return { full: 'Lỗi hệ thống', short: 'Lỗi' }
  }
}

export const hh_mm_dd_mm_yyyy = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${hours}:${minutes} - ${day}/${month}/${year}`
}

export const convertTimestampToDateString = (timestamp: number) => {
  console.log('datadade')
  const date = new Date(timestamp * 1000)

  console.log('datadade', date)
  return formatTime(date)
}

export const formatParams = (obj: any) => {
  const queryString = Object.keys(obj)
    .map((key) => {
      const value = obj[key]
      return encodeURIComponent(key) + '=' + encodeURIComponent(value ?? '')
    })
    .join('&')
  return queryString
}

export const getTitlePaymentMethod = (method: TPaymentMethod | 'all', prefix = '') => {
  switch (method) {
    case 'cash':
      return prefix + 'Tiền mặt'
    case 'card':
      return prefix + 'Thẻ'
    case 'ecom':
      return prefix + 'E-com'
    case 'va':
      return prefix + 'VA'
    case 'wallet':
      return prefix + 'Ví'
    default:
      return 'Tất cả ' + prefix
  }
}
