export function IBVBankLogo() {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.58212 1.97192L14.6298 19.3424L4.58212 36.713C3.75343 36.2295 3.1665 35.3661 3.1665 34.2611V4.42383C3.1665 3.31874 3.75343 2.4554 4.58212 1.97192Z'
        fill='url(#paint0_radial_1227_22683)'
      />
      <path
        d='M14.594 19.342H34.655C34.655 20.309 34.1714 21.2414 33.2388 21.794L7.3781 36.7126C6.44604 37.2651 5.37557 37.1961 4.54688 36.7126L14.594 19.342Z'
        fill='#2B3278'
      />
      <path
        d='M34.6551 19.3425H14.5947L4.58203 1.97194C5.41073 1.48847 6.4812 1.4194 7.41325 1.97194L33.2745 16.8906C34.2066 17.4431 34.6901 18.3755 34.6901 19.3425H34.6551Z'
        fill='#03C2F3'
      />
      <defs>
        <radialGradient
          id='paint0_radial_1227_22683'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(5.75595 5.04543) scale(28.5197 28.5249)'
        >
          <stop stopColor='#03C2F3' />
          <stop offset='1' stopColor='#2B3278' />
        </radialGradient>
      </defs>
    </svg>
  )
}

export function IOCBankLogo() {
  return (
    <svg width='38' height='38' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.0969818 25.7675C0.753895 31.2876 3.25135 36.1538 6.93392 39.7017C6.53888 38.5181 6.40719 37.3345 6.67055 36.281C7.45914 32.995 10.7452 30.7624 15.348 30.104V14.3292C3.25135 12.2253 -0.69309 19.8508 0.0969818 25.7675Z'
        fill='#E49F15'
      />
      <path
        d='M46.1075 20.6398C45.4491 15.1182 42.9531 10.255 39.272 6.70557C39.6671 7.75752 39.7973 8.94114 39.5339 10.1233C38.7453 13.4108 35.4593 15.6449 30.8579 16.3033V32.0781C42.9531 34.1805 46.7644 26.4248 46.1075 20.6398Z'
        fill='#E49F15'
      />
      <path
        d='M0.490723 18.0104C3.64509 13.6709 9.29841 11.1734 19.6847 14.1976C37.957 19.3242 43.8722 7.36069 33.4873 2.49745C30.3329 0.92027 26.7821 0 23.1025 0C12.0592 0.00147954 2.85798 7.7572 0.490723 18.0104Z'
        fill='#E49F15'
      />
      <path
        d='M45.7119 28.2632C42.5576 32.6027 36.9043 35.1001 26.5194 32.0759C8.11546 26.9479 2.19882 38.9114 12.5837 43.9063C15.738 45.485 19.2874 46.4038 22.9685 46.4038C34.1435 46.2721 43.3476 38.648 45.7119 28.2632Z'
        fill='#E49F15'
      />
    </svg>
  )
}
