import { TShop } from '@src/types/shop'
import { create } from 'zustand'

type State = {
  currentShop?: TShop
}

type Actions = {
  setCurrentShop: (branch: TShop) => void
}

export const useShopStore = create<State & Actions>()((set) => ({
  currentShop: undefined,
  setCurrentShop: (shop: TShop) => set((state) => ({ ...state, currentShop: shop })),
}))
