import { useEffect } from 'react'
import { getActivePageColor } from '@src/constants/navbar/navbar.constant'
import { wait } from '@src/utils/common'
import { setShowIntro } from '@src/utils/local-storage'
import { setThemeColor } from '@src/utils/theme-color'
import { AnimatePresence, motion } from 'framer-motion'

import IRocket from './rocket.icon'
const ToastUpdateVersion = ({ message }: { message: string | React.ReactNode }) => {
  useEffect(() => {
    setThemeColor(getActivePageColor().themeColorOverlay)
  }, [])

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className='absolute -inset-4 flex items-center bg-overlay p-4 sm:inset-x-0'
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ delay: 0.3 }}
          className='w-full rounded-2xl bg-white p-4 py-6 shadow-md'
        >
          <div className='relative aspect-[1/0.255] w-full'>
            <div className='relative h-[calc(100%+1.5rem)] w-full -translate-y-6 overflow-hidden'>
              <div className='absolute left-1/2 top-6 aspect-square w-[51%] -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondary/10'></div>
              <div className='absolute left-1/2 top-6 aspect-square w-[44%] -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondary/15'></div>
            </div>
            <div className='absolute left-1/2 top-0 aspect-square w-[37%] -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondary'>
              <span className='absolute left-1/2 top-1/2 w-[70%] -translate-x-[45%] -translate-y-1/2'>
                <IRocket />
              </span>
            </div>
          </div>

          <div className='flex w-full flex-col items-center'>
            <div className='w-full text-center text-lg font-bold text-strong-950'>Phiên bản mới đã sẵn sàng</div>
            <div className='w-full text-center text-sm font-light text-strong-950'>Hãy cập nhật để trải nghiệm!</div>
            <button
              data-load={false}
              className='group mt-6 flex w-fit scale-100 items-center gap-2 rounded-full bg-primary px-5 py-1.5 text-base font-semibold text-white shadow transition-all data-[load=true]:scale-125 data-[load=true]:gap-0 data-[load=true]:px-2.5 data-[load=false]:pl-4'
              onClick={async (e) => {
                if ('serviceWorker' in navigator) {
                  navigator.serviceWorker.ready.then((registration) => {
                    registration.active?.postMessage({ type: 'CLEAR_CACHE' })
                  })
                }

                e.currentTarget.setAttribute('data-load', 'true')
                setShowIntro(true)
                await wait(2000)
                window.location.reload()
              }}
            >
              <ProgressButton />
            </button>

            <div className='mt-4 w-full text-center text-sm font-light text-strong-950/80'>Version: {message}</div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
}

export default ToastUpdateVersion

function ProgressButton() {
  return (
    <>
      <span className='relative size-[18px] transition-all group-data-[load=true]:scale-[1.8]'>
        <svg
          className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-100 transition-all duration-300 group-data-[load=true]:scale-0'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
          <polyline points='7 10 12 15 17 10' />
          <line x1='12' x2='12' y1='15' y2='3' />
        </svg>

        <span className='absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 scale-0 items-center justify-center text-secondary transition-all delay-[350ms] duration-300 group-data-[load=true]:scale-100'>
          <svg width='16' height='16' className='animate-spin' viewBox='0 0 20 20' fill='none'>
            <path
              d='M19.0073 10C19.5555 10 20.0051 10.4456 19.9508 10.9912C19.7381 13.1267 18.8424 15.1472 17.3831 16.7446C15.9238 18.342 13.9924 19.4164 11.8848 19.8208C11.3463 19.9241 10.862 19.5166 10.8125 18.9705V18.9705C10.7631 18.4245 11.1679 17.9479 11.7037 17.8314C13.3175 17.4803 14.7924 16.6368 15.9172 15.4055C17.0421 14.1741 17.7491 12.6292 17.9531 10.9903C18.0209 10.4462 18.459 10 19.0073 10V10Z'
              fill='currentColor'
            />
          </svg>
        </span>
      </span>

      <span className='max-w-[70px] overflow-hidden whitespace-nowrap text-nowrap opacity-100 transition-all duration-300 group-data-[load=true]:max-w-0 group-data-[load=true]:opacity-0'>
        Cập nhật
      </span>
    </>
  )
}
