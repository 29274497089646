import { home_banner } from '@src/constants/bank.constants'
import { ex_modules } from '@src/constants/home.constants'
import { IQuestion } from '@src/constants/icons/header.icon'
import { getThemeBank } from '@src/utils/theme-color'

import { LayoutHeader } from '../common/layout-header'
import { CarouselSection } from '../home/carousel-section'
import { openToast } from '../toaster/custom-toast'

import BlueNote from './blue-note'

const Reward = () => {
  return (
    <div className='flex h-full flex-col bg-weak-50'>
      <LayoutHeader
        title={'Điểm thưởng'}
        rightItem={
          <span
            className='text-primary active:opacity-active'
            onClick={() => {
              openToast({
                message: 'Sắp ra mắt!',
              })
            }}
          >
            <IQuestion />
          </span>
        }
      />

      <div className='flex-1 overflow-auto'>
        {/*  */}
        <BlueNote />

        <div className='flex w-full flex-col gap-2 p-4 pt-2'>
          {/*  */}
          <div className='w-full p-4'>
            <button className='w-full rounded-full bg-primary/10 py-3 text-center text-base font-medium text-primary active:opacity-active'>
              Danh sách tài khoản điểm
            </button>
          </div>

          {/*  */}
          <div className='w-full'>
            <img src={home_banner[getThemeBank()]} alt='' className='h-[166px] w-full rounded-2xl object-cover' />
          </div>

          {/*  */}
          <div>
            <CarouselSection id='reward_items' modules={ex_modules} title={'Danh mục quà tặng'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reward
