import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconLocked, IconTick } from '@src/constants/icons/icon-sign-in'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { authService } from '@src/store/services/auth.service'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

import { InputField } from '../sign-in/input-field'
import { openToast } from '../toaster/custom-toast'

const formSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .test('custom-validation', 'Please enter a valid password', (value) => {
      if (value) {
        const normalized = value
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s/g, '')
        return normalized === value
      }
      return false
    }),
  confirm_password: yup
    .string()
    .required('Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match')
    .test('custom-validation', 'Please enter a valid password', (value) => {
      if (value) {
        const normalized = value
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s/g, '')
        return normalized === value
      }
      return false
    }),
})

type FormFields = yup.InferType<typeof formSchema>

const ResetPassword = () => {
  const [searchParams, _] = useSearchParams()
  const navigate = useNavigate()
  const token = searchParams.get('token')
  const [currentStep, setCurrentStep] = useState<number>(1)
  useEffect(() => {
    if (!token) {
      navigate(ROUTES.sign_in)
    }
  }, [token, navigate])

  const {
    register,
    watch,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(formSchema),
  })
  const onSubmit = () => {
    clearErrors()
    reset_password()
  }

  const { mutate: reset_password, isPending } = useMutation({
    mutationFn: async () =>
      await authService.reset_password({
        token: token,
        new_password: watch('password'),
      }),
    onSuccess: () => {
      openToast({
        type: 'success',
        message: 'Đổi mật khẩu thành công',
        duration: 3000,
      })
      setCurrentStep(2)
    },
    onError: () => {
      openToast({
        type: 'error',
        message: 'Đổi mật khẩu thất bại',
        duration: 3000,
      })
    },
  })

  return currentStep == 2 ? (
    <div className='px-4 pt-[128px]'>
      <div className='flex w-full flex-col items-center gap-16'>
        <div className='flex w-full flex-col items-center gap-3'>
          <div className='flex h-[76px] w-[76px] items-center justify-center rounded-full bg-primary'>
            <IconTick />
          </div>
          <p className='text-[20px] font-semibold leading-6 text-strong-950'>Đổi mật khẩu thành công</p>
        </div>

        <a
          href={ROUTES.sign_in}
          className='flex h-[48px] w-full items-center justify-center rounded-xl bg-primary text-base font-medium text-white active:opacity-active disabled:opacity-50 sm:shadow-[0px_12px_21px_4px_rgba(68,97,242,0.15)]'
        >
          Quay lại trang đăng nhập
        </a>
      </div>
    </div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className='flex h-full w-full flex-col items-center gap-[64px] px-4 pt-8'>
      <div className='flex w-full flex-col gap-[32px]'>
        <div className='flex h-full w-full flex-col items-center gap-3'>
          <div className='flex h-[76px] w-[76px] items-center justify-center rounded-full border-2 border-primary border-opacity-[0.16]'>
            <IconLocked isCircle />
          </div>
          <p className='text-xl font-semibold tracking-[-2%] text-strong-950'>Mật khẩu mới</p>
        </div>
        <div className='flex w-full flex-col gap-9 px-2'>
          <div className='flex flex-col gap-4'>
            <InputField
              isPassword
              required={true}
              placeholder={'Vui lòng nhập mật khẩu mới'}
              register={register}
              registerValue='password'
              title={'Mật khẩu mới'}
              sign_in={onSubmit}
              errorsMessage={errors.password?.message}
              showError={!!errors.password?.message}
            />
            <InputField
              required={true}
              isPassword
              placeholder={'Nhập mật khẩu'}
              register={register}
              registerValue='confirm_password'
              title={'Vui lòng nhập lại mật khẩu mới'}
              sign_in={onSubmit}
              errorsMessage={errors.confirm_password?.message}
              showError={!!errors.confirm_password?.message}
            />
          </div>
          <div className='flex w-full flex-col gap-3'>
            <h1 className='font-semibold text-sub-600'>Lưu ý: Điều kiện mật khẩu</h1>
            <ul className='list-inside list-disc text-sm text-sub-600'>
              <li>Mật khẩu bắt buộc có độ dài tối thiểu từ 8-35 ký tự.</li>
              <li>Có tối thiểu 1 ký tự chữ cái viết hoa, 1 ký tự chữ cái viết thường, 1 ký tự số.</li>
              <li>Mật khẩu không dùng tiếng Việt có dấu và khoảng cách.</li>
              <li>
                Sau 20 phút, nếu người dùng truy cập vào “Đặt lại mật khẩu”, hệ thống sẽ hiện trang{' '}
                <span className='font-semibold text-primary'>“Không tìm thấy yêu cầu”.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button
        disabled={isPending}
        type='submit'
        className='flex h-[48px] w-full items-center justify-center rounded-xl bg-primary text-base font-medium text-white active:opacity-active disabled:opacity-50 sm:shadow-[0px_12px_21px_4px_rgba(68,97,242,0.15)]'
      >
        Lưu
      </button>
    </form>
  )
}

export default ResetPassword
