import { useCallback, useState } from 'react'
import { passkeyAuthService } from '@src/store/services/passkey_auth.service'
const useWebAuthn = () => {
  const [credential, setCredential] = useState<PublicKeyCredential | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const base64URLtoBuffer = (base64url: string): Uint8Array => {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/')
    const paddedBase64 = base64.padEnd(base64.length + ((4 - (base64.length % 4)) % 4), '=')
    const rawData = window.atob(paddedBase64)
    const buffer = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i)
    }

    return buffer
  }

  const register = useCallback(async (params: any) => {
    try {
      setIsLoading(true)
      const response = (await passkeyAuthService.generate_passkey_options(params)).data.data
      const challenge = base64URLtoBuffer(response.challenge)

      // Convert user ID to Uint8Array
      const userId = base64URLtoBuffer(response.user.id)

      const publicKey: PublicKeyCredentialCreationOptions = {
        challenge: challenge,
        rp: {
          name: response.rp.name,
        },
        user: {
          id: userId,
          name: response.user.name,
          displayName: response.user.display_name,
        },
        pubKeyCredParams: response.pub_key_cred_params.map((param: any) => ({
          type: param.type,
          alg: param.alg,
        })),
        timeout: response.timeout,
        attestation: response.attestation,
        // Use defaults or API response for authenticatorSelection
        authenticatorSelection: response.authenticator_selection || {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
      }

      const newCredential = (await navigator.credentials.create({ publicKey })) as PublicKeyCredential
      setCredential(newCredential)
      return newCredential
    } catch (err) {
      console.error('Registration failed', err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [])

  const authenticate = useCallback(async (credential: PublicKeyCredential) => {
    try {
      setIsLoading(true)
      const res = (
        await passkeyAuthService.verify_registration({
          response: credential,
          username: 'frey2k5',
        })
      ).data.data
      return res
    } catch (err) {
      console.error('Registration failed', err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { credential, register, authenticate, isLoading }
}

export default useWebAuthn
