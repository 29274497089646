export const TIME_FILTER = [
  {
    id: 1,
    label: 'Hôm nay',
    value: 'today',
  },
  {
    id: 2,
    label: 'Hôm qua',
    value: '1-day',
  },
  {
    id: 3,
    label: '7 ngày trước',
    value: '1-week',
  },
  {
    id: 4,
    label: 'Tháng này',
    value: '1-month',
  },
  {
    id: 5,
    label: 'Tháng trước',
    value: '1-month-before',
  },
]

export const MONEY_AMOUNTS = [
  {
    id: 1,
    label: 'Tất cả',
    value: 'all',
  },
  {
    id: 2,
    label: '0 - 1 triệu',
    value: JSON.stringify({
      start: 0,
      end: 1 * Math.pow(10, 6),
    }),
  },
  {
    id: 3,
    label: '1 - 10 triệu',
    value: JSON.stringify({
      start: 1 * Math.pow(10, 6),
      end: 10 * Math.pow(10, 6),
    }),
  },
  {
    id: 4,
    label: '10 -50 triệu',
    value: JSON.stringify({
      start: 10 * Math.pow(10, 6),
      end: 50 * Math.pow(10, 6),
    }),
  },
  {
    id: 5,
    label: '> 50 triệu',
    value: JSON.stringify({
      start: 50 * Math.pow(10, 6),
      end: 100 * Math.pow(10, 6),
    }),
  },
]

export const SALE_LOCATIONS = [
  {
    id: 1,
    label: 'Tất cả',
    value: 'all',
  },
  {
    id: 2,
    label: 'Starbucks Gigamall',
    value: 'starbucks-gigamall',
  },
  {
    id: 3,
    label: 'Starbucks Landmark 81',
    value: 'starbucks-landmark-81',
  },
  {
    id: 4,
    label: 'Starbucks Vincom Center',
    value: 'starbucks-vincom-center',
  },
]
