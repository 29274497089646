import { useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'

type EmitEvent = {
  name: string
  payload?: any
}

type OnEvent = {
  name: string
  func: (data: any, socket: Socket) => void
}

type UseSocketProps = {
  url: string
  emitEvent?: EmitEvent
  onEvent: OnEvent
  enable?: boolean
}
export const useSocket = ({ url, emitEvent, onEvent, enable = true }: UseSocketProps) => {
  const [data, setData] = useState<{ data: any }>()

  useEffect(() => {
    if (enable) {
      const socket = io(url)
      socket.on('connect', () => {
        if (emitEvent) socket.emit(emitEvent?.name, emitEvent?.payload)
        // Remove previous event listener when re-focus the website
        socket.off(onEvent?.name)
        // Get notification
        socket.on(onEvent?.name, async (data) => {
          setData(data)
          onEvent.func(data, socket)
        })
      })
      // Event handler for disconnection
      socket.on('disconnect', () => {})
      // Event handler for errors
      socket.on('error', (e) => console.log('Socket error:', e))
      // Clean up the socket connection when the component is unmounted
      return () => {
        socket.disconnect()
      }
    }
  }, [enable])

  return { data }
}
