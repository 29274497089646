import { MutableRefObject } from 'react'
import { wait } from '@src/utils/common'

const InstallButton = ({
  installPrompt,
  isInstalled,
  handleClose,
}: {
  installPrompt: MutableRefObject<any>
  isInstalled: boolean
  handleClose: () => void
}) => {
  return (
    <>
      <button
        id='install-pwa-button'
        data-load={false}
        className={`group flex w-full items-center justify-center rounded-[10px] bg-primary px-10 py-3 text-white data-[load=true]:pointer-events-none data-[load=true]:opacity-70`}
        onClick={async (e) => {
          if (isInstalled) {
            handleClose()
            installPrompt.current = null
            window.open(window.location.href, '_blank')
          } else {
            if (!installPrompt) {
              return
            }
            e.currentTarget.dataset.load = 'true'
            await wait(800)
            try {
              await installPrompt.current.prompt()
              installPrompt.current.userChoice.then((choiceResult: any) => {
                const el = document.getElementById('install-pwa-button')
                el?.dataset.load && (el.dataset.load = 'false')
                if (choiceResult.outcome === 'accepted') {
                  handleClose()
                  installPrompt.current = null
                  window.open(window.location.href, '_blank')
                }
              })
            } catch (error) {
              alert('Lưu ý: Có thể bạn đã cài đặt ứng dụng rồi!')
              const el = document.getElementById('install-pwa-button')
              if (el) {
                el.dataset.load = 'false'
              }
            }
          }
        }}
      >
        <span className='inline-block max-w-[60px] overflow-hidden whitespace-nowrap text-nowrap text-base font-medium leading-5 tracking-[-0.16px] transition-all group-data-[load=true]:max-w-0'>
          {isInstalled ? 'Mở ứng dụng' : 'Cài đặt'}
        </span>

        <span className='inline-block size-5 max-w-0 scale-0 animate-spin overflow-hidden rounded-full border-y-2 border-white transition-all group-data-[load=true]:max-w-5 group-data-[load=true]:scale-100'></span>
      </button>
    </>
  )
}

export default InstallButton
