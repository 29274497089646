import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import { keepPreviousData, QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Chatbot from './components/chat-bot'
import CustomToast from './components/toaster/custom-toast'
import AuthProvider from './providers/auth-provider'
import { ShopListProvider } from './providers/shop-provider'
import AppRouter from './routes'

import './App.css'

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: 60 * 5 * 60,
        placeholderData: keepPreviousData,
        networkMode: 'offlineFirst',
      },
    },
  })
  return (
    <ErrorBoundary fallback={<div>An error has occurred</div>}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ShopListProvider>
              <AppRouter />
              <CustomToast />
              <Chatbot />
            </ShopListProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
