import { useEffect, useState } from 'react'
import SearchBar from '@src/components/common/search-bar'
import { useShopList } from '@src/hooks/use-shop-list'
import { useFilterStore } from '@src/store/states/filter.state'
import { useDebounce } from 'use-debounce'

import FilterSectionWrapper from '../filter-section-wrapper'

export default function SaleLocationSection() {
  const { setFilterOptions, filterOptions } = useFilterStore()
  const [inputSearch, setInputSearch] = useState('')
  const searchDebounce = useDebounce(inputSearch, 500)
  const [salesLocations, setSaleLocations] = useState<any>([])
  const { data } = useShopList()

  useEffect(() => {
    if (data) {
      setSaleLocations([{ source_id: 0, name: 'Tất cả' }, ...data])
    }
  }, [data])

  useEffect(() => {
    if (searchDebounce) {
      setSaleLocations(
        [{ source_id: 0, name: 'Tất cả' }, ...data].filter((item: any) =>
          item.name.toLowerCase().includes(searchDebounce[0].toLowerCase())
        )
      )
    } else {
      setSaleLocations([{ source_id: 0, name: 'Tất cả' }, ...data])
    }
  }, [searchDebounce, data])

  return (
    <FilterSectionWrapper isExpanded headerLabel='Theo điểm bán'>
      <div className='flex flex-col items-center gap-[20px]'>
        <SearchBar inputValue={inputSearch} setInputValue={setInputSearch} />
        <div className='flex flex-wrap items-center justify-start gap-2'>
          {salesLocations.length > 0 ? (
            salesLocations.map((item: any) => (
              <button
                onClick={() => setFilterOptions({ ...filterOptions, saleLocation: item.source_id })}
                key={item.source_id}
                className={`min-w-fit rounded-full ${item.source_id === filterOptions.saleLocation ? 'border-2 border-primary bg-primary/10 text-primary' : 'border border-soft-200'} px-3 py-2`}
              >
                {item.name}
              </button>
            ))
          ) : (
            <div className='flex items-center justify-center py-4'>Nothing found</div>
          )}
        </div>
      </div>
    </FilterSectionWrapper>
  )
}
