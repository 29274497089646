import { useEffect, useState } from 'react'
import { theme_bank } from '@src/constants/bank.constants'
import { getActivePageColor } from '@src/constants/navbar/navbar.constant'
import { animateHome, getInstallPWA, setShowIntro } from '@src/utils/local-storage'
import { getThemeBank, setThemeColor } from '@src/utils/theme-color'

import BVBankIntro from './bvbank-intro'
import OcbIntro from './ocb-intro'

const PageIntro = ({
  control,
  children,
  isChangeThemeAtTheEnd,
}: {
  control: boolean
  children: React.ReactNode
  isChangeThemeAtTheEnd?: boolean
}) => {
  const [isPlay, setIsPlay] = useState(control)

  useEffect(() => {
    if (control) {
      setThemeColor(theme_bank[getThemeBank()].primary)
    }
  }, [])

  function onStart() {
    animateHome(true)
  }

  function onFinish() {
    setShowIntro(false)
    setIsPlay(false)
    if (isChangeThemeAtTheEnd) {
      const color = getActivePageColor()
      setThemeColor(!getInstallPWA() ? color.themeColorOverlay : color.themeColor)
    }
    animateHome(false)
  }

  return (
    <>
      {children}
      {isPlay && (
        <>
          {getThemeBank() === 'bvb' ? (
            <BVBankIntro onStart={onStart} onFinish={onFinish} />
          ) : (
            <OcbIntro onFinish={onFinish} />
          )}
        </>
      )}
    </>
  )
}

export default PageIntro
