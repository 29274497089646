import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import OtpInput from 'react-otp-input'
import { CustomButton } from '@src/components/common/custom-button'
import { openToast } from '@src/components/toaster/custom-toast'
import { wait } from '@src/utils/common'

import AnimatedPosMachine from './animated-pos-machine'
import { getRequestPos, verifyOtpPos } from './connect-device.controller'
import useConnectDeviceStore from './connect-device.store'

import styles from './connect-device.module.css'

type TForm = {
  pos_code: string
  otp_code: string
}

function ConnectDevice({ closeModal }: { closeModal: () => void }) {
  const { setConnectDevice } = useConnectDeviceStore()
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    formState: { isValid },
  } = useForm<TForm>()
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(1)
  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()

  async function handleStep(nextStep: number) {
    setStep(nextStep)
    setMessage('')
  }

  async function handleSuccess() {
    openToast({
      message: 'Xác thực thành công!',
      type: 'success',
    })
    setStep(3)
    await wait(1000)
    closeModal()
  }

  const onSubmit = async (data: TForm) => {
    switch (step) {
      case 1:
        setLoading(true)
        await getRequestPos({
          pos_code: data.pos_code,
        })
          .then((res) => {
            if (res) {
              openToast({
                message: 'Đã tìm thấy thiết bị. Vui lòng nhập mã OTP',
                type: 'success',
              })
              handleStep(2)
            } else {
              openToast({
                message: 'Không tìm thấy thiết bị. Vui lòng thử lại!',
                type: 'error',
              })
              setFocus('pos_code')
              setMessage('Không tìm thấy thiết bị. Vui lòng thử lại!')
            }
          })
          .catch((_) => {
            setFocus('pos_code')
          })
          .finally(() => setLoading(false))

        break
      case 2:
        setLoading(true)
        await verifyOtpPos({
          pos_code: data.pos_code,
          otp_code: data.otp_code,
        })
          .then((res) => {
            if (typeof res == 'string') {
              openToast({
                message: res,
                type: 'error',
              })
              setMessage(res)
              setOtp('')
            } else {
              handleSuccess()
              setConnectDevice({
                ...res,
                device_name: 'Johnny Mai',
                account: 'Johnny Mai',
                update_time: new Date().toLocaleString(),
                store_name: 'Atom',
                branch_name: 'Vung Tau',
                agency_name: 'Ba Ria',
                status: 'active',
              })
            }
          })
          .finally(() => setLoading(false))
        break
    }
  }
  useEffect(() => {
    if (step === 2) {
      setValue('otp_code', otp)
      if (otp.length === 6) {
        const el = document.getElementById('input-otp-5')
        if (el) {
          el.blur()
        }
      }
    }
  }, [otp])

  return (
    <div className={styles.wrapper}>
      <AnimatedPosMachine step={step} />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <div data-step={step} className='group relative min-h-[158px] w-full overflow-hidden'>
          <div className='absolute inset-0 translate-x-[-100%] transition-all duration-300 group-data-[step=1]:translate-x-0'>
            <p className={styles.title}>
              Nhập số Serial
              <br />
              <span className={styles.subTitle}>Nhập số Serial trên máy POS để tiến hành kết nối</span>
            </p>
            <label className={styles.field}>
              <input
                {...register('pos_code', {
                  required: 'Vui lòng nhập số Serial',
                })}
                type='text'
                placeholder='Nhập số Serial'
                className={styles.inputFiled}
              />
            </label>
          </div>
          <div className='absolute inset-0 translate-x-0 transition-all duration-300 group-data-[step=1]:translate-x-full'>
            <p className={styles.title}>
              Nhập mã OTP
              <br />
              <span className={styles.subTitle}>Nhập 6 chữ số được gửi đến máy POS của bạn</span>
            </p>
            <label className={styles.field}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType='number'
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props, index) => {
                  return <input id={`input-otp-` + index} {...props} className={styles.inputFiled} />
                }}
              />
            </label>
          </div>
        </div>

        <p data-hide={message.length === 0} className={styles.message}>
          {message}
        </p>

        <div
          data-step={step}
          data-success={step == 3}
          className={'group flex justify-center gap-0 transition-all data-[step=2]:gap-4'}
        >
          <div className='max-w-0 flex-1 overflow-hidden transition-all duration-300 group-data-[step=2]:max-w-[60%]'>
            <CustomButton
              onClick={() => {
                handleStep(1)
                setOtp('')
              }}
              type='button'
              text={'Quay lại'}
              className={'!rounded-[10px] border border-primary !bg-transparent text-primary'}
            />
          </div>
          <div className='flex-1 transition-all'>
            <CustomButton
              id='submit-connect-device'
              onClick={() => {}}
              disable={!isValid || loading}
              type='submit'
              text={step != 3 ? (loading ? 'Đang kết nối' : 'Kết nối') : 'Kết nối thành công!'}
              className={`!rounded-[10px] !text-white !transition-all ${step == 3 ? 'pointer-events-none !bg-success-base' : '!bg-primary'}`}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ConnectDevice
