export const IMove = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0764 5.52751C15.0764 5.81515 14.8432 6.04834 14.5556 6.04834L3.44447 6.04834C3.15682 6.04834 2.92364 5.81515 2.92364 5.52751C2.92364 5.23986 3.15682 5.00667 3.44447 5.00667L14.5556 5.00667C14.8432 5.00667 15.0764 5.23986 15.0764 5.52751Z'
        fill='#525866'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0764 8.99967C15.0764 9.28732 14.8432 9.52051 14.5556 9.52051L3.44447 9.52051C3.15682 9.52051 2.92364 9.28732 2.92364 8.99967C2.92364 8.71203 3.15682 8.47884 3.44447 8.47884L14.5556 8.47884C14.8432 8.47884 15.0764 8.71203 15.0764 8.99967Z'
        fill='#525866'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0764 12.4718C15.0764 12.7595 14.8432 12.9927 14.5556 12.9927L3.44447 12.9927C3.15682 12.9927 2.92364 12.7595 2.92364 12.4718C2.92364 12.1842 3.15682 11.951 3.44447 11.951L14.5556 11.951C14.8432 11.951 15.0764 12.1842 15.0764 12.4718Z'
        fill='#525866'
      />
    </svg>
  )
}

export const IEditUnderscore = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_474_11475)'>
        <path d='M3.33337 18.3335H16.6667' stroke='#1B1B1B' strokeWidth='1.5' strokeLinecap='round' />
        <path
          d='M11.5734 3.05228L12.1913 2.43436C13.2151 1.41055 14.8751 1.41055 15.8989 2.43436C16.9227 3.45816 16.9227 5.11807 15.8989 6.14187L15.2809 6.75979M11.5734 3.05228C11.5734 3.05228 11.6507 4.36535 12.8093 5.52395C13.9679 6.68255 15.2809 6.75979 15.2809 6.75979M11.5734 3.05228L5.89259 8.7331C5.50782 9.11788 5.31543 9.31026 5.14997 9.52239C4.9548 9.77262 4.78746 10.0434 4.65094 10.3299C4.5352 10.5727 4.44916 10.8308 4.27708 11.3471L3.54791 13.5346M15.2809 6.75979L9.60011 12.4406C9.21533 12.8254 9.02295 13.0178 8.81082 13.1832C8.56059 13.3784 8.28983 13.5457 8.00336 13.6823C7.7605 13.798 7.50239 13.8841 6.98616 14.0561L4.79865 14.7853M4.79865 14.7853L4.26393 14.9635C4.00989 15.0482 3.72981 14.9821 3.54046 14.7927C3.35111 14.6034 3.28499 14.3233 3.36967 14.0693L3.54791 13.5346M4.79865 14.7853L3.54791 13.5346'
          stroke='#1B1B1B'
          strokeWidth='1.25'
        />
      </g>
      <defs>
        <clipPath id='clip0_474_11475'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IPositionDevice = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.75 10.1433C4.75 5.64588 8.33172 2 12.75 2C17.1683 2 20.75 5.64588 20.75 10.1433C20.75 14.6055 18.1967 19.8124 14.2129 21.6744C13.2843 22.1085 12.2157 22.1085 11.2871 21.6744C7.30332 19.8124 4.75 14.6055 4.75 10.1433Z'
        stroke='#232A75'
        strokeWidth='1.5'
      />
      <circle cx='12.75' cy='10' r='3' stroke='#00BAEE' strokeWidth='1.5' />
    </svg>
  )
}

export const IConnectingCheck = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12.25' cy='12' r='10' stroke='#232A75' strokeWidth='1.5' />
      <path
        d='M6.75 4L14.1284 12V7.5'
        stroke='#00BAEE'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6284 19.8784L10.1284 12V16.5'
        stroke='#00BAEE'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const ILockDevice = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.75 16C2.75 13.1716 2.75 11.7574 3.62868 10.8787C4.50736 10 5.92157 10 8.75 10H16.75C19.5784 10 20.9926 10 21.8713 10.8787C22.75 11.7574 22.75 13.1716 22.75 16C22.75 18.8284 22.75 20.2426 21.8713 21.1213C20.9926 22 19.5784 22 16.75 22H8.75C5.92157 22 4.50736 22 3.62868 21.1213C2.75 20.2426 2.75 18.8284 2.75 16Z'
        stroke='#00BAEE'
        strokeWidth='1.5'
      />
      <circle cx='12.75' cy='16' r='2' stroke='#232A75' strokeWidth='1.5' />
      <path
        d='M6.75 10V8C6.75 4.68629 9.43629 2 12.75 2C16.0637 2 18.75 4.68629 18.75 8V10'
        stroke='#232A75'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export const ICancelDelete = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.25 12C2.25 7.28595 2.25 4.92893 3.71447 3.46447C5.17893 2 7.53595 2 12.25 2C16.964 2 19.3211 2 20.7855 3.46447C22.25 4.92893 22.25 7.28595 22.25 12C22.25 16.714 22.25 19.0711 20.7855 20.5355C19.3211 22 16.964 22 12.25 22C7.53595 22 5.17893 22 3.71447 20.5355C2.25 19.0711 2.25 16.714 2.25 12Z'
        stroke='#232A75'
        strokeWidth='1.5'
      />
      <path
        d='M14.75 9.50002L9.75 14.5M9.74998 9.5L14.75 14.5'
        stroke='#00BAEE'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
