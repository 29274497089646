import { useEffect } from 'react'
import { ModalProps, ModalTransition } from '@src/constants/modal/modal'
import { getActivePageColor } from '@src/constants/navbar/navbar.constant'
import { setThemeColor } from '@src/utils/theme-color'
import { motion } from 'framer-motion'

import ModalWrapper from './modal-wrapper'

const variantBottom = {
  initial: { y: '100%' },
  animate: { y: '0' },
  exit: { y: '105%' },
}

const ModalBottom = (props: ModalProps) => {
  const { isOpen, onClose, children, className, classNameIosPb, header, footer, disableChangeColorTheme } = props
  useEffect(() => {
    if (disableChangeColorTheme) {
      return
    }
    const page = getActivePageColor()
    if (isOpen) {
      setThemeColor(page.themeColorOverlay)
    } else {
      setThemeColor(page.themeColor)
    }
  }, [isOpen, disableChangeColorTheme])
  return (
    <ModalWrapper isOpen={isOpen}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        {...ModalTransition}
        className={`flex size-full items-end bg-overlay`}
        onClick={(e) => {
          onClose()
          e.stopPropagation()
        }}
      >
        <motion.div
          {...variantBottom}
          {...ModalTransition}
          className={`flex max-h-[80%] w-full flex-col overflow-hidden rounded-t-2xl bg-weak-50 ${className} `}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={`flex flex-col items-center pt-3`}>
            <div className='h-1 w-10 rounded-full bg-disable-300 text-xs' />
            {header}
          </div>
          <div className='relative w-full flex-1 overflow-auto'>{children}</div>
          <div className={`w-full pb-ios-pb ${classNameIosPb}`}>{footer}</div>
        </motion.div>
      </motion.div>
    </ModalWrapper>
  )
}

export default ModalBottom
