Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DNS as string,
  environment: 'production',
  // maxBreadcrumbs: 50,
  // debug: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
})

import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser'

import App from './App.tsx'

createRoot(document.getElementById('root')!).render(
  // <StrictMode>
  <App />
  // </StrictMode>
)
