import { useState } from 'react'
import { IFilter, IMagnifier } from '@src/constants/icons/transaction.icon'
import { useFilterStore } from '@src/store/states/filter.state'
import { TTransaction } from '@src/types/transaction'

import FilterModal from '../filter-modal'

const SearchBar = ({ controlItem }: { controlItem: TTransaction['payment_method'] | 'all' }) => {
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const { setFilterOptions, filterOptions } = useFilterStore()

  return (
    <div className='flex w-full items-stretch gap-2 px-4'>
      <label className='flex flex-1 items-center gap-2 rounded-full bg-weak-50 px-3'>
        <IMagnifier />
        <div className='flex-1'>
          <input
            value={filterOptions.textSearch}
            className='w-full bg-transparent text-sm font-normal text-soft-400 outline-none'
            placeholder='Tìm giao dịch'
            onChange={(e) => setFilterOptions({ ...filterOptions, textSearch: e.target.value })}
          />
        </div>
      </label>
      <button
        onClick={() => setOpenFilterModal(true)}
        className='relative flex size-11 items-center justify-center rounded-full bg-weak-50 text-strong-950'
      >
        <span className='absolute right-0 top-0 flex h-[18px] w-[18px] items-center justify-center rounded-full border border-white bg-primary text-[10px] font-medium text-white'>
          4
        </span>
        <IFilter />
      </button>

      <FilterModal isOpen={openFilterModal} onClose={() => setOpenFilterModal(false)} controlItemValue={controlItem} />
    </div>
  )
}

export default SearchBar
