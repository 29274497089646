import { useEffect, useMemo, useRef } from 'react'
import { useInView } from 'framer-motion'

export type SwipeAreaChildrenProps<T extends { id: string }> = {
  swipeAreaContainerId: string
  controlItem: T
  value?: T
  onChange: (value: T) => void
  children?: React.ReactNode
  renderOnlyIfInview?: boolean
}

const SwipeAreaChildren = <T extends { id: string }>({
  swipeAreaContainerId,
  controlItem,
  value,
  onChange,
  children,
  renderOnlyIfInview = false,
}: SwipeAreaChildrenProps<T>) => {
  const id = useMemo(() => swipeAreaContainerId + controlItem.id, [controlItem])
  const ref = useRef<HTMLDivElement | null>(null)
  const isInView = useInView(ref)
  const isRender = useMemo(() => {
    if (renderOnlyIfInview) {
      if (!isInView) {
        return false
      }
      if (value?.id !== controlItem.id) {
        return false
      }
    }
    return true
  }, [isInView, controlItem, value, renderOnlyIfInview])

  useEffect(() => {
    const parent = document.getElementById(id)?.parentElement
    if (!parent) return
    const allowInview = parent.getAttribute('data-inview')
    if (isInView && allowInview == 'true' && value?.id !== controlItem.id) {
      onChange(controlItem)
    }
  }, [isInView])

  return (
    <>
      <div id={id} className='relative size-full min-w-full snap-center snap-always'>
        <div
          ref={ref}
          className='pointer-events-none absolute left-1/2 top-1/2 size-4 -translate-x-1/2 -translate-y-1/2 opacity-0'
        ></div>

        <div className='relative size-full overflow-auto'>{isRender && children}</div>
      </div>
    </>
  )
}

export default SwipeAreaChildren
