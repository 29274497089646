import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconEmail, IconLocked } from '@src/constants/icons/icon-sign-in'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { authService } from '@src/store/services/auth.service'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

import { openToast } from '../toaster/custom-toast'

const formSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .matches(
      /^[^\s][a-zA-Z0-9._+-]{0,62}[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]{2,253}\.[a-zA-Z]{2,63}$/,
      'Please enter a valid email'
    ),
})

type FormFields = yup.InferType<typeof formSchema>

const ForgotPassword = () => {
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState<number>(1)
  const {
    register,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(formSchema),
  })
  const onSubmit = () => {
    clearErrors()
    forgot_password()
  }

  const { mutate: forgot_password, isPending } = useMutation({
    mutationFn: async () =>
      await authService.forgot_password({
        email: watch('email'),
      }),
    onError: () => {
      setError('email', { message: 'Email không hợp lệ' })
      openToast({
        type: 'error',
        message: 'Email không hợp lệ',
        duration: 3000,
      })
    },
    onSuccess: () => {
      openToast({
        type: 'success',
        message: 'Gửi liên kết thành công',
        duration: 3000,
      })
      setCurrentStep(2)
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex h-full w-full flex-col items-center gap-[64px] px-4 pt-8'>
      <div className='flex w-full flex-col gap-[48px]'>
        <div className='flex h-full w-full flex-col items-center gap-3'>
          <div className='flex h-[76px] w-[76px] items-center justify-center rounded-full border-2 border-primary border-opacity-[0.16]'>
            <span className='[--color-primary:theme(colors.primary)] [--color-secondary:theme(colors.secondary)]'>
              {currentStep === 2 ? <IconEmail /> : <IconLocked />}
            </span>
          </div>
          <p className='text-xl font-semibold tracking-[-2%] text-strong-950'>
            {currentStep === 2 ? 'Đã gửi email' : 'Quên mật khẩu'}
          </p>
          {currentStep == 2 ? (
            <p className='text-center text-[16px] leading-6 tracking-[-2%] text-sub-600'>
              Chúng tôi đã gửi email đến <span className='font-bold text-primary'>{maskEmail(watch('email'))}</span> kèm
              theo liên kết để bạn truy cập lại vào tài khoản. <br /> Vui lòng kiểm tra email của bạn
            </p>
          ) : (
            <p className='text-center text-[16px] leading-6 tracking-[-2%] text-sub-600'>
              Nhập email và chúng tôi sẽ gửi cho bạn một liên kết để truy cập lại vào tài khoản.
            </p>
          )}
        </div>
        {currentStep === 1 && (
          <div className='mx-2 flex w-full flex-col gap-2'>
            <input
              {...register('email')}
              placeholder='Vui lòng nhập email'
              className={`rounded-[10px] border border-soft-200 px-3 py-1.5 placeholder:text-sm placeholder:font-normal placeholder:text-[#ABB2C2]`}
            />
            {errors.email && <p className='text-sm text-red-500'>{errors.email.message}</p>}
          </div>
        )}
      </div>

      <div className='flex w-full flex-col gap-4'>
        <button
          disabled={isPending}
          className={`flex h-[48px] w-full items-center justify-center rounded-xl bg-primary text-white active:opacity-active disabled:opacity-50 sm:shadow-[0px_12px_21px_4px_rgba(68,97,242,0.15)] ${isPending && 'cursor-not-allowed opacity-50'}`}
          type='submit'
        >
          {isPending ? (
            <div className='h-5 w-5 animate-spin rounded-full border-b-2 border-t-2 border-white' />
          ) : currentStep === 1 ? (
            'Gửi liên kết'
          ) : (
            'Gửi lại liên kết'
          )}
        </button>

        <div
          onClick={() => {
            if (currentStep === 2) {
              setCurrentStep(1)
            } else {
              navigate(ROUTES.sign_in)
            }
          }}
          className='flex h-[48px] w-full cursor-pointer items-center justify-center rounded-xl border border-primary text-center text-primary'
        >
          Trở lại
        </div>
      </div>
    </form>
  )
}

const maskEmail = (email: string) => {
  const [name, domain] = email.split('@')
  if (name.length <= 2) {
    return `${name[0]}****@${domain}`
  }
  return `${name[0]}****${name[name.length - 1]}@${domain}`
}

export default ForgotPassword
