import { useEffect } from 'react'
import { IRadio } from '@src/constants/icons/header.icon'
import { ModalProps } from '@src/constants/modal/modal'
import { useShopList } from '@src/hooks/use-shop-list'
import { useShopStore } from '@src/store/states/shop.state'
import { TShop } from '@src/types/shop'

import ModalHeader from '../modal/header/modal-header'
import ModalBottom from '../modal/modal-bottom'

const starBuck_img =
  'https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/640px-Starbucks_Corporation_Logo_2011.svg.png'

const ChooseBranchModal = ({ ...props }: ModalProps & { setValue?: (value: TShop) => void }) => {
  const { currentShop, setCurrentShop } = useShopStore()
  const { data, isLoading, error, page, page_size, setPage } = useShopList()

  useEffect(() => {
    if (!currentShop && data && data.length > 0) {
      setCurrentShop(data[0])
      props.setValue && props.setValue(data[0])
    }
  }, [data])

  return (
    <ModalBottom {...props} header={<ModalHeader onClick={() => props.onClose?.()}>Chọn điểm bán</ModalHeader>}>
      <div className='flex w-full flex-col gap-2 px-5 pt-2'>
        {data &&
          data.map((shop, index) => {
            const isActive = currentShop?.id === shop.id
            return (
              <div
                key={shop.id}
                className={`overflow-hidden rounded-2xl border transition-all active:opacity-active ${isActive ? 'pointer-events-none border-primary bg-primary/10' : 'border-primary/0 bg-white'}`}
                onClick={() => {
                  setCurrentShop(shop)
                  props.setValue && props.setValue(shop)
                  props.onClose && props.onClose()
                }}
              >
                <div className='flex items-center gap-3 px-3 py-2'>
                  <div className='size-11 rounded-full'>
                    <img src={starBuck_img} alt='' className='size-full object-cover' />
                  </div>

                  <div className='flex-1'>
                    <p className='text-base font-semibold text-primary'>{shop.name}</p>
                    <p className='mt-0.5 text-sm text-sub-600'>{shop.address}</p>
                  </div>

                  <div
                    className={
                      isActive
                        ? 'text-primary [--fill-2:theme(colors.white)] [--fill:theme(colors.primary)]'
                        : 'text-disable-300'
                    }
                  >
                    <IRadio />
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </ModalBottom>
  )
}

export default ChooseBranchModal
