import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { QRLogo } from '@src/constants/bank.constants'
import { IEye } from '@src/constants/icons/icon-sign-in'
import { reportService } from '@src/store/services/report.service'
import { getThemeBank } from '@src/utils/theme-color'
import { formatCurrency } from '@src/utils/transaction'
import { useQuery } from '@tanstack/react-query'

const Balance = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['report-dashboard'],
    queryFn: () => reportService.getDashboard(new Date().toISOString().split('T')[0]),
  })

  const dashboard = useMemo(() => {
    if (!data) return undefined
    const dataArray = data.split('\n')
    const res: any = []
    if (Array.isArray(dataArray)) {
      dataArray.forEach((dataStr, index) => {
        if (index === dataArray.length - 1) return
        const data = JSON.parse(dataStr)
        res.push(data)
      })
    }
    return Object.values(res[2]['Card_monthly_revenue'].data)[0]
  }, [data])

  return (
    <>
      <div
        id='home-balance'
        data-shorten={false}
        className='group z-20 max-h-[140px] w-full origin-right translate-x-5 transition-all duration-300'
      >
        <div className='flex w-full justify-end pb-16 pt-4 group-data-[shorten=true]:hidden'>
          <div className='flex w-fit origin-right items-center rounded-l-[10px] border border-gray/0 bg-white py-2 pl-3 pr-4 shadow-2xl'>
            {QRLogo[getThemeBank()]()}
            <div className='flex flex-col gap-1 overflow-hidden transition-all'>
              <p className='pl-2 text-xs font-medium leading-4 tracking-[0.12px] text-strong-950'>
                Tài khoản thanh toán
              </p>
              <p className='pl-2 text-sm font-semibold leading-5 tracking-[0.14px] text-primary'>
                {isFetching ? <>Đang cập nhật...</> : <>{formatCurrency(Number('1525168000'))}</>}
              </p>
            </div>
          </div>
        </div>
        {document.getElementById('app-header')?.parentElement &&
          createPortal(
            <BalanceShorten isFetching={isFetching} />,
            document.getElementById('app-header')?.parentElement as HTMLElement
          )}
      </div>
    </>
  )
}

export default Balance

function BalanceShorten({ isFetching }: { isFetching: boolean }) {
  const [isHideMoney, setIsHideMoney] = useState(false)
  return (
    <div
      id='home-balance-shorten'
      data-shorten={false}
      className='absolute bottom-0 left-0 right-0 z-0 origin-top translate-y-full scale-y-0 rounded-b-lg bg-white px-4 py-2 opacity-0 transition-all data-[shorten=true]:scale-y-100 data-[shorten=true]:opacity-100'
    >
      <div className='flex w-full items-center gap-2 text-primary'>
        <div onClick={() => setIsHideMoney(!isHideMoney)} className='relative'>
          <IEye stroke='2' />
          <div
            data-hide={isHideMoney}
            className='absolute left-1/2 top-1/2 h-[2px] w-full max-w-[28px] translate-x-[-50%] translate-y-[-50%] -rotate-45 overflow-hidden rounded-full bg-primary transition-all data-[hide=false]:max-w-0'
          ></div>
        </div>

        <p className='text-sm font-semibold leading-5 tracking-[0.14px]'>
          {isFetching ? (
            <>Đang cập nhật...</>
          ) : (
            <>{isHideMoney ? '***** ' : formatCurrency(Number('1525168000')) + 'đ'}</>
          )}
        </p>
      </div>
    </div>
  )
}
