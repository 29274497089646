import { TSearchControlItem } from '@src/types/search'
import { create } from 'zustand'

type State = {
  filterOptions: FilterOptions
  calendarFilterOptions: CalendarFilterOptions
  searchControl?: TSearchControlItem
}

type Actions = {
  setFilterOptions: (filterOptions: FilterOptions) => void
  setCalendarFilterOptions: (calendarFilterOptions: CalendarFilterOptions) => void
  resetFilterOptions: () => void
  resetCalendarFilterOptions: () => void
  setSearchControl: (item: TSearchControlItem) => void
}

const today = new Date()

const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
const endDate = new Date(today.setHours(0, 0, 0, 0))

const defaultFilterOptions: FilterOptions = {
  startDate: new Date(today.setHours(0, 0, 0, 0)),
  endDate: new Date(today.setHours(23, 59, 59, 999)),
  moneyAmount: 'all',
  transaction: 'all',
  saleLocation: 0,
  rangeTimeOption: 'today',
  textSearch: '',
}

const defaultCalendarFilterOptions: CalendarFilterOptions = {
  startDate,
  endDate,
  rangeTimeOption: '1-month',
}

export const useFilterStore = create<State & Actions>()((set) => ({
  filterOptions: defaultFilterOptions,
  calendarFilterOptions: defaultCalendarFilterOptions,
  setFilterOptions: (filterOptions: FilterOptions) => set({ filterOptions }),
  resetFilterOptions: () => set({ filterOptions: defaultFilterOptions }),
  resetCalendarFilterOptions: () => set({ calendarFilterOptions: defaultCalendarFilterOptions }),
  setCalendarFilterOptions: (calendarFilterOptions: CalendarFilterOptions) => set({ calendarFilterOptions }),
  searchControl: undefined,
  setSearchControl: (item) => set((state) => ({ ...state, searchControl: item })),
}))
