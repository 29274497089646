export const IHide = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49' />
      <path d='M14.084 14.158a3 3 0 0 1-4.242-4.242' />
      <path d='M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143' />
      <path d='m2 2 20 20' />
    </svg>
  )
}

export const IClear = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <circle cx='12' cy='12' r='10' />
      <path d='m15 9-6 6' />
      <path d='m9 9 6 6' />
    </svg>
  )
}

export const IEye = ({ stroke = '1.5' }: { stroke?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0' />
      <circle cx='12' cy='12' r='3' />
    </svg>
  )
}

export const IFaceId = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='45' viewBox='0 0 44 45' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.0541 24.6031C39.7605 24.6031 40.3332 25.1757 40.3332 25.8821V25.9783C40.3332 29.1125 40.3332 31.595 40.072 33.5378C39.8032 35.5373 39.2368 37.1557 37.9605 38.4319C36.6842 39.7082 35.0659 40.2746 33.0664 40.5434C31.1235 40.8047 28.6411 40.8046 25.5069 40.8046H25.4107C24.7043 40.8046 24.1316 40.2319 24.1316 39.5255C24.1316 38.8191 24.7043 38.2465 25.4107 38.2465C28.6626 38.2465 30.9729 38.2438 32.7255 38.0081C34.4413 37.7774 35.4299 37.3448 36.1516 36.6231C36.8734 35.9013 37.306 34.9128 37.5367 33.197C37.7723 31.4444 37.775 29.1341 37.775 25.8821C37.775 25.1757 38.3477 24.6031 39.0541 24.6031Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.94557 24.6031C5.65199 24.6031 6.22464 25.1757 6.22464 25.8821C6.22464 29.1341 6.22736 31.4444 6.46299 33.197C6.69368 34.9128 7.12629 35.9013 7.84805 36.6231C8.5698 37.3448 9.55834 37.7774 11.2742 38.0081C13.0268 38.2438 15.337 38.2465 18.589 38.2465C19.2954 38.2465 19.8681 38.8191 19.8681 39.5255C19.8681 40.2319 19.2954 40.8046 18.589 40.8046H18.4928C15.3586 40.8046 12.8761 40.8047 10.9333 40.5434C8.93381 40.2746 7.31545 39.7082 6.03917 38.4319C4.76289 37.1557 4.19649 35.5373 3.92766 33.5378C3.66646 31.595 3.66648 29.1125 3.6665 25.9783C3.6665 25.9463 3.66651 25.9143 3.66651 25.8821C3.66651 25.1757 4.23916 24.6031 4.94557 24.6031Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4928 4.13794L18.589 4.13794C19.2954 4.13794 19.8681 4.7106 19.8681 5.41701C19.8681 6.12342 19.2954 6.69608 18.589 6.69608C15.337 6.69608 13.0268 6.6988 11.2742 6.93443C9.55834 7.16511 8.5698 7.59773 7.84805 8.31948C7.12629 9.04123 6.69368 10.0298 6.46299 11.7456C6.22736 13.4982 6.22464 15.8085 6.22464 19.0604C6.22464 19.7668 5.65199 20.3395 4.94557 20.3395C4.23916 20.3395 3.66651 19.7668 3.66651 19.0604L3.6665 18.9642C3.66648 15.83 3.66646 13.3476 3.92766 11.4047C4.19649 9.40524 4.76289 7.78688 6.03917 6.5106C7.31545 5.23432 8.93381 4.66792 10.9333 4.3991C12.8761 4.13789 15.3586 4.13791 18.4928 4.13794Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.7255 6.93443C30.9729 6.6988 28.6626 6.69608 25.4107 6.69608C24.7043 6.69608 24.1316 6.12342 24.1316 5.41701C24.1316 4.7106 24.7043 4.13794 25.4107 4.13794C25.4428 4.13794 25.4749 4.13794 25.5069 4.13794C28.6411 4.13791 31.1235 4.13789 33.0664 4.3991C35.0659 4.66792 36.6842 5.23432 37.9605 6.5106C39.2368 7.78688 39.8032 9.40524 40.072 11.4047C40.3332 13.3476 40.3332 15.8301 40.3332 18.9642V19.0604C40.3332 19.7668 39.7605 20.3395 39.0541 20.3395C38.3477 20.3395 37.775 19.7668 37.775 19.0604C37.775 15.8085 37.7723 13.4982 37.5367 11.7456C37.306 10.0298 36.8734 9.04123 36.1516 8.31948C35.4299 7.59773 34.4413 7.16511 32.7255 6.93443Z'
        fill='currentColor'
      />
      <path
        d='M19.0163 19.5941C19.0163 20.8303 18.3482 21.8325 17.5241 21.8325C16.6999 21.8325 16.0318 20.8303 16.0318 19.5941C16.0318 18.3579 16.6999 17.3557 17.5241 17.3557C18.3482 17.3557 19.0163 18.3579 19.0163 19.5941Z'
        fill='var(--secondary-color)'
      />
      <path
        d='M27.9698 19.5941C27.9698 20.8303 27.3017 21.8325 26.4776 21.8325C25.6534 21.8325 24.9853 20.8303 24.9853 19.5941C24.9853 18.3579 25.6534 17.3557 26.4776 17.3557C27.3017 17.3557 27.9698 18.3579 27.9698 19.5941Z'
        fill='var(--secondary-color)'
      />
      <path
        d='M18.1905 26.9024C17.694 26.5343 16.993 26.6385 16.625 27.135C16.2569 27.6316 16.3611 28.3325 16.8576 28.7006C18.3087 29.7762 20.0833 30.4129 22.0008 30.4129C23.9183 30.4129 25.693 29.7762 27.144 28.7006C27.6406 28.3325 27.7448 27.6316 27.3767 27.135C27.0086 26.6385 26.3077 26.5343 25.8111 26.9024C24.7243 27.708 23.4123 28.1745 22.0008 28.1745C20.5893 28.1745 19.2774 27.708 18.1905 26.9024Z'
        fill='var(--secondary-color)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.59 8.82861H25.4117C30.2354 8.82861 32.6472 8.82861 34.1457 10.3271C35.6442 11.8257 35.6442 14.2375 35.6442 19.0612V25.8829C35.6442 30.7066 35.6442 33.1184 34.1457 34.6169C32.6472 36.1154 30.2354 36.1154 25.4117 36.1154H18.59C13.7663 36.1154 11.3545 36.1154 9.85595 34.6169C8.35742 33.1184 8.35742 30.7066 8.35742 25.8829V19.0612C8.35742 14.2375 8.35742 11.8257 9.85595 10.3271C11.3545 8.82861 13.7663 8.82861 18.59 8.82861ZM16.625 27.135C16.993 26.6385 17.694 26.5343 18.1905 26.9024C19.2774 27.708 20.5893 28.1745 22.0008 28.1745C23.4123 28.1745 24.7243 27.708 25.8111 26.9024C26.3077 26.5343 27.0086 26.6385 27.3767 27.135C27.7448 27.6316 27.6406 28.3325 27.144 28.7006C25.693 29.7762 23.9183 30.4129 22.0008 30.4129C20.0833 30.4129 18.3087 29.7762 16.8576 28.7006C16.3611 28.3325 16.2569 27.6316 16.625 27.135ZM26.4776 21.8325C27.3017 21.8325 27.9698 20.8303 27.9698 19.5941C27.9698 18.3579 27.3017 17.3557 26.4776 17.3557C25.6534 17.3557 24.9853 18.3579 24.9853 19.5941C24.9853 20.8303 25.6534 21.8325 26.4776 21.8325ZM17.5241 21.8325C18.3482 21.8325 19.0163 20.8303 19.0163 19.5941C19.0163 18.3579 18.3482 17.3557 17.5241 17.3557C16.6999 17.3557 16.0318 18.3579 16.0318 19.5941C16.0318 20.8303 16.6999 21.8325 17.5241 21.8325Z'
        fill='var(--secondary-color)'
      />
      <path
        d='M19.0162 19.5941C19.0162 20.8303 18.3481 21.8325 17.524 21.8325C16.6998 21.8325 16.0317 20.8303 16.0317 19.5941C16.0317 18.3579 16.6998 17.3557 17.524 17.3557C18.3481 17.3557 19.0162 18.3579 19.0162 19.5941Z'
        fill='white'
      />
      <path
        d='M27.9697 19.5941C27.9697 20.8303 27.3016 21.8325 26.4775 21.8325C25.6533 21.8325 24.9852 20.8303 24.9852 19.5941C24.9852 18.3579 25.6533 17.3557 26.4775 17.3557C27.3016 17.3557 27.9697 18.3579 27.9697 19.5941Z'
        fill='white'
      />
      <path
        d='M18.1904 26.9023C17.6939 26.5343 16.9929 26.6384 16.6249 27.135C16.2568 27.6316 16.361 28.3325 16.8575 28.7006C18.3086 29.7762 20.0832 30.4129 22.0007 30.4129C23.9182 30.4129 25.6929 29.7762 27.1439 28.7006C27.6405 28.3325 27.7447 27.6316 27.3766 27.135C27.0085 26.6384 26.3076 26.5343 25.811 26.9023C24.7242 27.708 23.4122 28.1745 22.0007 28.1745C20.5892 28.1745 19.2773 27.708 18.1904 26.9023Z'
        fill='white'
      />
    </svg>
  )
}

export const IPassword = () => {}

export const IconEmail = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
      <path
        d='M3.6665 21.9999C3.6665 15.086 3.6665 11.629 5.81439 9.48114C7.96227 7.33325 11.4192 7.33325 18.3332 7.33325H25.6665C32.5804 7.33325 36.0374 7.33325 38.1853 9.48114C40.3332 11.629 40.3332 15.086 40.3332 21.9999C40.3332 28.9139 40.3332 32.3708 38.1853 34.5187C36.0374 36.6666 32.5804 36.6666 25.6665 36.6666H18.3332C11.4192 36.6666 7.96227 36.6666 5.81439 34.5187C3.6665 32.3708 3.6665 28.9139 3.6665 21.9999Z'
        stroke='var(--color-primary)'
        strokeWidth='2.75'
      />
      <path
        d='M11 14.6667L14.958 17.9651C18.3251 20.771 20.0087 22.174 22 22.174C23.9913 22.174 25.6749 20.771 29.042 17.9651L33 14.6667'
        stroke=' var(--color-secondary)'
        strokeWidth='2.75'
        strokeLinecap='round'
      />
    </svg>
  )
}

export const IconLocked = ({ isCircle = false }: { isCircle?: boolean }) => {
  return (
    <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.6665 29.3333C3.6665 24.1478 3.6665 21.5551 5.27742 19.9442C6.88833 18.3333 9.48105 18.3333 14.6665 18.3333H29.3332C34.5186 18.3333 37.1113 18.3333 38.7223 19.9442C40.3332 21.5551 40.3332 24.1478 40.3332 29.3333C40.3332 34.5187 40.3332 37.1114 38.7223 38.7223C37.1113 40.3333 34.5186 40.3333 29.3332 40.3333H14.6665C9.48105 40.3333 6.88833 40.3333 5.27742 38.7223C3.6665 37.1114 3.6665 34.5187 3.6665 29.3333Z'
        stroke='var(--color-secondary)'
        strokeWidth='2.75'
      />
      {!isCircle ? (
        <path d='M22 25.6667V33.0001' stroke='var(--color-primary)' strokeWidth='2.75' strokeLinecap='round' />
      ) : (
        <circle cx='22.0002' cy='29.3334' r='3.66667' stroke='var(--color-primary)' strokeWidth='2.75' />
      )}

      <path
        d='M11 18.3334V14.6667C11 8.59162 15.9249 3.66675 22 3.66675C28.0751 3.66675 33 8.59162 33 14.6667V18.3334'
        stroke='var(--color-primary)'
        strokeWidth='2.75'
        strokeLinecap='round'
      />
    </svg>
  )
}

export const IconTick = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
      <path
        d='M12.8335 23.65L18.5954 30.25L33.0002 13.75'
        stroke='white'
        strokeWidth='2.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
