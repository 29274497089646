import axios from 'axios'

const endpoint = 'https://outscope-api-demo-stag.atomsolution.vn/portal-service/webauthn'
export const passkeyAuthService = {
  generate_passkey_options: async (params: any) => {
    return await axios.post(`${endpoint}/register/generate-options`, params)
  },
  verify_registration: async (params: any) => {
    return await axios.post(`${endpoint}/register/verify`, params)
  },
}
