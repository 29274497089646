export const ROUTES = {
  home: '/',
  report: '/report',
  transaction: '/transaction',
  reward: '/reward',
  setting: '/setting',
  sign_in: '/sign_in',
  setting_theme: '/bank_theme',
  forgot_password: '/forgot_password',
  reset_password: '/reset_password',
  scan_qr: '/scan-qr',
}
