import { TTransaction } from '@src/types/transaction'

import { SkeletonTransactionCard, TransactionCard } from './transaction-card'

const TransactionList = ({ title, transactionList }: { title: string; transactionList: TTransaction[] }) => {
  return (
    <div className='w-full'>
      <p className='w-full px-3 py-2 text-sm font-semibold text-sub-600'>{title}</p>
      <div>
        {transactionList.map((item, index) => (
          <TransactionCard transaction={item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default TransactionList

export function SkeletonTransactionList() {
  return (
    <div className='w-full'>
      <p className='my-2 ml-3 h-5 w-24 animate-pulse rounded bg-soft-200'></p>
      <div>
        {new Array(4).fill(0).map((_, index) => (
          <SkeletonTransactionCard key={index} />
        ))}
      </div>
    </div>
  )
}
