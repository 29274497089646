import { posService } from '@src/store/services/pos.service'

export async function getRequestPos(body: { pos_code: string }) {
  try {
    const res = await posService.requestPos(body)
    if (res.status === 200) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export async function verifyOtpPos(body: { pos_code: string; otp_code: string }) {
  try {
    if (body.otp_code.length < 6) {
      return 'OTP yêu cầu 6 chữ số!'
    }
    const res = await posService.verifyPos(body)
    return await res.data
  } catch (error) {
    // if (isAxiosError(error)) {
    // }
    return 'OTP không đúng. Vui lòng thử lại!'
  }
}
