import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IArrowDown, INotification } from '@src/constants/icons/header.icon'
import { IScanHome } from '@src/constants/icons/home.icon'
import { IConBg } from '@src/constants/icons/icon-bg'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { useShopStore } from '@src/store/states/shop.state'

import ChooseBranchModal from './choose-branch-modal'

const HeaderInfo = () => {
  const navigate = useNavigate()
  const [isOpenChooseBranchModal, setIsOpenChooseBranchModal] = useState(false)
  const { currentShop } = useShopStore()

  return (
    <div
      id='app-header'
      className='group relative z-20 flex w-full origin-top translate-y-0 items-center justify-between gap-2 px-4 py-3 transition-all duration-500'
    >
      <div className='flex flex-1 origin-left items-center gap-2 transition-all duration-[35ms] ease-linear'>
        <img src={'./common/starbucks.png'} alt='' className='size-11 overflow-hidden rounded-full object-cover' />
        <div className='flex-1 overflow-auto'>
          <p className='text-xs leading-4 text-disable-300'>Xin chào,</p>
          <div
            className='flex w-full cursor-pointer items-center gap-2'
            onClick={() => setIsOpenChooseBranchModal(true)}
          >
            <p className='truncate break-all font-medium text-white'>{currentShop?.name}</p>
            <button className='text-primary-darker active:opacity-active'>
              <IArrowDown />
            </button>
            <ChooseBranchModal isOpen={isOpenChooseBranchModal} onClose={() => setIsOpenChooseBranchModal(false)} />
          </div>
        </div>
      </div>

      <div className='flex origin-right gap-2 transition-all duration-[35ms] ease-linear'>
        <IConBg
          className='!size-10 !bg-primary-darker'
          onClick={() => {
            navigate(ROUTES.scan_qr)
          }}
        >
          <span className='text-secondary [--secondary-color:theme(colors.secondary)]'>
            <IScanHome />
          </span>
        </IConBg>

        <IConBg className='!size-10 !bg-primary-darker'>
          <span className='text-secondary'>
            <INotification />
          </span>
        </IConBg>
      </div>
    </div>
  )
}

export default HeaderInfo
