import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IBranch, ITime } from '@src/constants/icons/report.icon'
import { CUSTOMER_ID, END_TIME, METHOD, REPORT_PAYMENT, REPORT_RESULT, START_TIME } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { useShopStore } from '@src/store/states/shop.state'
import { TShop } from '@src/types/shop'
import { formatTime } from '@src/utils/transaction'

import { BoxWrapper } from '../common/box-wrapper'
import { CustomButton } from '../common/custom-button'
import { LayoutHeader } from '../common/layout-header'
import ChooseBranchModal from '../home/choose-branch-modal'
import ModalDirection from '../modal/modal-direction'

import { ResultReport } from './result-report'
import { SelectInput } from './select-input'
import { TimeRangeModal } from './time-range-modal'

export type TDataFilter = {
  start_date: Date
  end_date: Date
  store?: TShop
  status: string
}

export const FilterReport = () => {
  const { currentShop } = useShopStore()
  const { getParams, deleteParams, setParams } = useQueryParams()
  const payment_method = getParams(REPORT_PAYMENT)
  const navigate = useNavigate()

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(0)

  const [dataFilter, setDataFilter] = useState<TDataFilter>({
    start_date: new Date(),
    end_date: new Date(),
    status: 'all',
    store: currentShop,
  })

  const filters = [
    { icon: <IBranch />, label: 'Chi nhánh', id: 1, value: dataFilter.store?.name ?? '' },
    {
      icon: <ITime />,
      label: 'Khoảng thời gian',
      id: 2,
      value: `${formatTime(dataFilter.start_date)} - ${formatTime(dataFilter.end_date)}`,
    },
  ]

  const handleDirect = () => {
    setParams({
      [REPORT_PAYMENT]: REPORT_RESULT,
      [CUSTOMER_ID]: dataFilter.store?.source_id ?? 1,
      [METHOD]: payment_method ?? '',
      [START_TIME]: dataFilter.start_date.getTime() / 1000,
      [END_TIME]: dataFilter.end_date.getTime() / 1000,
    })
  }

  const onClose = () => {
    try {
      navigate(-1)
    } catch (error) {
      deleteParams(REPORT_PAYMENT)
    }
  }

  return (
    <>
      <ModalDirection
        isOpen={!!payment_method}
        onClose={onClose}
        className='!bg-weak-50'
        header={<LayoutHeader onBack={onClose} title={'Báo cáo chi tiết giao dịch thanh toán'} />}
        footer={
          <div className='w-full p-4 pb-0'>
            <CustomButton
              onClick={() => handleDirect()}
              text={'Tiếp tục'}
              className={'rounded-[10px] !bg-primary text-white'}
            />
          </div>
        }
      >
        <div className='p-4'>
          <BoxWrapper className='px-3 py-4'>
            <>
              <div className='flex flex-col gap-4'>
                {filters.map((item, index) => (
                  <SelectInput
                    label={item.label}
                    key={index}
                    icon={item.icon}
                    defaultValue={item.value}
                    onClick={() => setIsOpenFilterModal(item.id)}
                    isExpand={index === item.id}
                  />
                ))}
              </div>
            </>
          </BoxWrapper>
        </div>
      </ModalDirection>
      <ChooseBranchModal
        isOpen={isOpenFilterModal === 1}
        onClose={() => setIsOpenFilterModal(0)}
        setValue={(value: TShop) => setDataFilter({ ...dataFilter, store: value })}
      />
      <TimeRangeModal
        isOpen={isOpenFilterModal === 2}
        onClose={() => setIsOpenFilterModal(0)}
        setValue={(startDate: Date, endDate: Date) =>
          setDataFilter({ ...dataFilter, start_date: startDate, end_date: endDate })
        }
      />
      <ResultReport />
    </>
  )
}
