import { Outlet } from 'react-router-dom'
import InstalPwa from '@src/components/instal-pwa-guide/install-pwa'
import { theme_bank } from '@src/constants/bank.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { setIsWantToInstallPWA, setShowIntro } from '@src/utils/local-storage'
import { getThemeBank, overrideManifest, setThemeBank } from '@src/utils/theme-color'

const BankThemeLayout = () => {
  const { getParams, deleteParams } = useQueryParams()
  const theme = getParams('t')
  if (theme) {
    if (theme == theme_bank.ocb.id || theme == theme_bank.bvb.id) {
      setThemeBank(theme)
      overrideManifest(theme)
    }
    deleteParams('t')
    setShowIntro(true)
    setIsWantToInstallPWA(true)
  } else {
    let bank_theme = getThemeBank()
    if (!bank_theme) {
      bank_theme = theme_bank.bvb.id
      setThemeBank(bank_theme)
      setShowIntro(true)
    }
    overrideManifest(bank_theme)
  }

  return (
    <>
      <Outlet />
      <InstalPwa />
    </>
  )
}

export default BankThemeLayout
