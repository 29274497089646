import { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { authService } from '@src/store/services/auth.service'
import { ACCESS_TOKEN, getRefreshToken, setAccessToken, setRefreshToken } from '@src/utils/local-storage'
import { setThemeColor } from '@src/utils/theme-color'

const AuthContext = createContext({
  token: '',
  user: '',
  loginAction: (data: any) => {},
  logOut: () => {},
  refreshToken: () => {},
})

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState('')
  const [token, setToken] = useState(localStorage.getItem(ACCESS_TOKEN) || '')

  const navigate = useNavigate()
  const loginAction = async (data: { email: string; password: string }) => {
    try {
      const response = await fetch('https://bvbank-stag.atomsolution.vn/v1/portal/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const res = await response.json()
      const email = data.email
      if (res.data && res.data.access_token && email) {
        setUser(email)
        setToken(res.data.access_token)
        setRefreshToken(res.data.refresh_token)
        setAccessToken(res.data.access_token)
        navigate(ROUTES.home)
        return
      }

      throw new Error(res.message || 'Login failed')
    } catch (err: any) {
      throw new Error('Login failed')

      // You might want to handle this error more gracefully,
      // such as showing an error message to the user
    }
  }

  const refreshToken = async () => {
    try {
      const response = await authService.get_refresh_token({
        refresh_token: getRefreshToken(),
      })
      setAccessToken(response.data.access_token)
      setRefreshToken(response.data.refresh_token)
    } catch (err: any) {
      logOut()
      throw new Error('Refresh token failed')
    }
  }

  const logOut = () => {
    setUser('')
    setToken('')
    localStorage.removeItem(ACCESS_TOKEN)
    setThemeColor('#ffffff')
    navigate(ROUTES.sign_in)
  }

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut, refreshToken }}>{children}</AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}
