import { useEffect, useMemo, useRef } from 'react'
import { reportService } from '@src/store/services/report.service'
import { useFilterStore } from '@src/store/states/filter.state'
import { TSearchControlItem } from '@src/types/search'
import { TTransaction } from '@src/types/transaction'
import { wait } from '@src/utils/common'
import { formatTitleTransaction } from '@src/utils/datetime'
import { formatParams } from '@src/utils/transaction'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'framer-motion'
import { useDebounce } from 'use-debounce'

import PullToRefresh from '../pull-to-refresh/pull-to-refresh'

import Accordion from './accordion'
import SearchControlResultEmpty from './search-control-result-empty'
import SearchFilterInfo from './search-filter-info'
import { SkeletonTransactionCard } from './transaction-card'
import TransactionList, { SkeletonTransactionList } from './transaction-list'

const SearchResult = ({ controlItem }: { controlItem: TSearchControlItem }) => {
  const {
    filterOptions: { startDate, endDate, transaction: type, textSearch, saleLocation },
    searchControl,
  } = useFilterStore()
  const debouncedTextSearch = useDebounce(textSearch, 300)[0]
  const reloadRef = useRef<null | HTMLDivElement>(null)

  const isInView = useInView(reloadRef)

  const {
    data: transactions,
    isLoading,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['transactions' + controlItem.value, debouncedTextSearch, searchControl?.value],
    queryFn: ({ pageParam }: { pageParam: number }) => {
      const offset = pageParam < 1 ? 1 : pageParam
      return reportService.getTxn(
        2877,
        searchControl && (searchControl.value === 'va' || searchControl.value === 'card') ? searchControl.value : 'va',
        formatParams({
          start_time: startDate ? startDate?.getTime() / 1000 : 0,
          end_time: endDate ? endDate?.getTime() / 1000 : 0,
          page: offset,
          status: type as 'all' | 'success' | 'pending' | 'fail',
          bill_id: debouncedTextSearch,
          customer_id: saleLocation === 0 ? '' : saleLocation,
        })
      )
    },

    enabled:
      !!searchControl &&
      searchControl.id === controlItem.id &&
      (searchControl?.value === 'all' || searchControl?.value === 'va' || searchControl?.value === 'card'),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.page < lastPage.total_page ? lastPage.page + 1 : null
    },
  })

  const displayData = useMemo(() => {
    const res: { title: string; list: TTransaction[] }[] = []
    transactions &&
      (searchControl?.value === 'all' || searchControl?.value === 'va' || searchControl?.value === 'card') &&
      transactions.pages.map((data) => {
        data.data.forEach((item: TTransaction) => {
          const date = new Date(item.created_at)
          const tilte = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
          if (!res.find((item) => item.title === tilte)) {
            res.push({
              title: tilte,
              list: [item],
            })
          } else {
            res.find((item) => item.title === tilte)?.list.push(item)
          }
        })
      })
    return res
  }, [transactions])

  useEffect(() => {
    if (isInView) fetchNextPage()
  }, [fetchNextPage, isInView, reloadRef.current])

  console.log('isInView', isInView)

  return (
    <div className='flex size-full flex-col'>
      <SearchFilterInfo isLoading={isLoading} list={transactions?.pages[0].data ?? []} />
      <div className='w-full flex-1 overflow-auto'>
        <PullToRefresh
          refreshingContentClassName='[&>div]:!bg-primary'
          onRefresh={async () => {
            await wait(500)
            refetch()
          }}
        >
          <div className='size-full overflow-y-auto p-4'>
            {isLoading ? (
              <Accordion isLoading>
                {[0, 1].map((_, index) => (
                  <SkeletonTransactionList key={index} />
                ))}
              </Accordion>
            ) : (
              <>
                {displayData.length == 0 ? (
                  <SearchControlResultEmpty />
                ) : (
                  <Accordion title={'Tất cả điểm bán'} className={`${isFetchingNextPage && '!rounded-none'}`}>
                    {displayData.map((item, index) => (
                      <TransactionList
                        key={index}
                        title={formatTitleTransaction(item.title)}
                        transactionList={item.list}
                      />
                    ))}
                  </Accordion>
                )}
              </>
            )}
            <div ref={reloadRef} className={`min-h-[20px] w-full ${isFetchingNextPage && 'rounded-b-2xl bg-white'}`}>
              {isFetchingNextPage && (
                <>
                  {[0, 1].map((_, index) => (
                    // <SkeletonTransactionList key={index} />
                    <SkeletonTransactionCard key={index} />
                  ))}
                </>
              )}
            </div>
          </div>
        </PullToRefresh>
      </div>
    </div>
  )
}

export default SearchResult
