export const IHomeBg = () => {
  return (
    <svg className='w-full' viewBox='0 0 414 298' fill='none' preserveAspectRatio='xMidYMid slice'>
      <g clipPath='url(#clip0_1037_1063)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M414 0H0V190.203C-32.0371 201.45 -51 215.395 -51 230.5C-51 267.779 64.5105 298 207 298C349.489 298 465 267.779 465 230.5C465 215.395 446.037 201.45 414 190.203V0Z'
          fill='url(#paint0_linear_1037_1063)'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_1037_1063' x1='207' y1='0' x2='207' y2='583.5' gradientUnits='userSpaceOnUse'>
          <stop stopColor='var(--light-color)' />
          <stop offset='1' stopColor='var(--darker-color)' />
        </linearGradient>
        <clipPath id='clip0_1037_1063'>
          <rect width='414' height='298' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IRefresh = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M3.06635 9.44444L2.31635 9.44444L2.31635 9.44444L3.06635 9.44444ZM3.06635 10.8333L2.5381 11.3657C2.83051 11.6559 3.30218 11.6559 3.59459 11.3657L3.06635 10.8333ZM4.99443 9.97685C5.28847 9.68511 5.29034 9.21024 4.9986 8.9162C4.70686 8.62216 4.23199 8.6203 3.93795 8.91204L4.99443 9.97685ZM2.19475 8.91204C1.90071 8.6203 1.42584 8.62216 1.13409 8.9162C0.842354 9.21024 0.84422 9.68511 1.13826 9.97685L2.19475 8.91204ZM15.4063 6.22612C15.6232 6.57899 16.0852 6.68918 16.438 6.47225C16.7909 6.25532 16.9011 5.79341 16.6842 5.44054L15.4063 6.22612ZM10.0656 1.75C5.79135 1.75 2.31635 5.18938 2.31635 9.44444H3.81635C3.81635 6.02889 6.60865 3.25 10.0656 3.25V1.75ZM2.31635 9.44444L2.31635 10.8333L3.81635 10.8333L3.81635 9.44444L2.31635 9.44444ZM3.59459 11.3657L4.99443 9.97685L3.93795 8.91204L2.5381 10.3009L3.59459 11.3657ZM3.59459 10.3009L2.19475 8.91204L1.13826 9.97685L2.5381 11.3657L3.59459 10.3009ZM16.6842 5.44054C15.3236 3.22738 12.8675 1.75 10.0656 1.75V3.25C12.3286 3.25 14.3092 4.44155 15.4063 6.22612L16.6842 5.44054Z'
        fill='currentColor'
      />
      <path
        d='M16.9284 9.16675L17.4556 8.63333C17.1635 8.34455 16.6934 8.34455 16.4012 8.63333L16.9284 9.16675ZM14.996 10.0222C14.7014 10.3134 14.6986 10.7883 14.9898 11.0829C15.281 11.3775 15.7558 11.3802 16.0504 11.0891L14.996 10.0222ZM17.8064 11.0891C18.101 11.3802 18.5758 11.3775 18.867 11.0829C19.1582 10.7883 19.1554 10.3134 18.8608 10.0222L17.8064 11.0891ZM4.53815 13.7729C4.32062 13.4204 3.85852 13.311 3.50602 13.5285C3.15353 13.746 3.04412 14.2081 3.26165 14.5606L4.53815 13.7729ZM9.90244 18.2501C14.1887 18.2501 17.6784 14.8134 17.6784 10.5556H16.1784C16.1784 13.9685 13.3768 16.7501 9.90244 16.7501V18.2501ZM17.6784 10.5556V9.16675H16.1784V10.5556H17.6784ZM16.4012 8.63333L14.996 10.0222L16.0504 11.0891L17.4556 9.70016L16.4012 8.63333ZM16.4012 9.70016L17.8064 11.0891L18.8608 10.0222L17.4556 8.63333L16.4012 9.70016ZM3.26165 14.5606C4.62756 16.774 7.09217 18.2501 9.90244 18.2501V16.7501C7.62837 16.7501 5.63935 15.5573 4.53815 13.7729L3.26165 14.5606Z'
        fill='currentColor'
      />
    </svg>
  )
}

export const IScanHome = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_203_1441)'>
        <path
          d='M8.33341 18.3333C5.19072 18.3333 3.61937 18.3333 2.64306 17.357C1.66675 16.3807 1.66675 15.6427 1.66675 12.5'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M18.3334 12.5C18.3334 15.6427 18.3334 16.3807 17.3571 17.357C16.3808 18.3333 14.8094 18.3333 11.6667 18.3333'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M11.6667 1.66675C14.8094 1.66675 16.3808 1.66675 17.3571 2.64306C18.3334 3.61937 18.3334 4.35738 18.3334 7.50008'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M8.33341 1.66675C5.19072 1.66675 3.61937 1.66675 2.64306 2.64306C1.66675 3.61937 1.66675 4.35738 1.66675 7.50008'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path d='M1.66675 10H18.3334' stroke='var(--secondary-color)' strokeWidth='1.5' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_203_1441'>
          <rect width='20' height='20' rx='4.16667' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
