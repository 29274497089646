import { getThemeBank } from '@src/utils/theme-color'

import { IHome, IMoreApp, IReport, IReward, ITransaction } from '../icons/navbar.icon'

import { ROUTES } from './routes.constant'

export const navbar = [
  {
    icon: IHome,
    title: 'Trang chủ',
    path: ROUTES.home,
    theme_bank: {
      ocb: {
        themeColor: '#008C4F',
        themeColorOverlay: '#1b3226',
      },
      bvb: {
        themeColor: '#232A75',
        themeColorOverlay: '#1c1f2f',
      },
    },
  },
  {
    icon: ITransaction,
    title: 'Giao dịch',
    path: ROUTES.transaction,
    theme_bank: {
      ocb: {
        themeColor: '#ffffff',
        themeColorOverlay: '#494949',
      },
      bvb: {
        themeColor: '#ffffff',
        themeColorOverlay: '#494949',
      },
    },
  },
  {
    icon: IReport,
    title: 'Báo cáo',
    path: ROUTES.report,
    theme_bank: {
      ocb: {
        themeColor: '#ffffff',
        themeColorOverlay: '#494949',
      },
      bvb: {
        themeColor: '#ffffff',
        themeColorOverlay: '#494949',
      },
    },
  },
  {
    icon: IReward,
    title: 'Điểm thưởng',
    path: ROUTES.reward,
    theme_bank: {
      ocb: {
        themeColor: '#ffffff',
        themeColorOverlay: '#494949',
      },
      bvb: {
        themeColor: '#ffffff',
        themeColorOverlay: '#494949',
      },
    },
  },
  {
    icon: IMoreApp,
    title: 'Xem thêm',
    path: ROUTES.setting,
    theme_bank: {
      ocb: {
        themeColor: '#027f49',
        themeColorOverlay: '#1b3226',
      },
      bvb: {
        themeColor: '#1c2872',
        themeColorOverlay: '#1c1f2f',
      },
    },
  },
]

export function getActivePageColor() {
  const path = window.location.pathname

  const sortedArr = Object.values(ROUTES).sort((a, b) => {
    return b.length - a.length
  })

  for (let i = 0; i < sortedArr.length; i++) {
    if (path.trim().includes(sortedArr[i])) {
      const themeBank = navbar.find((item) => item.path === sortedArr[i])?.theme_bank[getThemeBank()]

      if (themeBank) {
        return themeBank
      } else {
        return DefautPageColor
      }
    }
  }

  return DefautPageColor
}

export const DefautPageColor = {
  themeColor: '#ffffff',
  themeColorOverlay: '#494949',
}
