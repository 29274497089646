import { IEmpty } from '@src/constants/icons/empty.icon'

const SearchControlResultEmpty = () => {
  return (
    <div className='flex aspect-square w-full flex-col items-center justify-center gap-5 rounded-2xl bg-white'>
      <IEmpty />
      <p className='text-base font-medium text-soft-400'>Danh sách giao dịch trống</p>
    </div>
  )
}

export default SearchControlResultEmpty
