import { useFilterStore } from '@src/store/states/filter.state'
import { TSearchControlItem } from '@src/types/search'
import { getTitlePaymentMethod } from '@src/utils/transaction'

import Swiper from '../swiper/swiper'

import SearchBar from './search-bar'
import SearchResult from './search-result'

export const TransactionTypeItems: TSearchControlItem[] = [
  {
    id: '0',
    name: 'Tất cả',
    value: 'all',
  },
  {
    id: '1',
    value: 'card',
  },
  {
    id: '2',
    value: 'ecom',
  },
  {
    id: '3',
    value: 'wallet',
  },
  {
    id: '4',
    value: 'cash',
  },
  {
    id: '5',
    value: 'va',
  },
]

const Transaction = () => {
  const { searchControl, setSearchControl } = useFilterStore()

  return (
    <div className='flex size-full flex-col'>
      {/*  */}
      <div className='flex flex-col gap-3 bg-white pb-3 pt-2'>
        <p className='w-full px-4 text-center text-xl font-bold'>Quản lý giao dịch bán</p>
        <SearchBar controlItem={searchControl?.value ?? 'all'} />
      </div>

      <div className='w-full flex-1 overflow-auto'>
        <Swiper
          value={searchControl}
          onChange={setSearchControl}
          options={TransactionTypeItems}
          renderControlItem={(item) => {
            if (!item.name && item.value !== 'all') {
              return getTitlePaymentMethod(item.value, 'GD ')
            }
            return item.name
          }}
          renderOnlyIfInview={false}
          controlItemContainerClassName='bg-white'
        >
          {TransactionTypeItems.map((item, index) => {
            return <SearchResult key={index} controlItem={item} />
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default Transaction
