import { useMemo, useState } from 'react'
import { IMagnifier } from '@src/constants/icons/transaction.icon'
import { TDevice } from '@src/types/device'

import Swiper from '../swiper/swiper'
import Accordion from '../transaction/accordion'

import useConnectDeviceStore from './connect-device/connect-device.store'
import DeviceSection from './device-section'
import { TTab } from '.'

export const CarouselFilter = ({ type }: { type: TTab[] }) => {
  const [selectType, setSelectType] = useState<TTab>(type[0])
  const { connectDevice } = useConnectDeviceStore()
  const data = useMemo(() => {
    const dataAll = Array.from(connectDevice.values())
    const res: { branch_name: string; devices: TDevice[] }[] = []
    dataAll.forEach((item) => {
      if (item.branch_name) {
        const index = res.findIndex((item) => item.branch_name === item.branch_name)
        if (index === -1) {
          res.push({ branch_name: item.branch_name, devices: [item] })
        } else {
          res[index].devices.push(item)
        }
      }
    })
    return res
  }, [connectDevice])
  const [textSearch, setTextSearch] = useState('')
  // const debouncedTextSearch = useDebounce(textSearch, 300)[0]

  const handleOnchange = (value: TTab) => {
    setSelectType(value)
  }

  return (
    <>
      <div className='flex h-full flex-col bg-weak-50'>
        <div className='bg-white px-4 pt-3'>
          <label className='flex flex-1 items-center gap-2 rounded-full bg-weak-50 px-3 py-[6px]'>
            <IMagnifier />
            <div className='flex-1'>
              <input
                className='w-full bg-transparent text-sm font-normal text-soft-400 outline-none'
                placeholder='Tìm thiết bị'
                onChange={(e) => setTextSearch(e.target.value)}
              />
            </div>
          </label>
        </div>
        <div className='w-full flex-1 overflow-auto'>
          <Swiper
            value={selectType}
            onChange={handleOnchange}
            options={type}
            renderControlItem={(item) => {
              return item.name
            }}
            renderOnlyIfInview={false}
            controlItemContainerClassName='bg-white'
          >
            {type.map((_, index) => {
              return (
                <div className='p-4' key={index}>
                  {data.map((item) => {
                    return (
                      <Accordion title={item.branch_name} key={index} className='!bg-inherit'>
                        <DeviceSection devices={item.devices} />
                      </Accordion>
                    )
                  })}
                </div>
              )
            })}
          </Swiper>
        </div>
      </div>
    </>
  )
}
