import { useEffect, useRef } from 'react'
import { BoxWrapper } from '@src/components/common/box-wrapper'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import { useQueryParams } from '@src/hooks/use-query-params'
import { TModule } from '@src/types/modules'

import useCarouselSection from './carousel-section.controller'

export const CarouselSection = ({
  modules,
  title,
  id,
  className,
}: {
  modules: TModule[]
  title: string
  id: string
  className?: string
}) => {
  const sliderRef = useRef<HTMLElement | null>(null)
  const scrollableRef = useRef<HTMLDivElement | null>(null)
  const scrollbarRef = useRef<HTMLDivElement | null>(null)

  const { getTypeFeature } = useCarouselSection()

  let isDown = false
  let startX = 0
  let scrollLeft = 0

  useEffect(() => {
    const slider = document.getElementById(id) as HTMLElement | null

    if (slider) {
      sliderRef.current = slider

      const onMouseDown = (e: MouseEvent) => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      }

      const onMouseLeave = () => {
        isDown = false
        slider.classList.remove('active')
      }

      const onMouseUp = () => {
        isDown = false
        slider.classList.remove('active')
      }

      const onMouseMove = (e: MouseEvent) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 4
        slider.scrollLeft = scrollLeft - walk
      }

      const onScroll = () => {
        if (scrollableRef.current && scrollbarRef.current) {
          const ratio =
            scrollableRef.current.scrollLeft / (scrollableRef.current.scrollWidth - scrollableRef.current.clientWidth)
          scrollbarRef.current.style.transform = `translateX(${ratio * ((scrollbarRef.current.clientWidth * modules.length) / 4 - scrollbarRef.current.clientWidth)}px)`
        }
      }

      slider.addEventListener('mousedown', onMouseDown)
      slider.addEventListener('mouseleave', onMouseLeave)
      slider.addEventListener('mouseup', onMouseUp)
      slider.addEventListener('mousemove', onMouseMove)
      scrollableRef.current?.addEventListener('scroll', onScroll)

      return () => {
        slider.removeEventListener('mousedown', onMouseDown)
        slider.removeEventListener('mouseleave', onMouseLeave)
        slider.removeEventListener('mouseup', onMouseUp)
        slider.removeEventListener('mousemove', onMouseMove)
        scrollableRef.current?.removeEventListener('scroll', onScroll)
      }
    }
  }, [id])

  // const { mutate: generateShopQR } = useMutation({
  //   mutationFn: (vaAccountNo: string) => shopQrService.generateShopQr({ vaAccountNo }),
  //   onSuccess: (data) => {
  //     setQrData(data.data)
  //   },
  // })

  // const handleShowQrCode = () => {
  //   setOpenQR(true)
  //   generateShopQR(currentShop?.va_account_no || '')
  // }

  const { setParams } = useQueryParams()

  return (
    <BoxWrapper>
      <>
        <p className='px-4 text-[18px] font-semibold tracking-[-0.36px]'>{title}</p>
        <div
          className='container flex w-full min-w-full snap-x snap-mandatory items-start overflow-auto pb-3'
          id={id}
          ref={scrollableRef}
        >
          {modules.map((item, index) => (
            <button
              onClick={getTypeFeature(item.id)}
              className={`${className} flex w-[25%] min-w-[25%] flex-col items-center justify-center px-2.5 ${index === modules.length - 1 ? 'snap-end' : 'snap-start'}`}
              key={index}
            >
              <IconWrapper
                size={48}
                rounded={'16px'}
                className='size-[34px] rounded-xl border border-primary/10 !bg-inherit'
              >
                {item.icon}
              </IconWrapper>
              <span className='text-center text-sm font-medium'>{item.title}</span>
            </button>
          ))}
        </div>

        <div className='relative mx-auto mt-2 h-1 w-[11%] rounded-full bg-soft-200'>
          <div
            className='absolute left-0 top-0 h-full rounded-full bg-primary'
            style={{ width: `${(4 / modules.length) * 100}%` }}
            ref={scrollbarRef}
          />
        </div>
      </>
    </BoxWrapper>
  )
}
