import { useState } from 'react'
import { IMove } from '@src/constants/icons/device.icons'
import { DEVICE_DETAIL, OPEN } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { TDevice } from '@src/types/device'

import QuickActionModal from './quick-action-modal'

const DeviceSection = ({ devices }: { devices: TDevice[] }) => {
  const [openDevice, setOpenDevice] = useState<TDevice | undefined>()
  const { setParams } = useQueryParams()
  return (
    <>
      <div className='flex flex-col gap-2'>
        {devices.map((device, index) => (
          <div
            className='flex w-full cursor-pointer items-start justify-between gap-3 rounded-[10px] bg-white pb-3 pl-3 pr-2 pt-2'
            key={index}
            onClick={() => setOpenDevice(device)}
          >
            <div>
              <div onClick={(e) => e.stopPropagation()}>
                <div
                  className='flex items-center gap-1'
                  onClick={() => {
                    setParams({ [OPEN]: DEVICE_DETAIL, id: device.id })
                  }}
                >
                  <span className='text-sm font-medium leading-5 tracking-[-0.14px] text-strong-950'>
                    {device.device_name ?? 'POS Q190'}
                  </span>
                  <button>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                      <path
                        d='M8.33332 6.66683L11.3954 9.41094C11.7571 9.73501 11.7571 10.2653 11.3954 10.5894L8.33332 13.3335'
                        stroke='#99A0AE'
                        strokeWidth='1.5'
                        strokeMiterlimit='10'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <p className='mt-2 text-xs leading-5 tracking-[-0.21px] text-soft-400'>
                {device.store_name ?? device.pos_code}
              </p>
              <button
                className={`mt-2 flex h-6 items-center rounded-lg px-3 ${device.status === 'active' ? 'bg-primary' : device.status === 'activation' ? 'bg-secondary' : 'bg-faded-base'}`}
              >
                <span className='text-xs font-semibold leading-4 tracking-[-0.12px] text-white'>
                  {device.status === 'active' ? 'Hoạt động' : device.status === 'activation' ? 'Đợi kích hoạt' : 'Khóa'}
                </span>
              </button>
            </div>
            <button>
              <IMove />
            </button>
          </div>
        ))}
      </div>

      <QuickActionModal device={openDevice} onClose={() => setOpenDevice(undefined)} />
    </>
  )
}

export default DeviceSection
