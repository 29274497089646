import { IFaceId } from '@src/constants/icons/icon-sign-in'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import useWebAuthn from '@src/hooks/use-webauthn'

const FaceIdButton = () => {
  const { register, authenticate, isLoading } = useWebAuthn()
  const handleAuthenPasskey = async () => {
    const newCredential = await register({
      username: 'frey2k5',
    })
    if (newCredential) {
      const response = await authenticate(newCredential)
      console.log(response)
    }
  }

  console.log(isLoading)
  return (
    <button onClick={handleAuthenPasskey} className='flex items-center justify-center'>
      <IconWrapper size={76} rounded='full' className='border-[2px]'>
        <IFaceId />
      </IconWrapper>
    </button>
  )
}

export default FaceIdButton
