import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '@src/components/home/navbar'
import { authService } from '@src/store/services/auth.service'
import { useAccountStore } from '@src/store/states/account.state'
import { getIsNotSignIn, getShowIntro } from '@src/utils/local-storage'
import { useQuery } from '@tanstack/react-query'

import PageIntro from './page-intro/page-intro'

const MainLayout = () => {
  const { setMyProfile } = useAccountStore()

  useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      try {
        const res = await authService.myProfile()
        if (res) {
          setMyProfile(res)
        }
        return res
      } catch (error) {
        console.error(error)
        return null
      }
    },
    enabled: !getIsNotSignIn(),
  })

  return (
    <React.Suspense>
      <PageIntro control={getShowIntro()}>
        <div data-page={true} className='flex size-full flex-col'>
          <div className='flex-1 overflow-y-auto overflow-x-hidden bg-main-background pb-navbar-height'>
            <Outlet />
          </div>
          <Navbar />
        </div>
      </PageIntro>
    </React.Suspense>
  )
}

export default MainLayout
