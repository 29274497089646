import { useFilterStore } from '@src/store/states/filter.state'
import { TTransaction } from '@src/types/transaction'
import { formatCurrency } from '@src/utils/transaction'

import { openToast } from '../toaster/custom-toast'

export default function SearchFilterInfo({ isLoading, list }: { isLoading?: boolean; list: TTransaction[] }) {
  const {
    filterOptions: { startDate, endDate },
  } = useFilterStore()

  return (
    <div className='flex items-center justify-between gap-2 border-b border-soft-200 bg-white px-5 py-3'>
      <div className='flex flex-col gap-[2px]'>
        {isLoading ? (
          <div className='h-5 w-40 animate-pulse rounded bg-soft-200'></div>
        ) : (
          <p className='text-sm font-bold leading-5 text-strong-950'>
            Từ {startDate?.getDate().toString().padStart(2, '0')}/{startDate && startDate?.getMonth() + 1}{' '}
            {endDate && '-'} {endDate && endDate?.getDate().toString().padStart(2, '0')}
            {endDate && '/'}
            {endDate && endDate?.getMonth() + 1}
          </p>
        )}

        <div
          onClick={() => openToast({ message: 'Đang phát triển' })}
          className='flex cursor-pointer items-center gap-2'
        >
          {isLoading ? (
            <div className='h-5 w-32 animate-pulse rounded bg-soft-200'></div>
          ) : (
            <div className='flex items-center gap-1'>
              <p className='text-sm font-normal leading-5 text-sub-600'>Tổng giao dịch:</p>
              <p className='text-sm font-medium leading-5 text-strong-950'>{list.length}</p>
            </div>
          )}
          <div className='h-[15px] w-[1px] bg-soft-200' />
          {isLoading ? (
            <div className='h-5 w-36 animate-pulse rounded bg-soft-200'></div>
          ) : (
            <div className='flex items-center gap-1'>
              <p className='text-sm font-normal leading-5 text-sub-600'>Tổng thu:</p>
              <p className='text-sm font-medium leading-5 text-strong-950'>
                {formatCurrency(list.filter((item) => item.status === 'success').reduce((a, b) => a + b.amount, 0))}đ
              </p>
            </div>
          )}
        </div>
      </div>

      <button>
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
          <path
            d='M8 14L11.6745 10.7071C12.1085 10.3182 12.1085 9.68182 11.6745 9.29293L8 6'
            stroke='#99A0AE'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  )
}
