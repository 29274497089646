import { useEffect } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

const OcbIntro = ({ onFinish }: { onFinish: () => void }) => {
  const logo = useAnimationControls()
  const header = useAnimationControls()
  const subheader = useAnimationControls()
  const container = useAnimationControls()
  useEffect(() => {
    async function play() {
      await logo.start({
        scale: 1,
        rotate: 0,
        transition: {
          delay: 0.1,
        },
      })
      await header.start({ maxWidth: '200px' })
      await subheader.start({ maxWidth: '200px' })
      await container.start({
        opacity: 0,
        pointerEvents: 'none',
        transition: {
          delay: 0.5,
        },
      })
      onFinish()
    }
    play()
  }, [])
  return (
    <>
      <motion.div
        initial={{ opacity: 1 }}
        animate={container}
        transition={{ ease: 'easeInOut' }}
        className='fixed inset-0 z-[10000] flex items-center justify-center bg-primary pb-36 drop-shadow-md'
      >
        <motion.div initial={{ scale: 0, rotate: -360 }} animate={logo} transition={{ ease: 'easeInOut' }}>
          <CoinSvg />
        </motion.div>

        <motion.div className='relative'>
          <motion.div
            initial={{ maxWidth: '0px' }}
            animate={header}
            transition={{ ease: 'easeInOut' }}
            className='overflow-hidden pl-[10.5px]'
          >
            <OCB />
          </motion.div>
          <motion.div
            initial={{ maxWidth: '0px' }}
            animate={subheader}
            transition={{ ease: 'easeInOut' }}
            className='absolute bottom-[-8.5px] right-0 translate-y-full overflow-hidden'
          >
            <NHPD />
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}

export default OcbIntro

function CoinSvg() {
  return (
    <svg width='80' height='81' viewBox='0 0 80 81' fill='none'>
      <path
        d='M0.167294 44.4489C1.30047 53.9711 5.60859 62.3653 11.961 68.4855C11.2796 66.4437 11.0524 64.402 11.5067 62.5848C12.867 56.9164 18.5355 53.0651 26.4753 51.9294V24.7178C5.60858 21.0886 -1.19558 34.2426 0.167294 44.4489Z'
        fill='#E49F15'
      />
      <path
        d='M79.5352 35.6035C78.3995 26.0787 74.0939 17.6896 67.7441 11.5669C68.4255 13.3815 68.6501 15.4233 68.1958 17.4625C66.8355 23.1335 61.1671 26.9873 53.2297 28.123V55.3345C74.094 58.9612 80.6684 45.5826 79.5352 35.6035Z'
        fill='#E49F15'
      />
      <path
        d='M0.846313 31.0679C6.2876 23.5823 16.0396 19.2742 33.956 24.4909C65.4756 33.3343 75.6793 12.6972 57.7654 4.30811C52.3242 1.58747 46.1989 0 39.8516 0C20.802 0.0025522 4.92983 13.3812 0.846313 31.0679Z'
        fill='#E49F15'
      />
      <path
        d='M78.8535 48.7544C73.4122 56.24 63.6602 60.5481 45.7464 55.3314C13.9996 46.4855 3.79335 67.1226 21.7072 75.7388C27.1485 78.462 33.2712 80.0469 39.6211 80.0469C58.8978 79.8197 74.7751 66.6683 78.8535 48.7544Z'
        fill='#E49F15'
      />
    </svg>
  )
}

function OCB() {
  return (
    <svg width='186' height='64' viewBox='0 0 186 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M35.298 0.476074C16.251 0.476074 0.9021 14.7071 0.9021 32.1616C0.9021 49.6161 16.3812 63.8471 35.298 63.8471C54.2149 63.8471 69.694 49.6161 69.694 32.1616C69.694 14.7071 54.2992 0.476074 35.298 0.476074ZM35.298 52.3699C30.2243 52.0713 25.4772 49.7794 22.0879 45.9945C18.6986 42.2096 16.9401 37.2379 17.1979 32.1642C16.9171 27.0751 18.6628 22.0804 22.0547 18.2777C25.4466 14.4749 30.209 12.1702 35.298 11.8716C40.3718 12.1702 45.1189 14.4621 48.5082 18.247C51.8976 22.0319 53.656 27.0036 53.3983 32.0774C53.656 37.1512 51.9001 42.1228 48.5082 45.9077C45.1189 49.6927 40.3693 51.9845 35.298 52.2831'
        fill='white'
      />
      <path
        d='M185.776 44.8046C185.702 42.5153 185.131 40.2694 184.1 38.2276C183.02 36.0557 181.407 34.1926 179.414 32.8093C178.258 31.99 176.999 31.3239 175.672 30.8313C177.791 29.8615 179.638 28.3863 181.047 26.5309C183.007 23.6546 183.946 20.2014 183.714 16.7279C183.699 14.7066 183.262 12.7107 182.433 10.8681C181.604 9.02537 180.401 7.37409 178.901 6.02143C175.131 3.12469 170.445 1.69035 165.701 1.97875H131.606V62.1698H161.444C165.979 62.277 170.499 61.6236 174.815 60.2352C178.255 59.1837 181.267 57.0603 183.413 54.1738C185.294 51.3944 186.172 48.0587 185.907 44.7153L185.776 44.8046ZM157.485 11.182C164.966 11.182 167.505 12.7286 167.505 18.3613C167.505 23.1339 164.58 25.1119 157.746 25.1119H153.746L149.446 36.3773H158.044C165.525 36.3773 169.437 38.8708 169.437 44.5902C169.437 50.3072 165.137 52.8032 157.7 52.8032H147.853V11.2687L157.485 11.182Z'
        fill='white'
      />
      <path
        d='M125.283 47.469C121.246 50.6643 116.243 52.3922 111.095 52.3692C105.516 52.5555 100.093 50.524 96.0069 46.7212C91.9208 42.9184 89.509 37.6532 89.2972 32.0767C89.4988 26.495 91.9081 21.2222 95.9967 17.4168C100.085 13.6115 105.516 11.5851 111.095 11.7816C116.243 11.7586 121.248 13.4865 125.283 16.6818V4.38532C119.939 1.73359 114.051 0.363056 108.086 0.386026C88.654 0.386026 72.8738 14.5737 72.8738 32.0716C72.8738 49.5694 88.6515 63.7571 108.086 63.7571C114.059 63.7852 119.952 62.4019 125.283 59.7144V47.469Z'
        fill='white'
      />
    </svg>
  )
}

function NHPD() {
  return (
    <svg width='186' height='17' viewBox='0 0 186 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.7488 9.21966C10.7488 8.23196 10.7488 7.19832 10.7488 6.21062V3.45935C10.7488 2.68603 10.7488 1.65239 10.7488 0.450305H8.34202C8.34202 1.05262 8.34202 1.82594 8.34202 2.98719C8.34202 4.14844 8.34202 5.09275 8.34202 6.51177V8.61734L7.52533 7.11154L6.14969 4.61804L4.94506 2.46909L3.87057 0.447754H0.904907C0.904907 1.47884 0.904907 2.46909 0.904907 3.58696V9.34727C0.904907 10.4217 0.904907 11.3686 0.904907 12.2721V12.4865H3.39842V12.2721C3.39842 11.1108 3.39842 10.2941 3.39842 9.5642C3.39842 8.83427 3.39842 7.54286 3.39842 6.38161V4.18928L8.08425 12.4865H11.0091V12.2721C11.0091 11.3252 11.0091 10.3375 11.0091 9.21966'
        fill='white'
      />
      <path
        d='M16.9798 3.02832C16.3341 3.03342 15.6935 3.15083 15.0887 3.37287C14.4813 3.55918 13.9325 3.89862 13.4987 4.36057C13.0086 4.84038 12.6386 5.42995 12.4242 6.08076C12.169 6.85662 12.0516 7.67077 12.0796 8.48747C12.0924 9.29907 12.2991 10.0928 12.682 10.81C12.9729 11.3715 13.4195 11.8334 13.9708 12.1422C14.4812 12.4306 15.0606 12.5786 15.6476 12.571C16.1887 12.5761 16.7195 12.4434 17.1942 12.1831C17.4903 12.0019 17.7532 11.767 17.9676 11.494C18.0467 12.0937 18.0467 12.7012 17.9676 13.3009C17.8936 13.6582 17.6945 13.9773 17.4086 14.2044C17.0743 14.4111 16.6838 14.5158 16.2908 14.5056C15.3107 14.4545 14.3486 14.2197 13.4527 13.8165H13.1515L13.3659 16.2232C13.9019 16.3763 14.4481 16.4912 14.9993 16.5677C15.6017 16.6111 16.204 16.6111 16.8063 16.5677C17.7634 16.6213 18.7077 16.3304 19.4708 15.751C19.8256 15.3682 20.091 14.9114 20.2467 14.4111C20.4023 13.9109 20.4457 13.3851 20.3743 12.8696C20.3743 9.51601 20.3743 6.37681 20.3743 3.62554C19.3049 3.35756 18.21 3.19932 17.1075 3.15338L16.9798 3.02832ZM18.0977 8.31648C18.0799 8.89072 17.8833 9.4471 17.5388 9.90649C17.4112 10.0724 17.2504 10.2102 17.0641 10.3072C16.8778 10.4042 16.6736 10.4578 16.4643 10.4654C16.2295 10.5343 15.9794 10.5445 15.7369 10.4909C15.497 10.4373 15.2724 10.3251 15.0887 10.1643C14.6982 9.71253 14.4991 9.12553 14.5298 8.53086C14.4762 7.90047 14.5808 7.26497 14.8309 6.68307C15.0121 6.27216 15.3311 5.93783 15.7344 5.7362C16.1147 5.52692 16.546 5.42228 16.9824 5.43504C17.2963 5.38145 17.6154 5.38145 17.9293 5.43504C18.0059 6.43551 18.0059 7.44363 17.9293 8.44409'
        fill='white'
      />
      <path
        d='M25.1509 1.39464L25.7967 2.16796H27.4735L26.4857 0.233398H23.7345L22.8744 2.16796H24.5946L25.1509 1.39464Z'
        fill='white'
      />
      <path
        d='M28.8492 10.1668C28.8492 9.30673 28.8492 8.44664 28.8492 7.54316V5.5652C28.8492 3.88841 27.688 3.02832 25.6258 3.02832C24.5283 3.03853 23.4385 3.2274 22.4024 3.58726V5.7362H22.7469C23.1552 5.51672 23.5891 5.34317 24.0358 5.22066C24.5105 5.0854 25.003 5.01393 25.4982 5.00628C26.5293 5.00628 26.6594 5.43505 26.6594 5.7796C26.6594 6.12414 26.6594 6.33852 26.3583 6.38191C25.7636 6.65499 25.146 6.87193 24.5105 7.02762C23.727 7.25476 22.997 7.63504 22.3615 8.14548C22.1037 8.4007 21.9072 8.71207 21.7873 9.05406C21.6673 9.39605 21.629 9.76358 21.675 10.1234C21.6724 10.4833 21.7439 10.8432 21.8843 11.1749C22.0246 11.5067 22.2314 11.8079 22.4917 12.058C22.775 12.3311 23.1144 12.5404 23.4845 12.6756C23.8545 12.8084 24.2501 12.8619 24.6432 12.8339H26.0188L26.4068 10.6849L26.0188 10.8993C26.0188 10.8993 25.5901 10.8993 24.9444 11.1571C24.2986 11.4148 23.9132 10.6415 23.9132 10.297C23.8903 10.1413 23.9107 9.98562 23.972 9.84014C24.0332 9.69722 24.1302 9.57216 24.2578 9.48028C24.707 9.2174 25.1817 9.00047 25.6768 8.83458C26.0265 8.7427 26.3608 8.59722 26.6645 8.40581C26.6645 10.2536 26.6645 11.6292 26.6645 12.7063V12.9206H29.158V12.7063C29.158 12.0172 29.158 11.3306 29.158 10.6007'
        fill='white'
      />
      <path
        d='M38.4355 10.9403C38.4355 10.5958 38.4355 9.99346 38.4355 9.09254V5.86912C38.3973 5.35357 38.2824 4.84569 38.091 4.36332C37.869 3.91924 37.5269 3.54661 37.1033 3.28884C36.5724 3.01576 35.9803 2.88304 35.3831 2.90091C34.8905 2.86518 34.3954 2.95195 33.9462 3.15357C33.4945 3.3552 33.104 3.66912 32.8028 4.06216V3.11529H30.3093V3.32967C30.3093 6.16772 30.3093 9.13337 30.3093 11.928V12.1424H32.8462V8.14312C32.8232 7.8292 32.8232 7.51273 32.8462 7.19625C32.8462 5.77723 33.5761 5.13153 34.5664 5.13153C35.5541 5.13153 35.942 5.64707 35.9854 6.76493C36.0288 7.8828 35.9854 9.98836 35.9854 11.9255V12.1399H38.6091V11.9255C38.6091 11.4099 38.6091 10.9378 38.6091 10.5932'
        fill='white'
      />
      <path
        d='M54.2989 0.447505H51.5476C51.5476 1.39437 51.5476 2.89761 51.5476 4.74795H47.2472C47.2472 4.23241 47.2472 3.5867 47.2472 2.81339V0.0621211H44.5393C44.5393 1.9533 44.5393 3.88787 44.5393 5.90921C44.5393 7.93055 44.5393 9.9085 44.5393 11.8865V12.1008H47.2472V11.7129C47.2651 11.399 47.2651 11.0825 47.2472 10.766V6.81014H51.762C51.762 7.71361 51.762 8.5737 51.762 9.3904C51.762 10.2071 51.762 11.1106 51.762 11.8839V12.0983H54.5133V11.8839C54.5133 8.70131 54.5133 4.70457 54.5133 0.0595703'
        fill='white'
      />
      <path d='M60.7078 2.3843L59.7609 0.235352H56.9229L58.7298 2.3843H60.7078Z' fill='white' />
      <path
        d='M62.9002 10.1662V5.56717C62.9002 3.89037 61.7389 3.03027 59.7176 3.03027C58.6201 3.04048 57.5303 3.22935 56.4942 3.58921V5.73816H56.8387C57.2292 5.51101 57.6477 5.33746 58.0867 5.22261C58.564 5.0899 59.054 5.01844 59.5491 5.00823C60.5802 5.00823 60.7537 5.437 60.7537 5.78155C60.7537 6.1261 60.7538 6.34048 60.4526 6.38387C59.8579 6.65695 59.2378 6.87389 58.6048 7.02957C57.8187 7.24651 57.0863 7.62678 56.4559 8.14743C56.1981 8.40265 56.0016 8.71402 55.8816 9.05601C55.7617 9.39801 55.7234 9.76553 55.7693 10.1254C55.7617 10.4827 55.8255 10.84 55.9607 11.1718C56.0935 11.5036 56.2925 11.8073 56.5452 12.06C56.8387 12.3305 57.1858 12.5372 57.5635 12.6699C57.9412 12.8026 58.3394 12.8588 58.7401 12.8333H60.0723L60.5011 10.6843L60.1132 10.8987C59.7712 11.085 59.3832 11.1743 58.9953 11.1565C58.1352 11.1565 58.0076 10.6409 58.0076 10.3398C57.9719 10.179 57.9846 10.0105 58.0459 9.85741C58.1071 9.70428 58.2143 9.57156 58.3521 9.47968C58.7962 9.20659 59.2735 8.99221 59.7712 8.83398C60.1106 8.74975 60.4322 8.60428 60.718 8.40521C60.718 10.3398 60.718 11.7154 60.718 12.7057V12.92H63.1681V12.7057C63.1681 12.0166 63.1681 11.33 63.1681 10.6001'
        fill='white'
      />
      <path
        d='M72.4434 10.9396V7.15722C72.4638 6.76928 72.4638 6.38391 72.4434 5.99597C72.4485 5.47532 72.3286 4.95978 72.0989 4.49018C71.9049 4.02823 71.5552 3.64795 71.1111 3.4157C70.5828 3.13751 69.9882 3.00479 69.391 3.02776C68.906 2.99458 68.4186 3.08391 67.9745 3.28554C67.5304 3.48716 67.145 3.79853 66.8541 4.18902V3.24214H64.3606V3.45653C64.3606 6.2078 64.3606 9.17346 64.3606 12.0549V12.2693H66.9409V11.6236C66.9179 11.3377 66.9179 11.0493 66.9409 10.7635C66.9409 10.2045 66.9409 9.77577 66.9409 9.38784C66.9409 8.99991 66.9409 8.61452 66.9409 8.26998C66.9409 7.92543 66.9409 7.58089 66.9409 7.32312C66.9409 5.90409 67.7142 5.25838 68.7044 5.25838C69.6921 5.25838 70.0801 5.77393 70.1234 6.89179C70.1668 8.00965 70.1234 10.1152 70.1234 12.0523V12.2667H72.7471V12.0523C72.7471 11.5368 72.7471 11.1055 72.7471 10.7201'
        fill='white'
      />
      <path
        d='M78.2922 3.02832C77.6312 3.03087 76.9778 3.14573 76.3576 3.37287C75.7553 3.56939 75.2092 3.90883 74.7676 4.36057C74.2776 4.84038 73.9101 5.42995 73.6931 6.08076C73.4328 6.85407 73.3282 7.67332 73.392 8.48747C73.392 9.29397 73.5834 10.0903 73.9509 10.81C74.2444 11.3842 74.7089 11.8513 75.2832 12.1422C75.7936 12.4332 76.3729 12.5812 76.96 12.571C77.4883 12.5812 78.0063 12.4459 78.4657 12.1831C78.7669 11.9891 79.04 11.7568 79.2825 11.494C79.3641 12.0937 79.3641 12.7012 79.2825 13.3009C79.2544 13.4821 79.1906 13.6531 79.0936 13.8088C78.9966 13.9645 78.8715 14.0972 78.7235 14.2044C78.3892 14.4111 77.9987 14.5158 77.6057 14.5056C76.6409 14.4494 75.6941 14.2146 74.811 13.8165H74.4664L74.7242 16.2232C75.2449 16.3763 75.7783 16.4912 76.3142 16.5677C76.9166 16.6111 77.5189 16.6111 78.1212 16.5677C79.091 16.6086 80.0456 16.3202 80.8291 15.751C81.166 15.358 81.4161 14.8986 81.5641 14.4009C81.7121 13.9058 81.7555 13.3826 81.6892 12.8696C81.6892 9.47263 81.6892 6.37681 81.6892 3.62554C80.6326 3.36011 79.553 3.20187 78.4657 3.15338L78.2922 3.02832ZM79.3667 8.44664C79.3335 9.02599 79.1242 9.58237 78.7644 10.0367C78.6367 10.2026 78.4759 10.3404 78.2896 10.4373C78.1033 10.5343 77.8992 10.5879 77.6899 10.5956C77.4704 10.593 77.256 10.5471 77.0569 10.4578C76.8579 10.3684 76.6767 10.2408 76.5312 10.08C76.1407 9.6283 75.9416 9.0413 75.9723 8.44664C75.9314 7.81114 76.0488 7.17565 76.3168 6.59885C76.4955 6.20071 76.7966 5.86892 77.1769 5.65198C77.5597 5.44525 77.9885 5.34316 78.4249 5.35082C78.7516 5.29978 79.0859 5.29978 79.4126 5.35082C79.4126 6.81323 79.4126 7.88771 79.4126 8.35986'
        fill='white'
      />
      <path
        d='M104.733 10.4647V5.86568C104.746 5.44456 104.672 5.02601 104.516 4.63297C104.36 4.23993 104.128 3.88518 103.83 3.58657C103.209 3.08634 102.431 2.82857 101.637 2.85664C100.994 2.84643 100.361 2.99446 99.7894 3.28541C99.4806 3.48703 99.2178 3.7499 99.0161 4.05872C99.0161 2.72647 99.0161 1.47845 99.0161 0.276367H96.4792C96.4792 4.57682 96.4792 8.35918 96.4792 12.1007V12.3151H99.0595V12.1007C98.9727 10.7404 98.9727 9.37751 99.0595 8.01719C99.0493 7.28215 99.2254 6.55733 99.5751 5.91162C99.7052 5.71255 99.8839 5.55432 100.096 5.44712C100.308 5.34248 100.542 5.29399 100.78 5.3093C100.966 5.28633 101.157 5.3042 101.336 5.3629C101.515 5.4216 101.678 5.52113 101.813 5.65384C102.104 6.03667 102.255 6.50629 102.242 6.9861C102.242 8.4919 102.242 10.2095 102.242 12.1032V12.3176H104.866V12.1032C104.866 11.5009 104.866 10.8986 104.866 10.2963'
        fill='white'
      />
      <path
        d='M132.637 10.9396V7.15722C132.66 6.77184 132.66 6.38391 132.637 5.99597C132.626 5.48043 132.524 4.96999 132.335 4.49018C132.108 4.03588 131.748 3.66071 131.304 3.4157C130.776 3.1324 130.181 2.99713 129.584 3.02776C129.099 2.99458 128.612 3.08391 128.168 3.28554C127.724 3.48716 127.338 3.79853 127.047 4.18902V3.24214H124.554V3.45653C124.554 6.25119 124.554 9.21685 124.554 12.0549V12.2693H127.134V11.5393C127.114 11.2816 127.114 11.0238 127.134 10.766V8.27253C127.111 7.95861 127.111 7.64214 127.134 7.32567C127.134 5.90665 127.864 5.26093 128.854 5.26093C129.842 5.26093 130.23 5.77648 130.273 6.89434C130.317 8.0122 130.273 10.1178 130.273 12.0549V12.2693H132.897V12.0549C132.897 11.5827 132.897 11.108 132.897 10.7226'
        fill='white'
      />
      <path
        d='M138.484 3.02832C137.823 3.03087 137.17 3.14573 136.549 3.37287C135.947 3.56939 135.401 3.90883 134.959 4.36057C134.474 4.84548 134.107 5.4325 133.885 6.08076C133.645 6.85918 133.543 7.67332 133.584 8.48747C133.584 9.29397 133.775 10.0903 134.143 10.81C134.436 11.3842 134.901 11.8513 135.475 12.1422C135.985 12.4332 136.565 12.5812 137.152 12.571C137.68 12.5812 138.201 12.4459 138.657 12.1831C138.959 11.9891 139.232 11.7568 139.474 11.494C139.556 12.0937 139.556 12.7012 139.474 13.3009C139.41 13.6531 139.229 13.9721 138.959 14.2044C138.619 14.406 138.234 14.5081 137.841 14.5056C136.861 14.4545 135.899 14.2197 135.003 13.8165H134.658L134.916 16.2232C135.437 16.3763 135.97 16.4912 136.506 16.5677C137.108 16.6111 137.711 16.6111 138.313 16.5677C139.283 16.6086 140.237 16.3202 141.021 15.751C141.376 15.3682 141.638 14.9114 141.786 14.4111C141.934 13.9109 141.968 13.3851 141.881 12.8696C141.881 9.3884 141.881 6.37681 141.881 3.62554C140.824 3.36011 139.745 3.20187 138.655 3.15338L138.484 3.02832ZM139.558 8.44664C139.507 9.02088 139.298 9.57216 138.956 10.0367C138.834 10.1974 138.678 10.3327 138.502 10.4271C138.323 10.5241 138.127 10.5803 137.925 10.5956C137.7 10.5981 137.476 10.5522 137.269 10.4629C137.062 10.3736 136.876 10.2434 136.72 10.08C136.33 9.6283 136.131 9.0413 136.161 8.44664C136.121 7.81114 136.238 7.17565 136.506 6.59885C136.685 6.20071 136.986 5.86892 137.366 5.65198C137.749 5.44525 138.178 5.34316 138.614 5.35082C138.941 5.29722 139.275 5.29722 139.604 5.35082C139.604 6.85662 139.604 7.88771 139.604 8.35986'
        fill='white'
      />
      <path
        d='M156.628 1.56843C156.171 1.11414 155.627 0.756834 155.027 0.522031C154.43 0.284677 153.787 0.174928 153.144 0.192794H148.114C148.114 2.04059 148.114 3.63316 148.114 4.96541H147.083V7.11436H148.545C148.545 8.87793 148.545 10.5113 148.545 12.0146V12.229H152.843C153.966 12.257 155.071 11.9584 156.025 11.3689C156.855 10.7666 157.483 9.92688 157.83 8.96215C158.202 7.86981 158.361 6.71621 158.302 5.56517C158.366 3.99302 157.827 2.4566 156.796 1.26472L156.628 1.56843ZM155.51 7.67329C155.329 8.30368 154.953 8.86006 154.435 9.26331C153.815 9.68442 153.078 9.89625 152.33 9.86563H150.954V7.02758H153.404V4.83524H150.911V2.17075H153.233C153.754 2.14268 154.269 2.29325 154.693 2.59952C155.066 2.92365 155.347 3.33966 155.51 3.80416C155.709 4.47283 155.768 5.17469 155.681 5.86889C155.729 6.60903 155.643 7.35171 155.423 8.06122'
        fill='white'
      />
      <path
        d='M166.728 5.30756C166.409 4.62102 165.903 4.03912 165.268 3.63077C164.579 3.22241 163.79 3.01313 162.989 3.02845C162.177 2.98251 161.376 3.202 160.702 3.65118C160.028 4.10037 159.518 4.75884 159.25 5.52195C158.905 6.3361 158.76 7.21916 158.818 8.10222C158.846 8.68923 158.961 9.26858 159.163 9.82241C159.336 10.3584 159.615 10.856 159.98 11.2848C160.352 11.7314 160.822 12.0836 161.355 12.3159C161.919 12.5839 162.537 12.7166 163.162 12.7038C164.035 12.7319 164.895 12.4767 165.612 11.9739C166.245 11.5094 166.725 10.8663 166.988 10.1261C167.256 9.3528 167.371 8.53609 167.332 7.71939C167.299 6.87972 167.095 6.06046 166.728 5.30756ZM164.451 9.60802C164.352 9.86324 164.173 10.0802 163.941 10.2282C163.708 10.3762 163.435 10.4451 163.162 10.4247C162.904 10.4324 162.647 10.3788 162.414 10.2665C162.182 10.1542 161.981 9.98574 161.827 9.77901C161.47 9.26092 161.276 8.64584 161.268 8.01545C161.187 7.23958 161.35 6.45606 161.741 5.77972C161.871 5.54492 162.065 5.35095 162.302 5.22079C162.537 5.09063 162.805 5.03192 163.073 5.04979C163.328 5.07021 163.573 5.15443 163.785 5.29735C163.994 5.44027 164.165 5.63679 164.277 5.86649C164.599 6.42797 164.762 7.06858 164.75 7.71429C164.813 8.4238 164.681 9.13841 164.364 9.77901'
        fill='white'
      />
      <path
        d='M162.862 1.39464L163.465 2.16796H165.141L164.195 0.233398H161.443L160.583 2.16796H162.26L162.862 1.39464Z'
        fill='white'
      />
      <path
        d='M176.447 10.9396V7.15722C176.468 6.76928 176.468 6.38391 176.447 5.99597C176.437 5.48043 176.335 4.96999 176.146 4.49018C175.911 4.05375 175.572 3.68368 175.156 3.4157C174.61 3.14261 174.005 3.0099 173.392 3.02776C172.907 2.99458 172.42 3.08391 171.976 3.28554C171.532 3.48716 171.146 3.79853 170.855 4.18902V3.24214H168.362V3.45653C168.362 6.29457 168.362 9.26024 168.362 12.0549V12.2693H170.942V12.0549C170.942 11.7103 170.942 11.2816 170.942 10.766V8.27253C170.942 7.92798 170.942 7.58344 170.942 7.32567C170.942 5.90665 171.716 5.26093 172.706 5.26093C173.696 5.26093 174.081 5.77648 174.125 6.89434C174.168 8.0122 174.125 10.0769 174.125 12.0549V12.2693H176.792V12.0549C176.817 11.6108 176.817 11.1667 176.792 10.7226'
        fill='white'
      />
      <path
        d='M185.777 3.54299C184.72 3.27756 183.641 3.11933 182.551 3.07084C181.905 3.06829 181.262 3.18569 180.66 3.41538C180.039 3.60169 179.478 3.94114 179.026 4.40309C178.541 4.88801 178.174 5.47501 177.952 6.12327C177.712 6.90169 177.61 7.71584 177.651 8.52999C177.651 9.33649 177.842 10.1328 178.21 10.8525C178.503 11.4267 178.97 11.8938 179.542 12.1847C180.052 12.4757 180.632 12.6237 181.219 12.6135C181.757 12.6186 182.291 12.4859 182.765 12.2256C183.061 12.0444 183.322 11.8096 183.539 11.5365C183.62 12.1362 183.62 12.7437 183.539 13.3434C183.518 13.5246 183.457 13.7007 183.36 13.8564C183.263 14.0121 183.135 14.1448 182.98 14.2444C182.758 14.3822 182.51 14.4715 182.25 14.5098C181.992 14.5455 181.726 14.5302 181.476 14.4587C180.496 14.4077 179.534 14.1729 178.638 13.7696H178.337L178.552 16.1764C179.072 16.3295 179.603 16.4444 180.142 16.5209H181.949C182.918 16.5618 183.873 16.2734 184.656 15.7042C185.011 15.3214 185.277 14.8645 185.43 14.3643C185.585 13.8666 185.629 13.3383 185.557 12.8228C185.557 9.42581 185.557 6.32999 185.557 3.57872L185.777 3.54299ZM183.286 8.31561C183.253 8.89496 183.043 9.45133 182.684 9.90562C182.561 10.0664 182.405 10.1991 182.229 10.2961C182.051 10.3931 181.854 10.4492 181.652 10.4646C181.428 10.4671 181.203 10.4212 180.997 10.3318C180.79 10.2425 180.604 10.1124 180.448 9.94901C180.057 9.49728 179.858 8.91027 179.889 8.31561C179.848 7.68011 179.965 7.04461 180.233 6.46781C180.412 6.06967 180.713 5.73789 181.094 5.52095C181.489 5.30912 181.933 5.20703 182.382 5.21979C182.696 5.16875 183.015 5.16875 183.329 5.21979C183.329 6.72559 183.329 7.75668 183.329 8.22883'
        fill='white'
      />
      <path
        d='M113.803 5.09295C114.522 4.85304 115.132 4.36557 115.523 3.71731C115.64 3.38552 115.645 3.02566 115.538 2.69132C115.428 2.35699 115.212 2.06859 114.921 1.86952C114.637 1.68576 114.311 1.58367 113.974 1.56836C113.54 1.60154 113.124 1.74956 112.769 1.99713L113.114 2.944C113.254 2.90571 113.402 2.90571 113.542 2.944H113.757C113.833 3.04098 113.874 3.16348 113.874 3.28854C113.874 3.4136 113.833 3.53355 113.757 3.63308C113.757 3.63308 113.757 3.63309 113.757 3.84748V3.50292H111.263C111.35 5.13633 111.35 6.77229 111.263 8.40315C111.263 9.26324 110.835 10.2509 109.673 10.2509C108.512 10.2509 108.341 9.7354 108.298 8.61753C108.254 7.49967 108.298 4.31708 108.298 3.50037H105.761C105.761 5.26394 105.761 6.59619 105.761 7.80083C105.761 9.00547 105.761 9.26323 105.761 9.86555C105.748 10.2611 105.817 10.6567 105.967 11.0242C106.118 11.3918 106.345 11.7236 106.634 11.9941C106.922 12.2672 107.267 12.4739 107.642 12.6041C108.017 12.7342 108.415 12.7802 108.811 12.7444C109.283 12.7802 109.755 12.7011 110.189 12.5147C110.623 12.3284 111.006 12.0375 111.304 11.67V12.53H113.67V12.3157C113.67 10.0799 113.67 7.71405 113.67 5.13633'
        fill='white'
      />
      <path
        d='M122.446 4.61817C122.806 4.3604 123.102 4.02351 123.306 3.63047C123.421 3.30634 123.423 2.95159 123.316 2.62491C123.206 2.29823 122.992 2.01748 122.704 1.82606C122.443 1.62954 122.127 1.5249 121.8 1.5249C121.353 1.55553 120.925 1.70356 120.552 1.95368L120.897 2.90054C121.052 2.85971 121.216 2.85971 121.369 2.90054H121.583C121.655 3.00263 121.69 3.12259 121.69 3.24509C121.69 3.3676 121.652 3.48755 121.583 3.58964C121.473 3.7249 121.343 3.83975 121.195 3.93418C120.516 3.49775 119.723 3.27316 118.916 3.28848C118.365 3.27316 117.819 3.37269 117.308 3.57942C116.798 3.78615 116.336 4.09752 115.95 4.49056C115.499 5.01121 115.157 5.62119 114.95 6.27965C114.743 6.93812 114.674 7.63232 114.746 8.31886C114.718 8.90076 114.807 9.48266 115.009 10.0314C115.213 10.5775 115.524 11.0778 115.925 11.4989C116.328 11.92 116.811 12.2569 117.347 12.4866C117.883 12.7163 118.459 12.8337 119.044 12.8337C119.582 12.8618 120.121 12.775 120.624 12.5836C121.126 12.3922 121.586 12.0961 121.969 11.7158C122.854 10.6643 123.288 9.30401 123.173 7.93348C123.168 6.78499 122.79 5.66967 122.099 4.75088L122.446 4.61817ZM120.427 10.1666C120.284 10.3147 120.108 10.4321 119.917 10.5061C119.723 10.5801 119.516 10.6107 119.309 10.598C118.061 10.598 117.546 9.30911 117.461 8.23208C117.349 7.34902 117.581 6.45575 118.107 5.73858C118.255 5.59311 118.429 5.48081 118.623 5.4068C118.814 5.33278 119.021 5.29961 119.225 5.30727C120.772 5.30727 121.032 7.02744 121.073 7.75737C121.172 8.61236 120.943 9.475 120.427 10.1666Z'
        fill='white'
      />
      <path
        d='M95.4892 3.54359C95.4509 2.96679 95.3208 2.40021 95.1013 1.8668C94.8945 1.38954 94.55 0.986291 94.1135 0.705549C93.6873 0.396733 93.1769 0.233398 92.6511 0.233398H87.8785C87.8785 1.35126 87.8785 2.42574 87.8785 3.3726C87.8785 4.31947 87.8785 5.35055 87.8785 6.21064V9.21969C87.8785 10.2942 87.8785 11.241 87.8785 12.0577V12.2721H90.4154V12.0577C90.4154 11.1976 90.201 3.88814 90.201 3.2016V2.25474H91.5767C91.9391 2.22411 92.304 2.31343 92.6078 2.51251C92.7328 2.64267 92.8298 2.79835 92.8885 2.96935C92.9472 3.14035 92.9702 3.32155 92.9523 3.50276C93.0034 3.9596 92.8808 4.42155 92.6078 4.79162C92.3372 5.07492 91.9876 5.27144 91.6048 5.35566C91.2219 5.43988 90.8238 5.40926 90.4588 5.26633L90.931 7.41528C91.9697 7.60414 93.0391 7.40507 93.94 6.85635C94.4964 6.49904 94.9175 5.97073 95.1446 5.35055C95.3769 4.73292 95.479 4.07445 95.4458 3.41598'
        fill='white'
      />
    </svg>
  )
}
