import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IClear } from '@src/constants/icons/icon-sign-in'
import { useAuth } from '@src/providers/auth-provider'
import { setShowIntro } from '@src/utils/local-storage'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

import { openToast } from '../toaster/custom-toast'

import FaceIdButton from './face-id-button'
import { InputField } from './input-field'

const formSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .matches(
      /^[^\s][a-zA-Z0-9._+-]{0,62}[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]{2,253}\.[a-zA-Z]{2,63}$/,
      'Please enter a valid email'
    ),
  password: yup
    .string()
    .required('Password is required')
    .test('custom-validation', 'Please enter a valid password', (value) => {
      if (value) {
        const normalized = value
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s/g, '')
        return normalized === value
      }
      return false
    }),
})

export type FormFields = yup.InferType<typeof formSchema>

const SignInForm = () => {
  const { loginAction } = useAuth()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(formSchema),
  })
  const { mutate: sign_in, isPending } = useMutation({
    mutationFn: async () =>
      loginAction({
        email: watch('email'),
        password: watch('password'),
      }),
    onError: () => {
      setError('password', { message: 'Mật khẩu không hợp lệ' })
      setError('email', { message: 'Email không hợp lệ' })
      openToast({
        type: 'error',
        message: 'Sai thông tin đăng nhập. Vui lòng thử lại.',
        duration: 3000,
      })
    },
    onSuccess: () => {
      setShowIntro(true)
    },
  })

  const onSubmit = async () => {
    try {
      clearErrors()
      await formSchema.validate(
        {
          email: watch('email'),
          password: watch('password'),
        },
        { abortEarly: false }
      )
      sign_in()
    } catch (err: unknown) {
      if (err instanceof yup.ValidationError) {
        err.inner.map((error) => {
          setError(error.path as 'email' | 'password', { message: error.message })
        })
      }
    }
  }

  return (
    <div className='flex w-full flex-col items-center gap-4 overflow-visible'>
      <form className='w-full px-2' onSubmit={handleSubmit(() => onSubmit())}>
        <div className='flex w-full flex-col gap-4'>
          <InputField
            placeholder={'Nhập tên tài khoản'}
            register={register}
            registerValue='email'
            title={'Tên tài khoản'}
            sign_in={onSubmit}
            clearButton={
              watch('email') ? (
                <button type='button' onClick={() => reset({ email: '' })}>
                  <IClear />
                </button>
              ) : (
                <></>
              )
            }
            errorsMessage={errors.email?.message}
            showError={!!errors.email?.message}
          />
          <InputField
            isPassword
            placeholder={'Nhập mật khẩu'}
            register={register}
            registerValue='password'
            title={'Mật khẩu'}
            sign_in={onSubmit}
            errorsMessage={errors.password?.message}
            showError={!!errors.password?.message}
          />
        </div>
      </form>
      <a href='/forgot_password' className='w-full cursor-pointer px-2 text-end text-base font-medium text-primary'>
        Quên mật khẩu?
      </a>

      <div className='w-fit px-2 py-12'>
        <FaceIdButton />
      </div>

      <div className='flex w-full flex-col gap-4'>
        <button
          disabled={isPending}
          className={`flex h-[50px] w-full items-center justify-center rounded-xl bg-primary active:opacity-active disabled:opacity-50 sm:shadow-[0px_12px_21px_4px_rgba(68,97,242,0.15)] ${isPending && 'cursor-not-allowed opacity-50'}`}
          onClick={() => onSubmit()}
        >
          <span className='text-base font-medium text-white'>
            {isPending ? (
              <div className='h-5 w-5 animate-spin rounded-full border-b-2 border-t-2 border-white' />
            ) : (
              'Đăng nhập'
            )}
          </span>
        </button>
      </div>
    </div>
  )
}

export default SignInForm
