export function getDateRange(year: number, month: number): { startDate: Date; endDate: Date } {
  // Ngày đầu tiên của tháng
  const firstDayOfMonth = new Date(year, month - 1, 1)

  // Tính toán ngày đầu tiên là thứ Hai của tuần đầu tiên chứa ngày đầu tiên của tháng
  const firstDayWeekday = firstDayOfMonth.getDay()
  const startDate = new Date(firstDayOfMonth)
  startDate.setDate(firstDayOfMonth.getDate() - ((firstDayWeekday + 6) % 7))

  // Ngày cuối cùng của tháng
  const lastDayOfMonth = new Date(year, month, 0)

  // Tính toán ngày Chủ nhật cuối cùng của tuần chứa ngày cuối cùng của tháng
  const lastDayWeekday = lastDayOfMonth.getDay()
  const endDate = new Date(lastDayOfMonth)
  if (lastDayWeekday === 0) {
    endDate.setDate(lastDayOfMonth.getDate())
  } else endDate.setDate(lastDayOfMonth.getDate() + (7 - lastDayWeekday))

  return { startDate, endDate }
}

export function getAllDatesInRange(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = []
  const currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}
