export const IArrowBack = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M11.6667 4.1665L6.66666 9.99984L11.6667 15.8332'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const IHeadphone = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M3 18V12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V18M21 19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H18C17.4696 21 16.9609 20.7893 16.5858 20.4142C16.2107 20.0391 16 19.5304 16 19V16C16 15.4696 16.2107 14.9609 16.5858 14.5858C16.9609 14.2107 17.4696 14 18 14H21V19ZM3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H6C6.53043 21 7.03914 20.7893 7.41421 20.4142C7.78929 20.0391 8 19.5304 8 19V16C8 15.4696 7.78929 14.9609 7.41421 14.5858C7.03914 14.2107 6.53043 14 6 14H3V19Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const INotification = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M19.0001 9.7041V9C19.0001 5.13401 15.8661 2 12.0001 2C8.13407 2 5.00006 5.13401 5.00006 9V9.7041C5.00006 10.5491 4.74995 11.3752 4.28123 12.0783L3.13263 13.8012C2.08349 15.3749 2.88442 17.5139 4.70913 18.0116C9.48258 19.3134 14.5175 19.3134 19.291 18.0116C21.1157 17.5139 21.9166 15.3749 20.8675 13.8012L19.7189 12.0783C19.2502 11.3752 19.0001 10.5491 19.0001 9.7041Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export const IArrowDown = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M0 8C0 3.58172 3.58172 0 8 0L12 0C16.4183 0 20 3.58172 20 8L20 12C20 16.4183 16.4183 20 12 20L8 20C3.58172 20 0 16.4183 0 12L0 8Z'
        fill='currentColor'
      />
      <path
        d='M14 8L10.7071 11.6745C10.3182 12.1085 9.68182 12.1085 9.29293 11.6745L6 8'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const IClose2 = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M7.03552 7.10657L12.9645 13.0355M6.96443 13.1066L13.0355 7.0355'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const IRadio = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='2.3999'
        y='2.3999'
        width='19.2'
        height='19.2'
        rx='9.6'
        fill='var(--fill)'
        stroke='currentColor'
        strokeWidth={2}
      />
      <rect x='8' y='8' width='8' height='8' rx='4' fill='var(--fill-2)' />
    </svg>
  )
}

export const IQuestion = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_714_3125)'>
        <path
          d='M1.66663 9.99984C1.66663 6.07147 1.66663 4.10728 2.88701 2.88689C4.1074 1.6665 6.07159 1.6665 9.99996 1.6665C13.9283 1.6665 15.8925 1.6665 17.1129 2.88689C18.3333 4.10728 18.3333 6.07147 18.3333 9.99984C18.3333 13.9282 18.3333 15.8924 17.1129 17.1128C15.8925 18.3332 13.9283 18.3332 9.99996 18.3332C6.07159 18.3332 4.1074 18.3332 2.88701 17.1128C1.66663 15.8924 1.66663 13.9282 1.66663 9.99984Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
        <path
          d='M8.4375 7.396C8.4375 6.53305 9.13706 5.8335 10 5.8335C10.8629 5.8335 11.5625 6.53305 11.5625 7.396C11.5625 7.96887 11.2542 8.46973 10.7945 8.74173C10.3984 8.97608 10 9.33159 10 9.79183V10.8335'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <ellipse cx='9.99996' cy='13.3333' rx='0.833333' ry='0.833333' fill='currentColor' />
      </g>
      <defs>
        <clipPath id='clip0_714_3125'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
