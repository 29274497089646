import { useFilterStore } from '@src/store/states/filter.state'

import Calendar from '../common/calendar'
import ModalHeader from '../modal/header/modal-header'
import ModalBottom from '../modal/modal-bottom'

export const TimeRangeModal = ({
  isOpen,
  onClose,
  setValue,
}: {
  isOpen: boolean
  onClose: () => void
  setValue: (startDate: Date, endDate: Date) => void
}) => {
  const { calendarFilterOptions } = useFilterStore()
  const { resetCalendarFilterOptions } = useFilterStore()

  // const TimeBox = ({ date }: { date: string }) => {
  //   return (
  //     <div className='flex w-full items-center gap-2 rounded-[10px] border border-soft-200 px-3 py-2'>
  //       <ICalendar />
  //       <span className='text-sm leading-5 tracking-[-0.21px] text-strong-950'>{date}</span>
  //     </div>
  //   )
  // }

  const handleClose = () => {
    calendarFilterOptions.startDate &&
      calendarFilterOptions.endDate &&
      setValue(calendarFilterOptions.startDate, calendarFilterOptions.endDate)

    onClose()
  }

  return (
    <ModalBottom
      isOpen={isOpen}
      onClose={() => handleClose()}
      className='bg-weak-50'
      header={<ModalHeader>Khoảng thời gian</ModalHeader>}
      classNameIosPb='bg-white'
      footer={
        <div
          style={{
            boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.04)',
          }}
          className='flex items-center justify-center gap-[18px] bg-white px-4 pt-4'
        >
          <button onClick={resetCalendarFilterOptions} className='flex-1 px-4 py-3 underline'>
            <span className='text-base font-medium leading-6 text-sub-600'>Mặc định</span>
          </button>

          <button onClick={handleClose} className='flex-1 rounded-full bg-primary/10 px-4 py-3'>
            <span className='text-base font-medium leading-6 text-primary'>Áp dụng</span>
          </button>
        </div>
      }
    >
      <div className='p-4'>
        {/* <div className='flex flex-col gap-5 rounded-[10px] bg-white p-4'> */}
        {/* <div className='flex justify-between gap-2'>
            {calendarFilterOptions.startDate && <TimeBox date={formatTime(calendarFilterOptions.startDate)} />}
            {calendarFilterOptions.startDate && (
              <TimeBox date={formatTime(calendarFilterOptions.endDate ?? calendarFilterOptions.startDate)} />
            )}
          </div> */}
        <Calendar />
        {/* </div> */}
      </div>
    </ModalBottom>
  )
}
