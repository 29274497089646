import { IBVBankLogo, IOCBankLogo } from './icons/bank.icon'

export type TBank = 'ocb' | 'bvb'

export const home_banner: Record<TBank, string> = {
  ocb: './bank-theme/OCB_Banner.png',
  bvb: './bank-theme/BVB_Banner.png',
}

export const logo: Record<TBank, string> = {
  ocb: './bank-theme/OCB_Logo.png',
  bvb: './bank-theme/BVB_Logo.png',
}

export const lightLogo: Record<TBank, string> = {
  ocb: './bank-theme/OCB_LightLogo.png',
  bvb: './bank-theme/BVB_LightLogo.png',
}

export const theme_bank: Record<TBank, { id: TBank; title: string; primary: string; htmlTitle: string }> = {
  ocb: {
    id: 'ocb',
    title: 'OCB',
    primary: '#008C4F',
    htmlTitle: 'OCB',
  },
  bvb: {
    id: 'bvb',
    title: 'BVBank',
    primary: '#232A75',
    htmlTitle: 'DIGISTORE',
  },
}

export const QRLogo: Record<TBank, () => JSX.Element> = {
  ocb: IOCBankLogo,
  bvb: IBVBankLogo,
}
