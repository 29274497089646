import { theme_bank } from '@src/constants/bank.constants'
import { IEmpty } from '@src/constants/icons/empty.icon'
import { getThemeBank } from '@src/utils/theme-color'

import { BoxWrapper } from '../common/box-wrapper'

export const EmptyDevice = () => {
  return (
    <div className='p-4'>
      <BoxWrapper className='flex flex-col gap-[32px] p-4 shadow-none'>
        <>
          <div className='p-2'>
            <p className='text-[18px] font-bold tracking-[-0.18px] text-neutral-200'>
              Quản lý thiết bị {theme_bank[getThemeBank()].id.toUpperCase()} Box{' '}
            </p>
            <p className='mt-4 text-sm leading-5 tracking-[-0.21px] text-neutral-300'>
              Danh sách các thiết bị đã được cấp quyền sử dụng chức năng Thanh toán{' '}
              {theme_bank[getThemeBank()].id.toUpperCase()} Box tại Đại lý{' '}
              <span className='font-semibold text-primary'>Starbucks</span>
            </p>
          </div>
          <div className='flex flex-col items-center gap-5'>
            <IEmpty />
            <span className='text-base font-medium leading-6 tracking-[-0.32px] text-soft-400'>
              Đại lý chưa có thiết bị nào đăng ký <br /> dịch vụ {theme_bank[getThemeBank()].id.toUpperCase()} Box
            </span>
          </div>
        </>
      </BoxWrapper>
    </div>
  )
}
