import { TBank, theme_bank } from '@src/constants/bank.constants'
import { BANK } from '@src/constants/key.constants'

export const getThemeColor = () => {
  return document.querySelector('meta[name="theme-color"]')?.getAttribute('content')
}

export const setThemeColor = (color: string) => {
  document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color)
}

export const getThemeBank = () => {
  return localStorage.getItem(BANK) as TBank
}
export const setThemeBank = (bank: TBank) => {
  localStorage.setItem(BANK, bank)
  document.body.className = bank
}

export function overrideManifest(bank: TBank) {
  try {
    document.querySelector('link[rel="manifest"]')?.setAttribute('href', `/manifest/${bank}.json`)
    document.querySelector('link[rel="icon"]')?.setAttribute('href', `/favicon/${bank}.svg`)
    const title = document.querySelector('title')
    if (title) {
      title.innerText = theme_bank[bank].htmlTitle
    }
  } catch (error) {
    alert(error)
  }

  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.ready.then((registration) => {
  //     registration.active?.postMessage({ type: 'CLEAR_CACHE' })
  //   })
  // }
}
