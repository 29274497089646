import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IRefresh } from '@src/constants/icons/home.icon'
import { IConBg } from '@src/constants/icons/icon-bg'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { TTransaction } from '@src/types/transaction'
import { getRandomId } from '@src/utils/common'

import { BoxWrapper } from '../common/box-wrapper'
import { CustomButton } from '../common/custom-button'
import { TransactionCard } from '../transaction/transaction-card'

export const ex_transition: TTransaction[] = [
  {
    bill_id: '',
    amount: 120000000,
    from: {
      id: '97419219821982198',
      name: 'Nguyễn Trần Trâm Anh',
    },
    is_received: true,
    payment_method: 'card',
    created_at: '12:23 - 02/10/2024',
    is_read: false,
    status: 'success',
  },
  {
    bill_id: '',
    amount: 150000,
    from: {
      id: '97419219821982198',
      name: 'Phan Huy Tâm',
    },
    is_received: true,
    payment_method: 'cash',
    created_at: '12:23 - 02/10/2024',
    is_read: false,
    status: 'fail',
  },
  {
    bill_id: '',
    amount: 220000,
    from: {
      id: '97419219821982198',
      name: 'Nguyễn Hoài Quốc',
    },
    is_received: true,
    payment_method: 'ecom',
    created_at: '12:23 - 02/10/2024',
    is_read: false,
    status: 'pending',
  },
  {
    bill_id: '',
    amount: 15000,
    from: {
      id: '97419219821982198',
      name: 'Lê Bảo Huy',
    },
    is_received: true,
    payment_method: 'va',
    created_at: '12:23 - 02/10/2024',
    is_read: false,
    status: 'fail',
  },
  {
    bill_id: '',
    amount: 8000,
    from: {
      id: '97419219821982198',
      name: 'Phùng Ngọc Nhung',
    },
    is_received: true,
    payment_method: 'wallet',
    created_at: '12:23 - 02/10/2024',
    is_read: false,
    status: 'success',
  },
]

function randomDate(start: Date, end: Date): Date {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export function getFakeDataTransactions() {
  const newRes: TTransaction[] = []
  const types = ['card', 'cash', 'ecom', 'va', 'wallet']
  const status = ['success', 'success', 'success', 'success', 'success', 'success', 'pending']
  ex_transition.forEach((item) => {
    types.forEach((type) => {
      status.forEach((status) => {
        newRes.push({
          ...item,
          payment_method: type as TTransaction['payment_method'],
          status: status as TTransaction['status'],
          created_at: randomDate(new Date('2024-10-01 00:00:00'), new Date()).toISOString(),
          bill_id: getRandomId(),
        })
      })
    })
  })
  return newRes.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
}
export const fakeData = getFakeDataTransactions()

export const RecentTransactions = () => {
  const [isRefresh, setRefresh] = useState(false)
  const navigate = useNavigate()

  const handleRefresh = async () => {
    setRefresh(true)
  }

  useEffect(() => {
    if (isRefresh)
      setTimeout(() => {
        setRefresh(false)
      }, 2000)
  }, [isRefresh])

  return (
    <BoxWrapper>
      <>
        <div className='flex items-center gap-1 px-4'>
          <div className='flex-1'>
            <p className='text-base font-semibold tracking-[-0.18px] text-strong-950'>Giao dịch gần đây</p>
            <p className='text-xs leading-5 tracking-[-0.12px] text-soft-400'>Cập nhật lúc 1:40 - 04/10/2024</p>
          </div>
          <IConBg
            className={`${isRefresh ? 'refresh-spin' : ''} !size-[32px] bg-primary/10 !text-primary`}
            onClick={() => handleRefresh()}
          >
            <IRefresh />
          </IConBg>
        </div>
        <div>
          {fakeData
            .filter((item) => item.status == 'success')
            .slice(0, 5)
            .map((item, index) => (
              <TransactionCard transaction={item} key={index} />
            ))}
        </div>
        <div className='px-4'>
          <CustomButton
            text={'Xem thêm'}
            onClick={() => {
              navigate(ROUTES.transaction)
            }}
          />
        </div>
      </>
    </BoxWrapper>
  )
}
