import { invoiceService } from '@src/store/services/fake-invocie.service'
export type TTable = {
  table_id: number
  table_name: string
  customer_id: number
  is_available: boolean
  order_id: number
  total_price: number
  product_amount: number
}

export const useFakeInvoice = () => {
  const handleAddItem = async (order_id: number) => {
    await invoiceService.updateOrder({
      order_id: order_id,
      products: [
        {
          merchant_id: 2877,
          code: 2,
          price: 53000,
          cost: 0,
          created_at: '2024-09-27T10:55:34.509983',
          product_id: 88,
          name: 'Dễ thương',
          img: 'https://static.wesap.vn/abc/product/67e82afb-3d04-4e0d-8481-b02cea0c38b0.jpg',
          tax: 0,
          status: 0,
          updated_at: '2024-09-27T10:55:34.510007',
          categories: [],
          amount: 1,
        },
      ],
      merchant_id: 2877,
    })
  }

  const getQrPayment = async () => {
    const res: any = await invoiceService.getTables()
    let order_id = res.tables[0].order_id
    if (res.tables[0].is_available) {
      const order = await invoiceService.createOrder({
        customer_id: 0,
        table_id: res.tables[0].table_id,
      })
      order_id = order.data.order_id
    }
    if (!res.tables[0].total_price || res.tables[0].total_price === 0) handleAddItem(order_id)
    const invoice = await invoiceService.getInvoice(order_id)
    const qr: any = await invoiceService.getQRPayment(invoice.data.invoice_data.invoice_id)
    if (qr) return qr.QR
  }

  return { getQrPayment }
}
