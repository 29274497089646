import { useEffect } from 'react'
import { lightLogo } from '@src/constants/bank.constants'
import { IHomeBg } from '@src/constants/icons/home.icon'
import { IArrowRight } from '@src/constants/icons/reward.icon'
import { IAIChat, IDevices, IDocuments, ILogout, ISettings, IShop } from '@src/constants/icons/setting.icon'
import { MANAGEMENT_DEVICE } from '@src/constants/key.constants'
import { OPEN } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { useShopList } from '@src/hooks/use-shop-list'
import { useAuth } from '@src/providers/auth-provider'
import { useShopStore } from '@src/store/states/shop.state'
import { getThemeBank } from '@src/utils/theme-color'

import ManagementDevice from '../management-device'

const Setting = () => {
  const { logOut } = useAuth()
  const { setParams } = useQueryParams()

  const { data } = useShopList()
  const { currentShop, setCurrentShop } = useShopStore()

  useEffect(() => {
    if (!currentShop && data && data.length > 0) {
      setCurrentShop(data[0])
    }
  }, [data])

  return (
    <>
      <div className='flex h-full w-full flex-col'>
        <div className='absolute left-0 right-0 top-0 h-[138px]'>
          <span className='absolute bottom-0 left-0 right-0 [--darker-color:theme(colors.primary-darker)] [--light-color:theme(colors.primary)]'>
            <IHomeBg />
          </span>
        </div>

        <div className='relative flex flex-col items-center pt-2'>
          <img src={lightLogo[getThemeBank()]} alt='logo' className='h-[56px] w-[140px] object-contain' />

          <div className='mt-2 w-full px-4'>
            <div
              className='flex w-full items-center gap-4 rounded-2xl bg-white p-4'
              style={{
                boxShadow: '0px 4px 6px -2px #10182808, 0px 4px 16px -4px #1018280A inset',
              }}
            >
              <img
                src={'./common/starbucks.png'}
                alt=''
                className='size-[56px] overflow-hidden rounded-full object-cover transition-all'
              />
              <div className='flex-1'>
                <p className='w-full text-lg font-semibold text-strong-950'>{currentShop?.name}</p>
                <p className='w-fit rounded-md bg-primary/10 px-2 py-1 text-sm font-medium text-primary'>Admin</p>
              </div>
              <button className='size-4 text-disable-300'>
                <IArrowRight />
              </button>
            </div>
          </div>
        </div>

        <div className='relative flex-1 overflow-auto px-4 pb-10'>
          {[
            {
              title: 'Tiện ích',
              array: [
                { icon: IShop, title: 'Quản lý cửa hàng', onClick: () => {} },
                {
                  icon: IDevices,
                  title: 'Quản lý thiết bị thanh toán',
                  onClick: () => {
                    setParams({ [OPEN]: MANAGEMENT_DEVICE })
                  },
                },
                { icon: IDocuments, title: 'Hóa đơn điện tử', onClick: () => {} },
              ],
            },
            {
              title: 'Hỗ trợ và cài đặt',
              array: [
                { icon: ISettings, title: 'Cài đặt', onClick: () => {} },
                {
                  icon: IAIChat,
                  title: 'AI Chat Box',
                  onClick: () => {
                    setParams({ chatbot: 'true' })
                  },
                },
              ],
            },
          ].map((item, index) => (
            <div key={index} className='mt-6 w-full'>
              <p className='w-full pl-3 text-base font-semibold text-strong-950'>{item.title}</p>
              <div className='mt-2 w-full rounded-xl bg-white'>
                {item.array.map((i) => (
                  <div
                    key={i.title}
                    className='group relative flex cursor-pointer items-center gap-4 p-3 active:opacity-active'
                    onClick={() => i.onClick()}
                  >
                    <div className='flex size-10 items-center justify-center [--color-primary:theme(colors.primary)] [--color-secondary:theme(colors.secondary)]'>
                      {i.icon()}
                    </div>
                    <div className='flex-1 text-base font-medium'>{i.title}</div>
                    <button className='size-4 text-disable-300'>
                      <IArrowRight />
                    </button>
                    <div className='absolute bottom-0 left-[58px] right-0 h-[1px] bg-soft-150 group-last:invisible'></div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <button
            onClick={() => logOut()}
            className='mt-6 flex w-full items-center justify-center gap-3 rounded-full bg-white py-3 text-strong-950 active:opacity-active'
          >
            <span>
              <ILogout />
            </span>
            <span className='text-base font-medium'>Đăng xuất</span>
          </button>

          <p id='app-version' className='mt-6 w-full text-center text-sm font-normal text-soft-400'>
            Version {localStorage.getItem('app_version')}
          </p>
        </div>
      </div>
      <ManagementDevice />
    </>
  )
}

export default Setting
