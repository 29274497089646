const IPosMachine = () => {
  return (
    <svg className='w-full' preserveAspectRatio='xMidYMid meet' viewBox='0 0 1819 1558' fill='none'>
      <mask
        id='mask0_1127_1401'
        style={{
          maskType: 'luminance',
        }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='1819'
        height='1558'
      >
        <path
          d='M1119.66 16.9192L815.66 424.689L20.09 1051.3C7.44 1061.67 0 1077.9 0 1095.12V1171.66C0 1209.22 20.54 1243.18 52.26 1258.05L674.26 1549.74C703.66 1563.53 737.65 1558.53 762.63 1536.74L1785.82 697.879C1806.31 679.999 1818.19 652.999 1818.19 624.469L1817.71 277.599C1817.71 252.299 1809.82 244.939 1787.23 238.929L1168.08 1.56922C1150.58 -3.08078 1132.13 2.76922 1119.66 16.9192Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1127_1401)'>
        <path
          d='M1692.88 172.879L721.1 1306.94V1299.57L138.45 937.459L-24.52 1072.87V1285.24L721.1 1639.61V1642.77L1881.73 683.219V223.099L1692.88 172.879Z'
          fill='url(#paint0_linear_1127_1401)'
        />
        <path
          d='M637.3 1350L3.84992 1050C-16.9301 1040.15 -20.1501 1010 -2.05008 995.279L764.95 374.869C766.236 373.835 767.434 372.694 768.53 371.459L1144 -77.4307L1844.19 200.999L1579.89 605.399C1542.01 663.358 1495.8 715.426 1442.76 759.929L761.61 1331.4C726.8 1362.36 678.67 1369.55 637.3 1350Z'
          fill='url(#paint1_linear_1127_1401)'
        />
        <path
          d='M637.3 1350L3.84992 1050C-16.9301 1040.15 -20.1501 1010 -2.05008 995.279L764.95 374.869C766.236 373.835 767.434 372.694 768.53 371.459L1144 -77.4307L1844.19 200.999L1579.89 605.399C1542.01 663.358 1495.8 715.426 1442.76 759.929L761.61 1331.4C726.8 1362.36 678.67 1369.55 637.3 1350Z'
          fill='url(#paint2_linear_1127_1401)'
        />
        <path
          d='M637.3 1350L3.84992 1050C-16.9301 1040.15 -20.1501 1010 -2.05008 995.279L764.95 374.869C766.236 373.835 767.434 372.694 768.53 371.459L1144 -77.4307L1844.19 200.999L1579.89 605.399C1542.01 663.358 1495.8 715.426 1442.76 759.929L761.61 1331.4C726.8 1362.36 678.67 1369.55 637.3 1350Z'
          fill='url(#paint3_radial_1127_1401)'
        />
      </g>
      <path
        d='M903.07 597.231C906.49 586.231 904.47 573.561 897.22 564.611C890.31 556.081 879.79 551.501 869.69 547.221L818.37 525.491L793.07 514.781C786.42 511.971 778.07 508.701 770.75 508.691C764.291 508.75 757.903 510.043 751.93 512.501C740.47 517.101 730.64 524.911 721 532.621L678.06 566.921C671.14 572.451 664.13 578.061 658.82 585.151C653.51 592.241 649.96 601.081 650.97 609.881C651.97 618.881 657.7 626.881 664.76 632.541C671.82 638.201 680.19 642.001 688.47 645.711L748.66 672.641C762.92 679.031 778.04 685.551 793.57 683.821C810.43 681.951 824.57 670.721 837.65 659.951L880.26 624.951C888.32 618.321 896.61 611.401 901.18 602.031C901.93 600.481 902.562 598.877 903.07 597.231Z'
        fill='url(#paint4_linear_1127_1401)'
      />
      <path
        d='M744.01 713.681C743.77 707.801 743.23 701.751 740.56 696.401C736.63 688.541 727.5 685.521 720.02 682.181L690.53 669.001L631.96 642.851C624.517 639.452 616.412 637.745 608.23 637.851C597.98 638.081 586.71 640.911 578.59 647.461L498.49 712.001C485.86 722.171 491.69 739.411 493.39 753.301C494.71 764.091 506.59 768.461 515.25 772.431L545.87 786.431L604.03 813.091C620.7 820.741 642.42 820.091 657.13 807.941L698.13 774.061C707.31 766.481 716.76 759.231 725.91 751.611C731.28 747.151 737.01 742.751 740.72 736.721C745.33 729.391 744.37 722.511 744.01 713.681Z'
        fill='url(#paint5_linear_1127_1401)'
      />
      <path
        d='M1106.19 685.122C1107.41 676.482 1105.69 666.602 1104.67 659.012C1103.23 648.352 1092.27 644.152 1083.67 640.422L1055.61 628.272L993.681 601.452C985.931 598.092 977.611 595.582 969.111 595.552C958.631 595.552 946.541 598.192 938.111 605.122L860.591 669.312C848.901 678.992 854.011 696.382 855.121 709.492C855.751 716.982 862.801 722.842 869.271 725.712L970.271 770.712C985.421 777.462 1008.27 776.092 1021.55 765.232L1021.93 764.912L1098.93 699.572C1103.74 695.492 1105.48 690.002 1106.19 685.122Z'
        fill='url(#paint6_linear_1127_1401)'
      />
      <path
        d='M581.03 862.912C581.77 852.412 581.11 837.112 574.95 828.192C570.5 821.752 563.08 818.402 556.05 815.562C546.15 811.562 536.49 807.132 526.8 802.682C512.51 796.112 498.18 789.682 483.97 782.932C475.58 778.932 467.36 774.292 458.33 771.932C443.5 767.982 425.95 770.582 413.82 780.292L331.36 846.702C322.02 854.232 322.66 862.782 324.19 873.542C325.55 883.222 325.08 893.802 333.45 900.482C338.3 904.352 344.83 906.712 350.45 909.362L381.19 923.872L437.05 950.302C457.6 960.022 480.05 954.162 497.13 940.102L531.43 911.792C545.22 900.412 559.79 889.572 572.97 877.512C577.38 873.422 580.59 869.112 581.03 862.912Z'
        fill='url(#paint7_linear_1127_1401)'
      />
      <path
        d='M948.19 819.722C948.36 815.642 947.55 811.112 947.26 807.292C946.65 799.212 947.58 789.882 941.67 783.522C936.73 778.192 929.46 775.642 922.98 772.752L893.48 759.592L833.66 732.892C826.298 729.459 818.31 727.571 810.19 727.342H810.08C799.22 727.212 787.14 730.112 778.62 737.162L698.83 803.222C686.68 813.222 692.06 830.862 693.45 844.412C694.6 855.642 706.31 860.112 715.25 864.212L745.02 877.862L805.86 905.752C815.401 910.248 825.986 912.062 836.48 911.002C849.53 909.502 858.94 903.182 868.72 894.892L894.13 873.332L939.92 834.472L940.51 833.972C944.72 830.412 947.92 825.302 948.19 819.722Z'
        fill='url(#paint8_linear_1127_1401)'
      />
      <path
        d='M1314.72 776.462C1315.28 771.032 1314.64 764.952 1314.49 760.242C1314.25 752.682 1313.12 744.372 1307.35 738.892C1299.67 731.602 1288.69 729.262 1279.25 725.162L1250.25 712.622C1231.9 704.622 1213.65 696.372 1195.16 688.752C1178.93 682.052 1156.62 682.322 1142.73 694.162L1065.59 759.842C1054.3 769.442 1058.97 786.672 1059.82 799.842C1060.61 812.202 1074.16 816.902 1083.82 821.202L1176.19 862.292C1188.67 867.842 1202.19 869.472 1215.35 865.372C1229.61 861.002 1239.33 850.002 1262.19 830.002L1303.69 793.782C1311.3 787.172 1313.91 784.342 1314.72 776.462Z'
        fill='url(#paint9_linear_1127_1401)'
      />
      <path
        d='M785.19 958.001C785.67 949.081 783.77 939.761 782.95 930.881C782.95 930.691 782.95 930.501 782.88 930.311C781.05 916.371 764.33 912.061 753.65 907.151L719.49 891.421C709.39 886.771 699.32 882.061 689.23 877.421C680.16 873.221 671.02 868.141 661.47 865.141C646.02 860.281 627.15 862.611 614.47 873.141L573.94 906.691C560.33 917.941 544.77 928.481 532.37 941.071C525.74 947.811 523.96 956.441 524.66 965.601C525.46 975.921 525.66 988.601 534.49 995.601C539.31 999.471 545.71 1001.82 551.27 1004.45L581.43 1018.71L639.72 1046.27C666.88 1059.12 688.91 1047.55 695.8 1041.67L777.4 972.441L777.72 972.161L778.03 971.891C780.324 969.864 782.223 967.43 783.63 964.711C784.603 962.604 785.134 960.321 785.19 958.001Z'
        fill='url(#paint10_linear_1127_1401)'
      />
      <path
        d='M903.07 597.231C906.49 586.231 904.47 573.561 897.22 564.611C890.31 556.081 879.79 551.501 869.69 547.221L818.37 525.491L793.07 514.781C786.42 511.971 778.07 508.701 770.75 508.691C764.291 508.75 757.903 510.043 751.93 512.501C740.47 517.101 730.64 524.911 721 532.621L678.06 566.921C671.14 572.451 664.13 578.061 658.82 585.151C653.51 592.241 649.96 601.081 650.97 609.881C651.97 618.881 657.7 626.881 664.76 632.541C671.82 638.201 680.19 642.001 688.47 645.711L748.66 672.641C762.92 679.031 778.04 685.551 793.57 683.821C810.43 681.951 824.57 670.721 837.65 659.951L880.26 624.951C888.32 618.321 896.61 611.401 901.18 602.031C901.93 600.481 902.562 598.877 903.07 597.231Z'
        fill='url(#paint11_linear_1127_1401)'
      />
      <path
        d='M744.01 713.681C743.77 707.801 743.23 701.751 740.56 696.401C736.63 688.541 727.5 685.521 720.02 682.181L690.53 669.001L631.96 642.851C624.517 639.452 616.412 637.745 608.23 637.851C597.98 638.081 586.71 640.911 578.59 647.461L498.49 712.001C485.86 722.171 491.69 739.411 493.39 753.301C494.71 764.091 506.59 768.461 515.25 772.431L545.87 786.431L604.03 813.091C620.7 820.741 642.42 820.091 657.13 807.941L698.13 774.061C707.31 766.481 716.76 759.231 725.91 751.611C731.28 747.151 737.01 742.751 740.72 736.721C745.29 729.461 744.38 722.701 744.01 713.681Z'
        fill='url(#paint12_linear_1127_1401)'
      />
      <path
        d='M581.03 862.912C581.77 852.412 581.11 837.112 574.95 828.192C570.5 821.752 563.08 818.402 556.05 815.562C546.15 811.562 536.49 807.132 526.8 802.682C512.51 796.112 498.18 789.682 483.97 782.932C475.58 778.932 467.36 774.292 458.33 771.932C443.5 767.982 425.95 770.582 413.82 780.292L331.36 846.702C322.02 854.232 322.66 862.782 324.19 873.542C325.55 883.222 325.08 893.802 333.45 900.482C338.3 904.352 344.83 906.712 350.45 909.362L381.19 923.872L437.05 950.302C457.6 960.022 480.05 954.162 497.13 940.102L531.43 911.792C545.22 900.412 559.79 889.572 572.97 877.512C577.38 873.422 580.59 869.112 581.03 862.912Z'
        fill='url(#paint13_linear_1127_1401)'
      />
      <path
        d='M1106.19 685.122C1107.41 676.482 1105.69 666.602 1104.67 659.012C1103.23 648.352 1092.27 644.152 1083.67 640.422L1055.61 628.272L993.681 601.452C985.931 598.092 977.611 595.582 969.111 595.552C958.631 595.552 946.541 598.192 938.111 605.122L860.591 669.312C848.901 678.992 854.011 696.382 855.121 709.492C855.751 716.982 862.801 722.842 869.271 725.712L970.271 770.712C985.421 777.462 1008.27 776.092 1021.55 765.232L1021.93 764.912L1098.93 699.572C1103.75 695.492 1105.47 690.052 1106.19 685.122Z'
        fill='url(#paint14_linear_1127_1401)'
      />
      <path
        d='M948.19 819.722C948.36 815.642 947.55 811.112 947.26 807.292C946.65 799.212 947.58 789.882 941.67 783.522C936.73 778.192 929.46 775.642 922.98 772.752L893.48 759.592L833.66 732.892C826.298 729.459 818.31 727.571 810.19 727.342H810.08C799.22 727.212 787.14 730.112 778.62 737.162L698.83 803.222C686.68 813.222 692.06 830.862 693.45 844.412C694.6 855.642 706.31 860.112 715.25 864.212L745.02 877.862L805.86 905.752C815.401 910.248 825.986 912.062 836.48 911.002C849.53 909.502 858.94 903.182 868.72 894.892L894.13 873.332L939.92 834.472L940.51 833.972C944.72 830.412 947.92 825.302 948.19 819.722Z'
        fill='url(#paint15_linear_1127_1401)'
      />
      <path
        d='M1314.72 776.462C1315.28 771.032 1314.64 764.952 1314.49 760.242C1314.25 752.682 1313.12 744.372 1307.35 738.892C1299.67 731.602 1288.69 729.262 1279.25 725.162L1250.25 712.622C1231.9 704.622 1213.65 696.372 1195.16 688.752C1178.93 682.052 1156.62 682.322 1142.73 694.162L1065.59 759.842C1054.3 769.442 1058.97 786.672 1059.82 799.842C1060.61 812.202 1074.16 816.902 1083.82 821.202L1176.19 862.292C1188.67 867.842 1202.19 869.472 1215.35 865.372C1229.61 861.002 1239.33 850.002 1262.19 830.002L1303.69 793.782C1311.27 787.202 1313.91 784.362 1314.72 776.462Z'
        fill='url(#paint16_linear_1127_1401)'
      />
      <path
        d='M785.19 958.001C785.67 949.081 783.77 939.761 782.95 930.881C782.95 930.691 782.95 930.501 782.88 930.311C781.05 916.371 764.33 912.061 753.65 907.151L719.49 891.421C709.39 886.771 699.32 882.061 689.23 877.421C680.16 873.221 671.02 868.141 661.47 865.141C646.02 860.281 627.15 862.611 614.47 873.141L573.94 906.691C560.33 917.941 544.77 928.481 532.37 941.071C525.74 947.811 523.96 956.441 524.66 965.601C525.46 975.921 525.66 988.601 534.49 995.601C539.31 999.471 545.71 1001.82 551.27 1004.45L581.43 1018.71L639.72 1046.27C666.88 1059.12 688.91 1047.55 695.8 1041.67L777.4 972.441L777.72 972.161L778.03 971.891C780.324 969.864 782.223 967.43 783.63 964.711C784.603 962.604 785.134 960.321 785.19 958.001Z'
        fill='url(#paint17_linear_1127_1401)'
      />
      <path
        opacity='0.4'
        d='M886.9 565.861C886.645 564.224 885.903 562.702 884.77 561.491C880.86 557.171 873.1 554.962 867.77 552.652L836.45 539.072L781.29 515.142C771.07 510.712 757.63 510.801 748.64 518.051L670.64 580.892C660.38 589.162 671.87 597.162 679.43 600.502L706.5 612.502L773.03 641.911C782.78 646.221 796.81 645.501 805.27 638.551L882.96 574.762C884.335 573.749 885.421 572.395 886.112 570.835C886.803 569.274 887.075 567.56 886.9 565.861Z'
        fill='#F3F8FB'
      />
      <path
        opacity='0.4'
        d='M727.34 696.781C727.096 695.105 726.365 693.537 725.24 692.271C721.43 687.881 713.79 685.621 708.61 683.271C692.15 675.938 675.69 668.571 659.23 661.171C651.43 657.651 643.59 654.171 635.94 650.381C628.49 646.651 620.72 642.761 612.35 641.691C604.35 640.691 594.74 642.021 588.35 647.271L508.19 712.001C498.1 720.131 508.5 728.471 516.25 732.001L542.64 744.001L605.12 772.471C616.79 777.791 632.54 780.471 643.3 771.561L723.19 705.921C726.12 703.531 727.79 700.121 727.34 696.781Z'
        fill='#F3F8FB'
      />
      <path
        opacity='0.4'
        d='M561.12 826.822C557.36 822.292 549.62 819.922 544.41 817.522L513.56 803.322L457.5 777.492L456.09 776.852C446.19 772.282 432.09 773.302 423.45 780.262L340.96 846.752C327.63 857.492 346.55 866.372 355.02 870.362L388.61 886.162L443.33 911.902C453.33 916.622 467.83 915.592 476.56 908.432L558.78 840.912C563.19 837.342 564.96 831.442 561.12 826.822Z'
        fill='#F3F8FB'
      />
      <path
        opacity='0.4'
        d='M1090.37 653.891C1087.89 645.891 1070.21 640.411 1064.26 637.831L1028.83 622.471L980.51 601.531C970.79 597.321 956.76 597.911 948.35 604.871L870.78 669.151C866.53 672.671 865.12 678.411 868.97 682.761C873.3 687.651 882.5 690.261 888.46 692.901L921.38 707.461C932.24 712.261 943.04 717.211 953.9 722.031C963.57 726.341 974.14 732.651 984.74 734.031C992.63 735.031 1002.59 733.781 1008.97 728.311L1086.28 663.081C1090.25 659.741 1091.19 656.671 1090.37 653.891Z'
        fill='#F3F8FB'
      />
      <path
        opacity='0.4'
        d='M930.44 788.002C929.57 779.552 915.73 775.802 909.05 772.822L875.19 757.712L821.61 733.782L821.46 733.712C811.35 729.202 797.54 729.872 788.82 737.102L708.97 803.262C704.6 806.862 702.97 812.722 706.9 817.262C711.47 822.632 719.06 824.692 725.33 827.312C737.69 832.492 749.76 838.382 761.95 843.932C772.36 848.672 782.65 853.682 793.03 858.482C802.43 862.832 812.71 868.902 823.1 870.302C844.45 873.202 860.43 853.732 875.33 841.162L925.14 799.162C928.9 795.912 930.83 791.832 930.44 788.002Z'
        fill='#F3F8FB'
      />
      <path
        opacity='0.4'
        d='M1298.48 744.571C1296.86 739.901 1290.76 736.051 1286.07 734.011L1186.07 690.631C1176.16 686.331 1161.94 686.802 1153.44 694.032L1076.27 759.801C1075.12 760.715 1074.17 761.857 1073.48 763.157C1072.79 764.456 1072.38 765.885 1072.27 767.351C1072.05 774.131 1079.69 777.481 1085 779.821L1162.61 814.131C1172.72 818.601 1183.73 825.341 1194.89 826.521C1202.68 827.341 1212.07 825.942 1218.14 820.592L1295.05 753.811C1298.79 750.581 1299.47 747.421 1298.48 744.571Z'
        fill='#F3F8FB'
      />
      <path
        opacity='0.4'
        d='M766.84 926.001C766.524 924.104 765.775 922.306 764.651 920.745C763.527 919.184 762.059 917.903 760.36 917.001C760.276 916.959 760.189 916.923 760.1 916.891L759.8 916.791C759.716 916.768 759.636 916.734 759.56 916.691C759.5 916.691 759.46 916.611 759.46 916.561L708.63 893.131L673.63 877.011C664.63 872.871 655.35 867.321 645.27 867.011C638.15 866.771 630.1 868.461 624.52 873.151L542.35 941.201C539.42 943.631 537.35 948.201 538.28 952.281C540.38 960.681 558.28 965.941 565.56 969.361L600.35 985.721L647.99 1008.12C671.22 1019.03 688.72 998.631 704.59 985.231L757.93 940.231L763.53 935.511C764.879 934.365 765.897 932.878 766.478 931.207C767.06 929.535 767.185 927.738 766.84 926.001Z'
        fill='#F3F8FB'
      />
      <path
        d='M412.45 1000C412.507 996.76 412.383 993.518 412.08 990.291C411.43 981.291 410.99 970.601 404.31 963.661C397.63 956.721 387.63 954.171 379.15 950.141L349.59 936.141C332.15 927.861 314.8 919.381 297.27 911.291C290.282 908.138 282.673 906.603 275.01 906.801C264.27 907.021 252.73 910.081 244.25 916.901L159.41 985.261C145.59 996.411 152.17 1014.12 154.55 1028.85C155.21 1032.93 158.37 1037.52 161.55 1040.11C163.354 1041.56 165.317 1042.8 167.4 1043.8L266.7 1092.27C284.32 1100.87 306.18 1097.15 321.3 1085.18C328.09 1079.81 334.67 1074.18 341.3 1068.65L398.35 1021.59C405.08 1016 411.98 1011.81 412.45 1000Z'
        fill='url(#paint18_linear_1127_1401)'
      />
      <path
        d='M412.45 1000C412.507 996.76 412.383 993.518 412.08 990.291C411.43 981.291 410.99 970.601 404.31 963.661C397.63 956.721 387.63 954.171 379.15 950.141L349.59 936.141C332.15 927.861 314.8 919.381 297.27 911.291C290.282 908.138 282.673 906.603 275.01 906.801C264.27 907.021 252.73 910.081 244.25 916.901L159.41 985.261C145.59 996.411 152.17 1014.12 154.55 1028.85C155.21 1032.93 158.37 1037.52 161.55 1040.11C163.354 1041.56 165.317 1042.8 167.4 1043.8L266.7 1092.27C284.32 1100.87 306.18 1097.15 321.3 1085.18C328.09 1079.81 334.67 1074.18 341.3 1068.65L398.35 1021.59C405.31 1015.83 411.98 1011.77 412.45 1000Z'
        fill='url(#paint19_linear_1127_1401)'
      />
      <path
        opacity='0.4'
        d='M391.89 965.651C387.17 960.501 377.48 956.651 372.43 954.261L339.6 938.641L287.19 913.721L286.93 913.601C277.03 908.891 262.36 910.051 253.77 917.041L168.89 985.501C158.78 993.651 167.79 1002.69 175.97 1006.66L201.52 1019.06L264.25 1049.5C276.42 1055.41 293.44 1058.87 304.88 1049.35L389.58 979.841C396.19 974.381 395.54 969.631 391.89 965.651Z'
        fill='#FFFDA6'
      />
      <path
        d='M1157.35 913.101C1158.18 904.701 1156.19 895.041 1155.79 887.821C1154.93 872.041 1134.23 867.091 1122.69 861.941L1087.69 846.341C1077.96 842.001 1068.24 837.611 1058.51 833.251C1049.45 829.251 1040.33 824.251 1030.81 821.361C1015.53 816.731 996.48 818.681 984.02 829.281L904.62 896.871C892.86 906.871 897.83 924.431 898.93 937.871C899.79 948.341 909.85 953.421 918.31 957.301L944.06 969.121L1008.53 998.691C1024.53 1006.02 1042.21 1010.69 1059.13 1003.48C1074.56 996.931 1085.95 983.831 1098.33 973.021L1147.54 930.021L1149.77 928.021C1154.17 924.246 1156.9 918.881 1157.35 913.101Z'
        fill='url(#paint20_linear_1127_1401)'
      />
      <path
        d='M1157.35 913.101C1158.18 904.701 1156.19 895.041 1155.79 887.821C1154.93 872.041 1134.23 867.091 1122.69 861.941L1087.69 846.341C1077.96 842.001 1068.24 837.611 1058.51 833.251C1049.45 829.251 1040.33 824.251 1030.81 821.361C1015.53 816.731 996.48 818.681 984.02 829.281L904.62 896.871C892.86 906.871 897.83 924.431 898.93 937.871C899.79 948.341 909.85 953.421 918.31 957.301L944.06 969.121L1008.53 998.691C1024.53 1006.02 1042.21 1010.69 1059.13 1003.48C1074.56 996.931 1085.95 983.831 1098.33 973.021L1147.54 930.021L1149.77 928.021C1154.17 924.246 1156.9 918.881 1157.35 913.101Z'
        fill='url(#paint21_linear_1127_1401)'
      />
      <path
        opacity='0.4'
        d='M1140.08 881.762C1137.96 872.392 1122.94 868.442 1115.08 864.932L1079.08 848.842L1027.73 825.902C1003 814.902 983.23 839.092 966.44 853.392C955.24 862.952 944.033 872.505 932.82 882.052L916.19 896.172C909.37 902.012 910.65 911.302 918.71 915.422L918.97 915.522L919.27 915.622C919.352 915.653 919.433 915.689 919.51 915.732C919.559 915.758 919.598 915.8 919.62 915.852L1024.06 963.482C1034.2 968.102 1049.01 967.482 1057.71 959.972L1136.85 891.212C1138.23 890.105 1139.26 888.628 1139.84 886.955C1140.41 885.282 1140.49 883.481 1140.08 881.762Z'
        fill='#F99C73'
      />
      <path
        d='M991.58 1054.89C991.86 1051.45 990.93 1033.07 990.03 1027.47C988.51 1018.04 980.65 1011.65 972.46 1007.82C970.46 1006.82 924.21 985.599 903.15 975.899C881.43 965.899 859.15 952.479 834.46 961.469C820.19 966.679 809.19 978.129 797.87 987.779C778.44 1004.32 713.1 1063.2 696.6 1069.86C688.611 1073.03 680.21 1075.05 671.65 1075.86C653.82 1077.62 638.37 1072.92 622.43 1065.62C587.43 1049.62 553.43 1031.41 518.43 1015.52C500.52 1007.36 481.35 998.919 461.43 1004.99C443.94 1010.32 431.48 1024.46 417.83 1035.76L365.83 1078.76C357.83 1085.36 354.34 1093.76 355.83 1103.99L358.77 1124.82C360.14 1134.46 366.55 1140.92 375.02 1145.05L676.08 1292.05C704.14 1305.75 732.87 1291.33 736.37 1288.42C740.99 1284.57 976.37 1079.88 982.93 1073.8C989.49 1067.72 991.01 1062 991.58 1054.89Z'
        fill='url(#paint22_linear_1127_1401)'
      />
      <path
        d='M991.58 1054.89C991.86 1051.45 990.93 1033.07 990.03 1027.47C988.51 1018.04 980.65 1011.65 972.46 1007.82C970.46 1006.82 924.21 985.599 903.15 975.899C881.43 965.899 859.15 952.479 834.46 961.469C820.19 966.679 809.19 978.129 797.87 987.779C778.44 1004.32 713.1 1063.2 696.6 1069.86C688.611 1073.03 680.21 1075.05 671.65 1075.86C653.82 1077.62 638.37 1072.92 622.43 1065.62C587.43 1049.62 553.43 1031.41 518.43 1015.52C500.52 1007.36 481.35 998.919 461.43 1004.99C443.94 1010.32 431.48 1024.46 417.83 1035.76L365.83 1078.76C357.83 1085.36 354.34 1093.76 355.83 1103.99L358.77 1124.82C360.14 1134.46 366.55 1140.92 375.02 1145.05L676.08 1292.05C704.14 1305.75 732.87 1291.33 736.37 1288.42C740.99 1284.57 976.37 1079.88 982.93 1073.8C989.49 1067.72 991.01 1062 991.58 1054.89Z'
        fill='url(#paint23_linear_1127_1401)'
      />
      <path
        opacity='0.4'
        d='M974.65 1023.34C972.74 1013.29 961.34 1009.63 953.18 1005.87C940.78 1000.16 885.7 974.768 870.55 967.798C857.15 961.618 838.02 962.848 826.55 972.598C811.55 985.378 716.48 1068.98 698.32 1077.1C657.66 1095.28 616.12 1070.34 580.24 1052.79C560.98 1043.37 541.54 1034.34 522.12 1025.26C503.12 1016.39 484.12 1003.66 462.49 1010.85C458.19 1012.19 454.19 1014.36 450.71 1017.22L375.81 1079.22C374.016 1080.65 372.568 1082.46 371.572 1084.52C370.577 1086.59 370.06 1088.85 370.06 1091.14C370.06 1093.44 370.577 1095.7 371.572 1097.76C372.568 1099.83 374.016 1101.64 375.81 1103.07C380.57 1106.92 664.45 1243.66 681.81 1252.07C695.57 1258.75 715.44 1257.33 727.23 1247.07L969.47 1036.62C974.01 1032.7 975.51 1027.87 974.65 1023.34Z'
        fill='#B5E69F'
      />
      <path
        d='M1665 299.38C1664.5 293.23 1663.3 287.157 1661.42 281.28C1658.42 271.28 1654.73 261.02 1646.04 254.61C1635.96 247.17 1265.48 103.24 1236.13 91.6097C1203.42 78.6797 1159.52 53.0697 1131.75 90.7697C1121.56 104.6 945.349 343.33 935.749 356.77C927.749 367.94 920.549 380.83 926.369 394.61C926.499 394.93 934.109 410.44 937.669 417.61C945.129 432.76 968.759 438.23 982.999 444.44C1008.93 455.75 1385 619.98 1400.05 626.27C1420.82 634.95 1449.45 627.6 1462.6 608.42L1659.6 320.88C1664.19 314.26 1665.44 306.87 1665 299.38Z'
        fill='url(#paint24_linear_1127_1401)'
      />
      <path
        d='M1664.85 299.17C1664.36 293.022 1663.15 286.952 1661.27 281.079C1658.27 271.079 1654.58 260.81 1645.9 254.4C1635.81 246.96 1265.33 103.03 1235.98 91.3995C1203.27 78.4795 1159.38 52.8595 1131.6 90.5695C1121.42 104.4 945.199 343.12 935.599 356.57C927.599 367.74 920.389 380.63 926.209 394.41C926.349 394.74 933.959 410.24 937.509 417.46C944.969 432.6 968.599 438.08 982.839 444.29C1008.77 455.59 1384.84 619.819 1399.89 626.109C1420.67 634.789 1449.29 627.44 1462.44 608.27C1462.44 608.27 1659.15 321.17 1659.44 320.72C1664.01 314.05 1665.29 306.66 1664.85 299.17Z'
        fill='url(#paint25_linear_1127_1401)'
      />
      <path
        d='M1638.49 270.599C1637.73 267.868 1636.35 265.35 1634.45 263.244C1632.56 261.137 1630.2 259.499 1627.56 258.459C1616.76 254.179 1190.21 84.7493 1177.28 80.0493C1165.21 75.6593 1150.61 80.1893 1142.85 90.7393L942.8 362.639C935.87 372.059 937.04 386.089 948.57 391.079L1362.11 569.939C1383.95 579.389 1417.9 603.399 1437.24 575.339L1635.74 287.099C1637.39 284.721 1638.48 282.009 1638.96 279.157C1639.44 276.304 1639.27 273.382 1638.49 270.599Z'
        fill='#00C7FF'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1127_1401'
          x1='-105.36'
          y1='907.829'
          x2='1888.91'
          y2='907.829'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00BAEE' />
          <stop offset='0.4' stopColor='#17205B' />
          <stop offset='0.97' stopColor='#232A75' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1127_1401'
          x1='564.89'
          y1='1357.59'
          x2='1029.19'
          y2='362.659'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03' stopColor='#232A75' />
          <stop offset='0.48' stopColor='#17205B' />
          <stop offset='0.97' stopColor='#00BAEE' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1127_1401'
          x1='598.96'
          y1='1306.98'
          x2='1246.34'
          y2='-125.891'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.52' stopColor='#232A75' />
          <stop offset='0.62' stopColor='#00BAEE' />
          <stop offset='0.76' stopColor='#00BAEE' />
          <stop offset='0.93' stopColor='#232A75' />
          <stop offset='0.94' stopColor='#17205B' />
        </linearGradient>
        <radialGradient
          id='paint3_radial_1127_1401'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-1226.5 -1468.87) scale(3736.65 3736.65)'
        >
          <stop offset='0.766667' stopColor='#00BAEE' />
          <stop offset='1' stopColor='#4548A9' />
        </radialGradient>
        <linearGradient
          id='paint4_linear_1127_1401'
          x1='724.23'
          y1='531.591'
          x2='837.1'
          y2='666.111'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1127_1401'
          x1='560.95'
          y1='659.621'
          x2='674.48'
          y2='794.931'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_1127_1401'
          x1='923.161'
          y1='616.812'
          x2='1036.88'
          y2='752.342'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_1127_1401'
          x1='394.64'
          y1='793.562'
          x2='509.81'
          y2='930.822'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_1127_1401'
          x1='762.16'
          y1='750.082'
          x2='877.49'
          y2='887.522'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_1127_1401'
          x1='1128.56'
          y1='706.092'
          x2='1245.37'
          y2='845.302'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_1127_1401'
          x1='596.18'
          y1='886.551'
          x2='713.68'
          y2='1026.59'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#BED8D9' />
          <stop offset='0.1' stopColor='#D4E7E9' />
          <stop offset='0.17' stopColor='#DFEEF0' />
          <stop offset='0.81' stopColor='#DDEDEF' />
          <stop offset='0.85' stopColor='#D6E7E9' />
          <stop offset='0.92' stopColor='#C2D5D7' />
          <stop offset='1' stopColor='#A6BBBE' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_1127_1401'
          x1='816.03'
          y1='513.321'
          x2='741.37'
          y2='677.001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_1127_1401'
          x1='652.93'
          y1='650.691'
          x2='581.67'
          y2='803.511'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_1127_1401'
          x1='488.59'
          y1='783.902'
          x2='415.64'
          y2='940.342'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_1127_1401'
          x1='1015.19'
          y1='608.342'
          x2='944.171'
          y2='760.652'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_1127_1401'
          x1='855.85'
          y1='741.262'
          x2='783.62'
          y2='896.142'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_1127_1401'
          x1='1222.87'
          y1='697.592'
          x2='1150.63'
          y2='852.522'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_1127_1401'
          x1='692.08'
          y1='877.921'
          x2='618.28'
          y2='1036.19'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A6BBBE' />
          <stop offset='0.36' stopColor='#B3CBCC' />
          <stop offset='0.77' stopColor='#BED8D9' />
          <stop offset='0.96' stopColor='#BED8D9' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_1127_1401'
          x1='223.58'
          y1='931.361'
          x2='340.27'
          y2='1070.43'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2C33F' />
          <stop offset='0.05' stopColor='#F3CE54' />
          <stop offset='0.13' stopColor='#F4DD70' />
          <stop offset='0.17' stopColor='#F5E37B' />
          <stop offset='0.34' stopColor='#F5E277' />
          <stop offset='0.52' stopColor='#F5DD69' />
          <stop offset='0.71' stopColor='#F6D653' />
          <stop offset='0.81' stopColor='#F6D246' />
          <stop offset='0.87' stopColor='#F5CB40' />
          <stop offset='0.96' stopColor='#F1B730' />
          <stop offset='1' stopColor='#EFAB26' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_1127_1401'
          x1='318.94'
          y1='921.331'
          x2='244.15'
          y2='1081.72'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EFAB26' />
          <stop offset='0.77' stopColor='#EFAB26' />
          <stop offset='1' stopColor='#EFAB26' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_1127_1401'
          x1='968.49'
          y1='842.341'
          x2='1087.19'
          y2='983.801'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F0592A' />
          <stop offset='0.1' stopColor='#F76E38' />
          <stop offset='0.17' stopColor='#FB793F' />
          <stop offset='0.81' stopColor='#FB793F' />
          <stop offset='0.85' stopColor='#F67239' />
          <stop offset='0.9' stopColor='#E85E28' />
          <stop offset='0.97' stopColor='#D23E0D' />
          <stop offset='1' stopColor='#C72F00' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_1127_1401'
          x1='1064.02'
          y1='833.731'
          x2='990.65'
          y2='991.061'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C72F00' />
          <stop offset='0.47' stopColor='#E34B1C' />
          <stop offset='0.77' stopColor='#F0592A' />
          <stop offset='0.96' stopColor='#F0592A' />
        </linearGradient>
        <linearGradient
          id='paint22_linear_1127_1401'
          x1='547.95'
          y1='926.049'
          x2='804.26'
          y2='1231.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#48A153' />
          <stop offset='0.02' stopColor='#53AB5A' />
          <stop offset='0.07' stopColor='#5EB761' />
          <stop offset='0.13' stopColor='#65BD66' />
          <stop offset='0.31' stopColor='#67BF67' />
          <stop offset='0.89' stopColor='#67BF67' />
          <stop offset='0.94' stopColor='#50A65B' />
          <stop offset='1' stopColor='#31854C' />
        </linearGradient>
        <linearGradient
          id='paint23_linear_1127_1401'
          x1='753.48'
          y1='905.699'
          x2='591.64'
          y2='1252.75'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#31854C' />
          <stop offset='0.05' stopColor='#378C4E' />
          <stop offset='0.19' stopColor='#419851' />
          <stop offset='0.38' stopColor='#469F53' />
          <stop offset='0.9' stopColor='#48A153' />
          <stop offset='1' stopColor='#31854C' />
        </linearGradient>
        <linearGradient
          id='paint24_linear_1127_1401'
          x1='1054.36'
          y1='184.289'
          x2='1568.24'
          y2='526.698'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#232A75' />
          <stop offset='0.83' stopColor='#00BAEE' />
          <stop offset='0.85' stopColor='#00BAEE' />
          <stop offset='0.93' stopColor='#17205B' />
        </linearGradient>
        <linearGradient
          id='paint25_linear_1127_1401'
          x1='1385.93'
          y1='144.626'
          x2='1190.29'
          y2='595.092'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#232A75' />
          <stop offset='0.95' stopColor='#17205B' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IPosMachine
