import { IArrowBack } from '@src/constants/icons/header.icon'
import IconWrapper from '@src/constants/icons/icon-wrapper'

export const LayoutHeader = ({
  title,
  onBack,
  rightItem,
}: {
  title?: string
  onBack?: () => void
  rightItem?: JSX.Element
}) => {
  return (
    <div className='sticky top-0 z-10 border-b border-soft-200 bg-white px-[10px] py-[12px]'>
      <div className='relative flex items-center justify-between'>
        <div className='flex min-h-10 min-w-10 items-center justify-center'>
          {onBack && (
            <IconWrapper
              size={40}
              rounded={'full'}
              onClick={onBack}
              className='cursor-pointer border-none text-strong-950'
            >
              <IArrowBack />
            </IconWrapper>
          )}
        </div>
        <p className='w-full flex-1 truncate break-all text-center text-[18px] font-semibold leading-[21.78px] tracking-[-0.36px] text-strong-950'>
          {title}
        </p>
        <div className='flex min-h-10 min-w-10 items-center justify-center'>{rightItem}</div>
      </div>
    </div>
  )
}
