import React from 'react'
import { Outlet } from 'react-router-dom'
import { getShowIntro } from '@src/utils/local-storage'

import PageIntro from './page-intro/page-intro'

const AuthLayout = () => {
  return (
    <React.Suspense>
      <PageIntro isChangeThemeAtTheEnd control={getShowIntro()}>
        <Outlet />
      </PageIntro>
    </React.Suspense>
  )
}

export default AuthLayout
