export type Exceptions =
  | 'AbortError'
  | 'InvalidStateError'
  | 'NotAllowedError'
  | 'NotFoundError'
  | 'NotReadableError'
  | 'OverconstrainedError'
  | 'SecurityError'
  | 'TypeError'

export const EXCEPTIONS: Record<Exceptions, string> = {
  ['AbortError']: 'AbortError: The operation was aborted, typically due to user action.', // Lỗi hủy bỏ: Hoạt động đã bị hủy, thường do hành động của người dùng.
  ['InvalidStateError']: 'InvalidStateError: The object is in an invalid state for the requested operation.', // Trạng thái không hợp lệ: Đối tượng đang ở trạng thái không hợp lệ cho hoạt động yêu cầu.
  ['NotAllowedError']: 'NotAllowedError: The operation is not allowed, often due to security settings.', // Không được phép: Hoạt động không được phép, thường do cài đặt bảo mật.
  ['NotFoundError']: 'NotFoundError: The requested resource could not be found.', // Không tìm thấy: Tài nguyên yêu cầu không thể được tìm thấy.
  ['NotReadableError']: 'NotReadableError: The resource is not readable, possibly due to permissions.', // Không thể đọc: Tài nguyên không thể đọc, có thể do quyền truy cập.
  ['OverconstrainedError']: 'OverconstrainedError: The operation could not be completed due to constraints.', // Quá nhiều ràng buộc: Hoạt động không thể hoàn thành do các ràng buộc.
  ['SecurityError']: 'SecurityError: A security error occurred, preventing the operation.', // Lỗi bảo mật: Một lỗi bảo mật đã xảy ra, ngăn cản hoạt động.
  ['TypeError']: 'TypeError: An operation was performed on a value of the wrong type.', // Lỗi kiểu: Một hoạt động đã được thực hiện trên một giá trị có kiểu sai.
}

export const SupportedBarcodeFormats: Record<BarcodeFormat, string> = {
  aztec: 'Aztec',
  code_128: 'Code 128',
  code_39: 'Code 39',
  code_93: 'Code 93',
  data_matrix: 'Data Matrix',
  ean_13: 'EAN-13',
  ean_8: 'EAN-8',
  itf: 'ITF',
  pdf417: 'PDF417',
  qr_code: 'QR Code',
  upc_e: 'UPC-E',
  upc_a: 'UPC-A',
  unknown: 'Unknown',
  codabar: 'Codabar',
}
