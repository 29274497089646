import { logo } from '@src/constants/bank.constants'
import { getThemeBank } from '@src/utils/theme-color'

import SignInForm from './sign-in-form'

const SignIn = () => {
  return (
    <>
      <div className='h-full overflow-auto bg-white px-4 py-4'>
        <div className='flex flex-col gap-16'>
          <div className='flex flex-col items-center justify-center gap-3'>
            <div className='h-[64px] w-full'>
              <img src={logo[getThemeBank()]} alt='logo' className='size-full object-contain' />
            </div>
            <p className='px-6 text-center text-base font-medium'>
              Quản lý giao dịch của bạn dễ dàng chỉ với một lần đăng nhập.
            </p>
          </div>
          <SignInForm />
        </div>
        <p className='pt-4 text-sm tracking-[-1.5%] text-[#99A0AE]'>
          Bằng cách đăng nhập, bạn chấp nhận{' '}
          <a href='#' className='font-medium tracking-[-1%] text-primary'>
            Điều khoản sử dụng, xác nhận Tuyên bố về quyền riêng tư và Chính sách cookie.
          </a>
        </p>
      </div>
    </>
  )
}

export default SignIn
