import { TDevice } from '@src/types/device'
import superjson from 'superjson'
import { create } from 'zustand'
import { persist, PersistStorage } from 'zustand/middleware'

const storage: PersistStorage<ConnectDeviceStoreState> = {
  getItem: (name) => {
    const str = localStorage.getItem(name)
    if (!str) return null
    return superjson.parse(str)
  },
  setItem: (name, value) => {
    localStorage.setItem(name, superjson.stringify(value))
  },
  removeItem: (name) => localStorage.removeItem(name),
}

type ConnectDeviceStoreState = {
  connectDevice: Map<string, TDevice>
}

type ConnectDeviceStoreActons = {
  setConnectDevice: (nextPosition: TDevice) => void
}

type ConnectDeviceStore = ConnectDeviceStoreState & ConnectDeviceStoreActons

const useConnectDeviceStore = create<ConnectDeviceStore>()(
  persist(
    (set) => ({
      connectDevice: new Map(),
      setConnectDevice: (data) =>
        set((state) => ({
          connectDevice: state.connectDevice.set(data.pos_code, data),
        })),
    }),
    { name: 'connect-device', storage }
  )
)

export default useConnectDeviceStore
