import { Dispatch, SetStateAction } from 'react'
import { SearchIcon } from '@src/assets/icons/common'

type Props = {
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
}

export default function SearchBar({ inputValue, setInputValue }: Props) {
  return (
    <div className='flex w-full items-center gap-3 rounded-full border border-soft-200 p-3'>
      <SearchIcon />

      <input
        type='text'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder='Tìm điểm bán'
        className='w-full bg-transparent text-sm font-normal leading-5 text-strong-950 outline-none placeholder:text-soft-400'
      />
    </div>
  )
}
