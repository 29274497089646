import React, { createContext, ReactNode, useState } from 'react'
import { shopService } from '@src/store/services/shop.service'
import { TShop } from '@src/types/shop'
import { useQuery } from '@tanstack/react-query'

import { useAuth } from './auth-provider'

// Define types
interface Shop {
  source_id: number
  name: string
  vat: string | null
  email: string | null
  industry_code: string | null
  parent_id: number
  create_date: string
  id: number
  address: string
  phone: string | null
  industry_name: string
  va_account_no: string | null
}

interface ShopListResponse {
  data: Shop[]
  page: number
  currentPage: number
  page_size: number
  total_page: number
}

export interface ShopListContextType {
  data: TShop[]
  page: number
  total_page: number
  page_size: number
  isLoading: boolean
  error: Error | null
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
}

// Create context
export const ShopListContext = createContext<ShopListContextType | undefined>(undefined)

// Create provider component
export const ShopListProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { token } = useAuth()

  const fetchShopList = async (): Promise<ShopListResponse> => {
    const response = await shopService.get_shop_list({ page, page_size: pageSize })
    return response.data
  }

  const { data, isLoading, error, refetch } = useQuery<ShopListResponse, Error>({
    queryKey: ['shopList', page, pageSize],
    queryFn: fetchShopList,
    enabled: !!token,
  })

  const value: ShopListContextType = {
    data: data?.data || [],
    total_page: data?.total_page || 0,
    page: page,
    page_size: pageSize,
    isLoading,
    error: error || null,
    setPage: (newPage: number) => setPage(newPage),
    setPageSize: (newPageSize: number) => setPageSize(newPageSize),
  }

  return <ShopListContext.Provider value={value}>{children}</ShopListContext.Provider>
}
