/* eslint-disable no-undef */
import { openToast } from '../src/components/toaster/custom-toast';
import packageInfo from '../package.json';
import { detectIncognito } from "detectincognitojs";

(function () {
  let updated = false;
  let activated = false;
  let importantUpdate = false;
  let isPrivate = false

  const currentVersion = packageInfo.version;

  const storedVersion = localStorage.getItem('app_version');

  if(!storedVersion){
    localStorage.setItem('app_version', currentVersion);
  } else if (storedVersion !== currentVersion) {
    importantUpdate = true;
    checkUpdate();
    localStorage.setItem('app_version', currentVersion); 
  }

  navigator.serviceWorker.register('inject-sw.js').then(registration => {
    registration.addEventListener("updatefound", () => {
      const worker = registration.installing;
      worker.addEventListener('statechange', () => {
        if (worker.state === "activated") {
          activated = true;
          checkUpdate();
        }
      });
    });
  });

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    updated = true;
    checkUpdate();
  });

  detectIncognito().then((result) => {
    isPrivate = result.isPrivate
  })

  function checkUpdate() {
    if (activated && updated && importantUpdate && !isPrivate) {
      openToast({
        message: currentVersion,
        type: 'update',
      })
        importantUpdate = false
        updated = false;
        activated = false;
      }   
  }
})();
