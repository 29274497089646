export default function ChatbotSkeleton() {
  return (
    <div className='flex size-full flex-col bg-white'>
      <div className='flex h-[72px] w-full flex-initial animate-pulse items-center justify-between bg-soft-400/35 p-4'>
        <div className='flex items-center gap-2'>
          <div className='size-10 animate-pulse rounded-full bg-soft-150'></div>
          <div className='h-6 w-40 animate-pulse rounded-md bg-soft-150'></div>
        </div>
        <div className='size-6 animate-pulse rounded-full bg-soft-150'></div>
      </div>
      <div className='flex h-full flex-[0_1_auto] flex-col'>
        <div className='flex h-full flex-[0_1_auto] animate-pulse flex-col gap-4 bg-white px-4 py-8'>
          <div className='flex items-start gap-2'>
            <div className='size-6 rounded-full bg-soft-400/30'></div>
            <div className='h-16 w-72 rounded-xl bg-soft-400/30'></div>
          </div>
        </div>
        <div className='flex h-[70px] items-center gap-4 bg-white p-4'>
          <div className='size-8 animate-pulse rounded-full bg-soft-400/30'></div>
          <div className='h-6 flex-1 animate-pulse rounded bg-soft-200'> </div>
          <div className='flex gap-2'>
            <div className='size-8 animate-pulse rounded-full bg-soft-200'></div>
            <div className='size-8 animate-pulse rounded-full bg-soft-400/50'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
