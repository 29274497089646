import React from 'react'
import toast, { ToastBar, Toaster } from 'react-hot-toast'
import { IWarning } from '@src/constants/icons/toast.icon'
import { wait } from '@src/utils/common'

import ToastUpdateVersion from './toast-update-version'

const CustomToast = () => {
  return (
    <Toaster
      gutter={16}
      position='top-center'
      containerClassName=''
      toastOptions={{
        duration: 3000,
        className: '!w-fit !p-0 max-sm:!max-w-[100vw] !max-w-limitWidth !overflow-hidden !rounded-[8px]',
        iconTheme: {
          primary: 'white',
          secondary: '#111111',
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {
            let color = ''
            let customIcon: () => JSX.Element | undefined = () => undefined
            switch (t.type) {
              case 'error':
                color += 'bg-error-base text-white'
                customIcon = IWarning
                break
              default:
                break
            }

            return (
              <div
                className={`!flex !flex-1 !items-center gap-2 !p-[6px] !pr-3 !text-sm !font-normal !leading-5 [&>div]:!m-0 ${color}`}
                style={{
                  boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
                }}
              >
                {icon && <span>{customIcon()}</span>}
                {message}
                {/* {t.type !== 'loading' && (
                        <button
                          onClick={() => toast.dismiss(t.id)}
                          className='!pl-4 !text-gray hover:!text-main-background'
                        >
                          x
                        </button>
                      )} */}
              </div>
            )
          }}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default CustomToast

export const openToast = ({
  message,
  type,
  duration,
}: {
  message: string | React.ReactNode
  type?: 'error' | 'success' | 'update'
  duration?: number
}) => {
  if (!type) {
    return toast(<span className=''>{message}</span>, {
      duration: duration ? duration : 3000,
    })
  } else if (type === 'error') {
    return toast.error(<span className=''>{message}</span>, {
      duration: duration ? duration : 3000,
    })
  } else if (type == 'success') {
    return toast.success(<div className=''>{message}</div>, {
      duration: duration ? duration : 3000,
    })
  } else if (type == 'update') {
    return toast.custom(
      <div className='sm:max-w-limitWidth relative h-[calc(100vh-2rem)] w-full sm:w-limitWidth'>
        <ToastUpdateVersion message={message} />
      </div>,
      {
        duration: duration ? duration : 3000000,
      }
    )
  }
}

export const dismissToast = (toastId?: string) => {
  return toastId ? toast.dismiss(toastId) : toast.dismiss()
}

export const editToast = ({ toastId, message }: { toastId: string; message: string | React.ReactNode }) => {
  return toast(<div className='w-full'>{message}</div>, { id: toastId })
}

export const openPromiseToast = async ({
  loadingMessage,
  undoingMessage,
  timeToUndo,
  promise,
  undoFunc,
  successMessage,
  errorMessage,
}: {
  loadingMessage?: string
  timeToUndo?: number
  promise: () => Promise<any>
  undoFunc?: () => void
  successMessage?: string
  undoingMessage?: string
  errorMessage?: string
}) => {
  let isUndo = false
  let message = loadingMessage
  async function setMessage(value: string) {
    message = value
  }
  const toastId = toast(
    (t) => (
      <div className='flex w-full items-center gap-2 pr-1'>
        <div className='flex-1 transition-all'>{message}</div>
        {!isUndo && (
          <button
            onClick={async () => {
              isUndo = true
              await setMessage(undoingMessage || 'Undo your action')
              setTimeout(() => {
                toast.dismiss(t.id)
                undoFunc && undoFunc()
              }, 700)
            }}
            className='bg-sky-blue-900 hover:bg-sky-blue-600 rounded px-2 py-[2px] text-sm text-main-background transition-all'
          >
            Undo
          </button>
        )}
      </div>
    ),
    {
      duration: 10000000,
    }
  )

  timeToUndo && (await wait(timeToUndo))

  if (!isUndo) {
    isUndo = true
    await promise()
      .then(() => {
        toast.dismiss(toastId)
        openToast({ message: successMessage || 'Success', type: 'success', duration: 1000 })
      })
      .catch((error) => {
        openToast({ message: errorMessage || error.message, type: 'error', duration: 1000 })
      })
  }
}
