import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { IEditUnderscore } from '@src/constants/icons/device.icons'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import { DEVICE_DETAIL, MANAGEMENT_DEVICE, OPEN } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { TDevice } from '@src/types/device'

import { LayoutHeader } from '../common/layout-header'
import ModalDirection from '../modal/modal-direction'

import useConnectDeviceStore from './connect-device/connect-device.store'
import QuickActionsWrapper from './quick-action'

const TitleAndContent = ({ title, content }: { title?: string; content?: string | number }) => {
  return (
    <div className='flex flex-col space-x-1'>
      <p className='text-sm leading-5 tracking-[-0.21px] text-sub-600'>{title}</p>
      <p className='text-sm font-semibold leading-5 tracking-[-0.14px] text-strong-950'>{content}</p>
    </div>
  )
}

const DeviceDetail = () => {
  const navigate = useNavigate()
  const { getParams, setParams } = useQueryParams()
  const open = getParams(OPEN) === DEVICE_DETAIL
  const { connectDevice } = useConnectDeviceStore()

  const device: TDevice | undefined = useMemo(() => {
    const id = getParams('id') ? Number(getParams('id')) : undefined
    if (!id) return undefined
    const data = Array.from(connectDevice.values()).find((item) => item.id === id)
    if (data) {
      return data
    } else {
      return undefined
    }
  }, [getParams('id'), connectDevice])
  console.log(device)

  const onClose = () => {
    try {
      navigate(-1)
    } catch (_) {
      setParams({ [OPEN]: MANAGEMENT_DEVICE })
    }
  }

  return (
    <ModalDirection
      isOpen={open}
      onClose={onClose}
      footer={<QuickActionsWrapper />}
      header={<LayoutHeader onBack={onClose} title={'Chi tiết thiết bị'} />}
    >
      <div className='flex flex-col space-y-2 p-4'>
        <div className='my-auto flex flex-row items-center justify-between p-2'>
          <div className='flex flex-col space-y-2'>
            <p className='text-lg font-bold leading-normal tracking-[-0.18px] text-neutral-200'>
              {device?.device_name ?? 'Trống'}
            </p>
            <button
              className={`mt-2 flex h-6 w-fit items-center rounded-lg px-3 ${device?.status === 'active' ? 'bg-primary' : device?.status === 'activation' ? 'bg-secondary' : 'bg-faded-base'}`}
            >
              <span className='text-xs font-semibold leading-4 tracking-[-0.12px] text-white'>
                {device?.status === 'active' ? 'Hoạt động' : device?.status === 'activation' ? 'Đợi kích hoạt' : 'Khóa'}
              </span>
            </button>
          </div>
          <IconWrapper size={44} rounded='full' className='cursor-pointer border-none bg-weak-50'>
            <IEditUnderscore />
          </IconWrapper>
        </div>
        <div className='flex flex-col space-y-4 rounded-xl border border-soft-200 p-4'>
          {/* Device Information */}
          <div className='flex flex-col space-y-3'>
            <TitleAndContent title={'Mã thiết bị'} content={device?.pos_code} />
            <TitleAndContent title={'Tài khoản đăng ký'} content={device?.account ?? 'Trống'} />
            <TitleAndContent title={'Thời gian cập nhật gần nhất'} content={device?.update_time ?? 'Trống'} />
          </div>

          {/* A incontinuous line */}
          <div className='border-t border-dashed border-sub-300' />

          {/* Sales Information */}
          <div className='flex flex-col space-y-3'>
            <TitleAndContent title={'Tên điểm bán'} content={device?.store_name ?? 'Trống'} />
            <TitleAndContent title={'Tên chi nhánh'} content={device?.branch_name ?? 'Trống'} />
            <TitleAndContent title={'Đại lý'} content={device?.agency_name ?? 'Trống'} />
          </div>
        </div>
      </div>
    </ModalDirection>
  )
}

export default DeviceDetail
