import { useEffect, useRef } from 'react'
import { getThemeBank } from '@src/utils/theme-color'
import { motion, useAnimationControls } from 'framer-motion'

import IPosMachine from './pos-machine.icon'

const AnimatedPosMachine = ({ step }: { step: number }) => {
  const pos = useAnimationControls()
  const ref = useRef<HTMLDivElement | null>(null)
  const waves = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    async function play() {
      await pos.start({
        width: '80%',
        transition: {
          duration: 0.3,
          delay: 0.2,
          ease: 'easeInOut',
          type: 'spring',
          stiffness: 200,
          damping: 20,
        },
      })
      await pos.start({ left: '-5%', top: '115%', width: '280%', transition: { duration: 0.3 } })
      if (waves.current) {
        waves.current.classList.remove('hidden')
      }
    }

    play()
  }, [])

  return (
    <>
      <div
        className='relative w-[calc(100%+2rem)] -translate-x-4 overflow-hidden shadow-inner'
        style={{
          background: `linear-gradient(180deg,${getThemeBank() == 'bvb' ? 'rgba(var(--secondary),1)' : 'rgba(var(--primary),1)'} 50%, rgba(var(--primary),1) 300%)`,
        }}
      >
        <div ref={ref} className='relative mx-auto my-1 aspect-square w-[25%] rounded-full bg-white/10 transition-all'>
          <div
            ref={waves}
            className='absolute left-[55%] top-[55%] hidden aspect-square -translate-x-1/2 -translate-y-1/2 animate-[fadeIn_0.8s_ease-in-out_infinite] rounded-full bg-white'
          ></div>

          <div className='absolute inset-[9%] rounded-full bg-white/15'></div>

          <motion.div
            initial={{ width: 0, left: '50%', top: '50%' }}
            animate={pos}
            className='absolute -translate-x-1/2 -translate-y-1/2 drop-shadow-xl'
          >
            <div className='relative'>
              <IPosMachine />
            </div>
            <div
              className='absolute left-[60%] top-[7%] w-[23%] -translate-x-1/2 -translate-y-1/2 animate-pulse p-1 font-semibold text-white'
              style={{
                transform: 'rotate3d(0.35, -0.4, 0.4, 45deg)',
              }}
            >
              <svg
                className='w-full'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                {step === 1 ? (
                  <>
                    <path d='M3 5v14' />
                    <path d='M8 5v14' />
                    <path d='M12 5v14' />
                    <path d='M17 5v14' />
                    <path d='M21 5v14' />
                  </>
                ) : (
                  <>
                    <path d='M12 20h.01' />
                    <path d='M2 8.82a15 15 0 0 1 20 0' />
                    <path d='M5 12.859a10 10 0 0 1 14 0' />
                    <path d='M8.5 16.429a5 5 0 0 1 7 0' />
                  </>
                )}
              </svg>
            </div>
          </motion.div>
        </div>
        <div
          className='relative h-3 w-full bg-white'
          style={{
            clipPath: 'polygon(0% 0%, 47% 0%, 50% 80%, 53% 0%, 100% 0%, 100% 100%, 0% 100%)',
          }}
        ></div>
        <div className='absolute bottom-0 left-0 right-0 h-[2px] translate-y-1/2 bg-white'></div>
      </div>
    </>
  )
}

export default AnimatedPosMachine
