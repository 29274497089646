import { useEffect, useMemo } from 'react'
import { ModalProps, ModalTransition } from '@src/constants/modal/modal'
import { getActivePageColor } from '@src/constants/navbar/navbar.constant'
import { setThemeColor } from '@src/utils/theme-color'
import { motion } from 'framer-motion'

import ModalWrapper from './modal-wrapper'

interface ModalDirectionProps extends ModalProps {
  direction?: 'left' | 'right' | 'bottom'
}

const ModalDirection = (props: ModalDirectionProps) => {
  const {
    direction = 'right',
    isOpen,
    onClose,
    children,
    className,
    classNameIosPb,
    header,
    footer,
    disableChangeColorTheme,
  } = props

  useEffect(() => {
    if (disableChangeColorTheme) {
      return
    }
    const page = getActivePageColor()
    if (isOpen) {
      setThemeColor('#ffffff')
    } else {
      setTimeout(() => {
        setThemeColor(page.themeColor)
      }, 50)
    }
  }, [isOpen])

  const variant = useMemo(() => {
    let res: any = {}
    if (direction == 'left' || direction == 'right') {
      const initial = ((direction == 'left' ? -1 : 1) * 100).toString()
      res = { initial: { x: `${initial}%` }, animate: { x: '0' }, exit: { x: `${initial}%` } }
    } else {
      const initial = ((direction == 'bottom' ? 1 : -1) * 100).toString()
      res = { initial: { y: `${initial}%` }, animate: { y: '0' }, exit: { y: `${initial}%` } }
    }

    delete res['exit']

    return res
  }, [direction])

  return (
    <ModalWrapper isOpen={isOpen}>
      <motion.div
        data-modal={'direction'}
        {...variant}
        {...ModalTransition}
        className={`flex size-full flex-col bg-white ${className}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {header}
        <motion.div className={`relative w-full flex-1 overflow-auto`} onClick={(e) => e.stopPropagation()}>
          {children}
        </motion.div>

        <div className={`w-full pb-ios-pb ${classNameIosPb}`}>{footer}</div>
      </motion.div>
    </ModalWrapper>
  )
}

export default ModalDirection
