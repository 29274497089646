export const IPosition = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M3.33337 8.45258C3.33337 4.70473 6.31814 1.6665 10 1.6665C13.6819 1.6665 16.6667 4.70473 16.6667 8.45258C16.6667 12.1711 14.5389 16.5102 11.2192 18.0619C10.4453 18.4236 9.55483 18.4236 8.78093 18.0619C5.46114 16.5102 3.33337 12.1711 3.33337 8.45258Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <ellipse cx='10' cy='8.3335' rx='2.5' ry='2.5' stroke='var(--secondary-color)' strokeWidth='1.5' />
    </svg>
  )
}

export const IConnect = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_801_799)'>
        <circle cx='9.99996' cy='9.99984' r='8.33333' stroke='currentColor' strokeWidth='1.5' />
        <path
          d='M5.41675 3.3335L11.5654 10.0002V6.25016'
          stroke='var(--secondary-color)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.482 16.5654L8.23206 10.0001V13.7501'
          stroke='var(--secondary-color)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_801_799'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ILock = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M2.75 16C2.75 13.1716 2.75 11.7574 3.62868 10.8787C4.50736 10 5.92157 10 8.75 10H16.75C19.5784 10 20.9926 10 21.8713 10.8787C22.75 11.7574 22.75 13.1716 22.75 16C22.75 18.8284 22.75 20.2426 21.8713 21.1213C20.9926 22 19.5784 22 16.75 22H8.75C5.92157 22 4.50736 22 3.62868 21.1213C2.75 20.2426 2.75 18.8284 2.75 16Z'
        stroke='var(--secondary-color)'
        strokeWidth='1.5'
      />
      <circle cx='12.75' cy='16' r='2' stroke='#232A75' strokeWidth='1.5' />
      <path
        d='M6.75 10V8C6.75 4.68629 9.43629 2 12.75 2C16.0637 2 18.75 4.68629 18.75 8V10'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
