import { useRef } from 'react'
import { ArrowDownIcon } from '@src/assets/icons/common'

type Props = {
  headerLabel: string
  children: React.ReactNode
  isExpanded?: boolean
}

export default function FilterSectionWrapper({ children, headerLabel, isExpanded = false }: Props) {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={ref}
      data-collapse={isExpanded}
      className='group flex flex-col rounded-[20px] bg-white p-4'
      onClick={() => {
        if (ref.current) {
          ref.current.getAttribute('data-collapse') == 'false'
            ? ref.current.setAttribute('data-collapse', 'true')
            : ref.current.setAttribute('data-collapse', 'false')
        }
      }}
    >
      <div className='flex w-full items-center justify-between'>
        <p className='text-lg font-semibold text-[#0E121B]'>{headerLabel}</p>
        <button className='flex size-6 items-center justify-center rounded-[6px] border border-soft-200'>
          <span className='rotate-0 transition-all duration-200 group-data-[collapse=false]:rotate-180'>
            <ArrowDownIcon />
          </span>
        </button>
      </div>

      <div className='max-h-[1000px] overflow-hidden transition-all group-data-[collapse=true]:max-h-0'>
        <div className='w-full pt-5' onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  )
}
