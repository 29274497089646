const IRocket = () => {
  return (
    <svg
      className='w-full'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 2045 3463'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M548.68 2486.23C548.68 2486.23 742.94 2641.8 640.51 2846C538.08 3050.2 293.43 3018 246 3121.87C293.39 3018.02 108.78 2854.3 195.9 2643.12C283.02 2431.94 527.84 2476.74 527.84 2476.74'
        fill='#E93544'
      />
      <path
        d='M515.94 2530.32C473.94 2526.21 352.5 2525 305.32 2639.32C269.6 2725.92 299.32 2802.89 330.75 2884.39C334.37 2893.76 338 2903.17 341.52 2912.62C350.96 2909.09 360.45 2905.62 369.9 2902.26C452.07 2872.61 529.68 2844.61 571.68 2760.87C627 2650.64 547.09 2559.88 515.94 2530.32Z'
        fill='#EFAF30'
      />
      <path
        d='M507.22 2549.49C484.11 2547.23 417.29 2546.56 391.34 2609.49C371.68 2657.14 388.02 2699.49 405.34 2744.32C407.34 2749.48 409.34 2754.66 411.27 2759.86C416.47 2757.91 421.68 2756.03 426.89 2754.16C472.09 2737.84 514.8 2722.44 537.89 2676.37C568.32 2615.69 524.36 2565.75 507.22 2549.49Z'
        fill='#FFEC7B'
      />
      <path
        d='M246 3148.26C241.58 3148.26 237.231 3147.15 233.352 3145.03C229.472 3142.92 226.187 3139.86 223.798 3136.14C221.408 3132.42 219.992 3128.16 219.678 3123.75C219.363 3119.35 220.162 3114.93 222 3110.91C237.23 3077.53 218.06 3027.91 195.87 2970.36C160.67 2879.12 116.87 2765.56 171.51 2633.06C210.71 2538.06 287.94 2477.24 394.87 2457.17C473.6 2442.39 540.43 2455.02 543.24 2455.57C547.34 2456.37 551.192 2458.12 554.48 2460.7C556.73 2462.46 610.09 2504.7 650.53 2573.84C705.46 2667.76 710.13 2765.97 664.05 2857.84C599.83 2986 485.34 3027.26 393.34 3060.45C335.34 3081.37 285.28 3099.45 270.05 3132.82C267.947 3137.43 264.561 3141.34 260.296 3144.08C256.031 3146.82 251.068 3148.27 246 3148.26ZM472.2 2502.46C397.2 2502.46 273.76 2523.62 220.31 2653.19C173.67 2766.19 211.63 2864.58 245.12 2951.36C259.12 2987.55 272.05 3021.14 277.59 3052.67C305.04 3036.2 338.9 3023.98 375.39 3010.81C462.89 2979.24 562.07 2943.46 616.89 2834.17C700.11 2668.27 555.61 2531.3 526.79 2506.31C508.721 2503.7 490.486 2502.41 472.23 2502.46H472.2Z'
        fill='#232A75'
      />
      <path
        d='M270.05 3132.82L97.8099 3459.63C94.7399 3465.39 85.6099 3461.54 88.0299 3455.17L222.03 3110.91C236.8 3077.14 285.89 3099.34 270.03 3132.82H270.05Z'
        fill='#232A75'
      />
      <path
        d='M442.06 1592C442.06 1592 -157.33 1559.13 84.2201 2196.7C84.2201 2196.7 159.94 1881.14 431.79 1922.49L442.06 1592Z'
        fill='#E93544'
      />
      <g opacity='0.6'>
        <path
          d='M440.14 1653.84L442.06 1591.96C442.06 1591.96 -157.33 1559.08 84.2201 2196.66C84.2201 2196.66 84.5301 2195.4 85.1601 2193.06C-68.6599 1697.61 333 1656.21 440.14 1653.84Z'
          fill='white'
        />
      </g>
      <path
        d='M84.21 2223C78.8593 2223 73.6346 2221.38 69.2287 2218.34C64.8228 2215.3 61.4439 2211 59.54 2206C-20.39 1995 -19.2299 1831.08 62.98 1718.73C183.98 1553.35 432.98 1565.03 443.51 1565.6C450.39 1565.98 456.849 1569.03 461.508 1574.11C466.166 1579.19 468.654 1585.88 468.44 1592.77L458.16 1923.26C458.044 1926.99 457.138 1930.65 455.503 1934.01C453.867 1937.36 451.539 1940.33 448.672 1942.72C445.805 1945.1 442.464 1946.86 438.869 1947.86C435.275 1948.86 431.509 1949.09 427.82 1948.53C326.15 1933.06 242.23 1969.97 178.37 2058.22C128.68 2126.88 110.06 2202.09 109.87 2202.84C108.543 2208.32 105.497 2213.23 101.178 2216.85C96.8597 2220.47 91.4953 2222.62 85.87 2222.97C85.31 2223 84.76 2223 84.21 2223ZM414.84 1618.28C351.69 1620.12 188.54 1636.28 105.43 1750.07C43.48 1834.91 36.6 1958.07 84.74 2116.69C98.3258 2085.72 115.039 2056.21 134.62 2028.63C203.86 1932.17 297.26 1885.73 406.3 1893.08L414.84 1618.28Z'
        fill='#232A75'
      />
      <path
        d='M1273.21 1971.25C1273.21 1971.25 1690.81 2402.48 1050.91 2637.81C1050.91 2637.81 1239.67 2373.81 1030.29 2195.58L1273.21 1971.25Z'
        fill='#E93544'
      />
      <g opacity='0.3'>
        <path
          d='M1065.07 2615.46C1104.73 2548.21 1203.07 2342.69 1030.32 2195.6L1200.18 2038.71C1277 2139.66 1440.3 2420.49 1065.07 2615.46Z'
          fill='#232A75'
        />
      </g>
      <path
        d='M1050.92 2664.2C1046.07 2664.2 1041.32 2662.86 1037.18 2660.33C1033.04 2657.81 1029.68 2654.19 1027.46 2649.88C1025.24 2645.56 1024.25 2640.72 1024.61 2635.89C1024.96 2631.05 1026.63 2626.41 1029.45 2622.46C1029.88 2621.85 1074.45 2558.46 1093.79 2475.99C1118.6 2369.91 1091.48 2282.33 1013.19 2215.67C1010.35 2213.25 1008.05 2210.26 1006.45 2206.89C1004.85 2203.52 1003.99 2199.85 1003.91 2196.11C1003.84 2192.38 1004.55 2188.68 1006.01 2185.25C1007.48 2181.81 1009.65 2178.73 1012.39 2176.2L1255.31 1951.87C1260.37 1947.19 1267.05 1944.68 1273.94 1944.87C1280.82 1945.05 1287.36 1947.92 1292.16 1952.87C1299.51 1960.45 1471.47 2140.87 1425.85 2340.65C1394.85 2476.37 1271.77 2584.65 1060.02 2662.54C1057.11 2663.63 1054.03 2664.19 1050.92 2664.2ZM1069.15 2195.61C1146.15 2273.09 1172.29 2374.14 1144.79 2489.61C1136.8 2522.47 1125.47 2554.43 1111 2585C1262.52 2517.34 1351 2431.38 1374.43 2328.89C1405.87 2191.18 1311.2 2057.77 1271.2 2009.01L1069.15 2195.61Z'
        fill='#232A75'
      />
      <path
        d='M755.47 2005.62L1054.73 2142.19L879 2338.89C857.598 2362.84 829.094 2379.32 797.655 2385.92C766.217 2392.51 733.494 2388.88 704.27 2375.54L606.92 2331.11L509.58 2286.69C480.357 2273.35 456.166 2251.02 440.545 2222.95C424.924 2194.88 418.692 2162.55 422.76 2130.69L456.16 1869L755.42 2005.57'
        fill='#00BAEE'
      />
      <g opacity='0.3'>
        <path
          d='M787.25 2020.12L629.7 2196.46C608.3 2220.41 579.801 2236.88 548.369 2243.48C516.937 2250.07 484.22 2246.44 455 2233.11L443.56 2227.89C459.162 2253.67 482.158 2274.16 509.56 2286.69L606.9 2331.11L704.25 2375.54C733.476 2388.88 766.204 2392.52 797.646 2385.92C829.089 2379.33 857.596 2362.85 879 2338.89L1054.75 2142.19L787.25 2020.12Z'
          fill='#232A75'
        />
      </g>
      <path
        d='M767.06 2415.67C741.598 2415.65 716.438 2410.15 693.29 2399.54L498.6 2310.69C464.176 2295.12 435.675 2268.88 417.307 2235.87C398.939 2202.85 391.679 2164.8 396.6 2127.34L430 1865.69C430.523 1861.59 432.002 1857.67 434.319 1854.24C436.635 1850.82 439.723 1847.98 443.335 1845.97C446.946 1843.96 450.98 1842.82 455.112 1842.65C459.243 1842.48 463.356 1843.29 467.12 1845L766.38 1981.56L766.68 1981.7L1065.68 2118.15C1069.44 2119.87 1072.75 2122.44 1075.33 2125.67C1077.91 2128.9 1079.7 2132.69 1080.55 2136.74C1081.4 2140.79 1081.28 2144.98 1080.22 2148.97C1079.15 2152.97 1077.16 2156.66 1074.4 2159.74L898.65 2356.44C882.141 2375.04 861.879 2389.94 839.198 2400.15C816.518 2410.36 791.932 2415.65 767.06 2415.67ZM477.8 1907.91L448.92 2134C445.47 2160.29 450.568 2186.99 463.459 2210.15C476.351 2233.32 496.353 2251.73 520.51 2262.65L715.21 2351.5C739.291 2362.59 766.301 2365.64 792.247 2360.19C818.194 2354.75 841.703 2341.11 859.3 2321.28L1011.19 2151.28L744.52 2029.59L744.21 2029.45L477.8 1907.91Z'
        fill='#232A75'
      />
      <path
        d='M1080.23 2220.53C1060.57 2239.5 1035.65 2252.12 1008.72 2256.73C981.786 2261.35 954.089 2257.75 929.23 2246.41L459.64 2032.13C434.793 2020.78 413.936 2002.22 399.784 1978.85C385.632 1955.49 378.839 1928.41 380.29 1901.13C391 1697.39 445.16 1190.92 713.84 673.35C770.3 564.7 833.24 474.17 899.29 398.82C1149.9 112.42 1443.36 44.2998 1581 28.2998C1605.44 25.4749 1630.2 29.3849 1652.58 39.6074C1674.96 49.8298 1694.13 65.9757 1708 86.2999C1786.23 200.76 1927.1 467 1874.91 844C1861.27 943.27 1834.1 1050.2 1789.02 1164C1574.18 1706.15 1227.05 2078.94 1080.23 2220.53Z'
        fill='white'
      />
      <path
        d='M1874.91 844C1469.19 292.81 899.29 398.86 899.29 398.86C1149.9 112.42 1443.36 44.2998 1581 28.2998C1605.44 25.4749 1630.2 29.3849 1652.58 39.6074C1674.96 49.8298 1694.13 65.9757 1708 86.2999C1786.23 200.76 1927.1 467 1874.91 844Z'
        fill='#E93544'
      />
      <g opacity='0.6'>
        <path
          d='M1698.82 74.3701C1688.11 72.9729 1677.28 72.8956 1666.55 74.1401C1528.88 90.1401 1235.46 158.21 984.78 444.62C918.78 520.01 855.86 610.56 799.39 719.2C575.39 1150.67 500.46 1574.37 475.51 1821.43C470.51 1870.81 467.59 1913.03 465.78 1946.96C464.718 1967.27 468.216 1987.57 476.018 2006.35C483.821 2025.14 495.729 2041.94 510.87 2055.52L477.32 2040.22L459.67 2032.15C434.819 2020.8 413.958 2002.24 399.8 1978.88C385.643 1955.51 378.844 1928.43 380.29 1901.15C390.99 1697.44 445.12 1190.96 713.82 673.39C770.29 564.75 833.24 474.2 899.29 398.81C1149.89 112.44 1443.39 44.2901 1581 28.3101C1602.91 25.7726 1625.1 28.6445 1645.64 36.6744C1666.18 44.7043 1684.44 57.6473 1698.82 74.3701Z'
          fill='white'
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M1796.45 811.7C1782.8 911 1755.66 1017.9 1710.58 1131.7C1495.71 1673.85 1148.58 2046.7 1001.82 2188.25C985.774 2203.71 966.181 2215.01 944.757 2221.15C923.333 2227.28 900.731 2228.07 878.93 2223.45L510.87 2055.45C495.744 2041.88 483.844 2025.1 476.043 2006.33C468.241 1987.57 464.734 1967.29 465.78 1947C467.59 1913.07 470.53 1870.85 475.51 1821.47L751.67 1947.47C776.534 1958.85 804.249 1962.46 831.2 1957.85C858.151 1953.24 883.086 1940.61 902.75 1921.61C979.43 1847.61 1110.75 1710.61 1249.26 1519.32C1388.96 1494.51 1510.49 1402.39 1570.58 1270.68C1620.64 1161 1621.31 1041.2 1582 936.4C1592.1 912.96 1601.92 889.163 1611.47 865.01C1656.56 751.16 1683.7 644.26 1697.35 545.01C1718.76 390.68 1707.75 254.9 1681.89 140.53C1756.34 279.29 1838.29 510.06 1796.45 811.7Z'
          fill='#232A75'
        />
      </g>
      <path
        d='M1874.94 870.47C1870.8 870.477 1866.72 869.507 1863.02 867.639C1859.32 865.771 1856.12 863.058 1853.67 859.72C1649.67 582.48 1402.77 479.01 1231.61 441.02C1045.38 399.7 905.47 424.54 904.08 424.8C897.247 425.962 890.231 424.39 884.548 420.422C878.864 416.454 874.97 410.409 873.707 403.594C872.443 396.779 873.912 389.74 877.795 383.999C881.678 378.257 887.664 374.274 894.46 372.91C900.46 371.78 1044.46 345.91 1240.62 388.97C1421.32 428.63 1681.74 537.06 1896.17 828.44C1899.05 832.369 1900.79 837.02 1901.19 841.876C1901.59 846.733 1900.64 851.606 1898.44 855.955C1896.25 860.304 1892.89 863.96 1888.74 866.516C1884.59 869.073 1879.81 870.431 1874.94 870.44V870.47Z'
        fill='#232A75'
      />
      <path
        d='M986 2285.26C962.629 2285.23 939.537 2280.18 918.29 2270.45L448.7 2056.16C418.946 2042.7 393.965 2020.55 377.045 1992.62C360.125 1964.68 352.06 1932.28 353.91 1899.68C364.32 1702.13 417.91 1186 690.46 661.18C967.76 127.18 1403.52 22.3499 1578.01 2.08991C1607.24 -1.34753 1636.85 3.29549 1663.62 15.514C1690.4 27.7324 1713.3 47.0585 1729.86 71.3899C1828.86 216.47 2035.3 614.3 1813.6 1173.72C1595.72 1723.48 1240.98 2102.2 1098.6 2239.5C1068.43 2268.79 1028.05 2285.2 986 2285.26ZM1596.74 53.7799C1592.52 53.78 1588.3 54.0237 1584.1 54.5099C1418 73.7999 1002.94 173.9 737.29 685.5C469.53 1201.17 416.84 1708.34 406.62 1902.5C405.364 1924.52 410.806 1946.41 422.23 1965.27C433.654 1984.14 450.525 1999.11 470.62 2008.2L940.18 2222.48C960.226 2231.71 982.597 2234.64 1004.34 2230.9C1026.09 2227.15 1046.19 2216.91 1062 2201.51C1201.93 2066.59 1550.51 1694.46 1764.58 1154.28C1976.97 618.37 1780.58 239.28 1686.33 101.14C1676.4 86.5341 1663.04 74.5819 1647.42 66.3266C1631.8 58.0713 1614.4 53.7637 1596.74 53.7799Z'
        fill='#232A75'
      />
      <path
        d='M857.63 1781.61C857.63 1781.61 942.34 1932.51 869.13 2105.87C795.92 2279.23 567.57 2417.23 567.57 2417.23C567.57 2417.23 522.17 2154.3 605.16 1985.41C688.15 1816.52 857.63 1781.61 857.63 1781.61Z'
        fill='#E93544'
      />
      <g opacity='0.6'>
        <path
          d='M635.32 2053.26C711.06 1899.1 858.8 1856.59 884.02 1850.34C872.19 1807.61 857.63 1781.61 857.63 1781.61C857.63 1781.61 688.14 1816.51 605.16 1985.41C522.18 2154.31 567.57 2417.23 567.57 2417.23C567.57 2417.23 575.38 2412.51 588.51 2403.76C581.68 2312.56 581.36 2163.07 635.32 2053.26Z'
          fill='white'
        />
      </g>
      <path
        d='M567.57 2443.62C561.349 2443.62 555.328 2441.43 550.57 2437.42C545.812 2433.41 542.625 2427.85 541.57 2421.72C539.65 2410.64 495.7 2148.35 581.48 1973.77C669.15 1795.32 844.88 1757.29 852.31 1755.77C857.793 1754.64 863.492 1755.29 868.585 1757.61C873.677 1759.94 877.899 1763.82 880.64 1768.7C884.36 1775.32 970.78 1932.98 893.44 2116.14C817.77 2295.33 590.84 2434.01 581.21 2439.83C577.093 2442.31 572.377 2443.62 567.57 2443.62ZM843.25 1813.13C798.48 1827.93 688.66 1875.3 628.84 1997.04C569.51 2117.81 579.68 2293.33 588.22 2371.99C653.22 2326.89 792.47 2219.57 844.82 2095.6C897.59 1970.64 861.4 1856.64 843.25 1813.13Z'
        fill='#232A75'
      />
      <path
        d='M1173.51 1450.53C1372.93 1450.53 1534.6 1288.86 1534.6 1089.44C1534.6 890.015 1372.93 728.35 1173.51 728.35C974.085 728.35 812.42 890.015 812.42 1089.44C812.42 1288.86 974.085 1450.53 1173.51 1450.53Z'
        fill='#00BAEE'
      />
      <g opacity='0.6'>
        <path
          d='M890.24 1007.38C973 826 1187.23 746 1368.65 828.79C1417.07 850.841 1460 883.359 1494.34 924C1457.35 851.962 1397.12 794.526 1323.41 761C1142 678.15 927.8 758.1 845 939.53C785 1070.88 810.47 1219.38 897.9 1322.78C849 1227.78 842.44 1112.14 890.24 1007.38Z'
          fill='white'
        />
      </g>
      <path
        d='M1173.31 1477.12C1108.44 1477.05 1044.62 1460.7 987.7 1429.57C930.783 1398.44 882.588 1353.53 847.53 1298.94C812.473 1244.36 791.673 1181.85 787.038 1117.14C782.403 1052.43 794.08 987.594 821 928.57C909.71 734.21 1140 648.24 1334.37 736.93C1528.74 825.62 1614.68 1055.93 1526 1250.3C1495.07 1317.88 1445.4 1375.18 1382.89 1415.38C1320.37 1455.59 1247.64 1477.01 1173.31 1477.12ZM1174.01 754.75C1046.89 754.75 925.14 827.49 869.01 950.48C832.157 1031.24 828.894 1123.33 859.94 1206.49C890.986 1289.66 953.797 1357.08 1034.56 1393.93C1115.31 1430.79 1207.4 1434.05 1290.57 1403C1373.73 1371.96 1441.16 1309.15 1478.01 1228.39C1554.62 1060.49 1480.37 861.56 1312.47 784.95C1269.02 765.053 1221.79 754.752 1174 754.75H1174.01Z'
        fill='#232A75'
      />
      <path
        d='M1327.54 1243.48C1412.61 1158.4 1412.61 1020.48 1327.54 935.403C1242.47 850.332 1104.54 850.332 1019.47 935.403C934.399 1020.48 934.399 1158.4 1019.47 1243.48C1104.54 1328.55 1242.47 1328.55 1327.54 1243.48Z'
        fill='#6ED8F2'
      />
      <g opacity='0.3'>
        <path
          d='M1020.56 1081.93C1070.43 972.65 1199.9 924.32 1309.18 974.19C1337.29 986.997 1362.33 1005.67 1382.62 1028.96C1373.96 998.806 1358.87 970.886 1338.39 947.117C1317.92 923.347 1292.54 904.292 1264 891.26C1154.72 841.39 1025.24 889.72 975.37 999C938.75 1079.24 955.14 1170.34 1009.68 1232.85C995.063 1182.87 998.925 1129.3 1020.56 1081.93Z'
          fill='#232A75'
        />
      </g>
      <path
        d='M1173.14 1333.66C1136.69 1333.59 1100.72 1325.36 1067.87 1309.57C1035.02 1293.79 1006.12 1270.85 983.283 1242.44C960.451 1214.03 944.267 1180.87 935.919 1145.39C927.571 1109.91 927.271 1073.02 935.04 1037.41C942.809 1001.8 958.451 968.377 980.818 939.6C1003.19 910.823 1031.71 887.417 1064.3 871.1C1096.89 854.783 1132.72 845.968 1169.16 845.302C1205.61 844.635 1241.73 852.135 1274.9 867.25C1397.42 923.16 1451.6 1068.32 1395.69 1190.83C1354.74 1280.57 1265.89 1333.66 1173.14 1333.66ZM1173.79 897.99C1101.08 897.99 1031.44 939.6 999.33 1009.99C955.5 1105.99 997.98 1219.83 1094.02 1263.65C1190.06 1307.47 1303.86 1265 1347.68 1168.96C1391.5 1072.92 1349 959.09 1253 915.26C1228.14 903.887 1201.13 898.001 1173.79 898V897.99Z'
        fill='#232A75'
      />
      <path
        d='M143.07 1284.48C170.45 1282.86 197.353 1276.57 222.62 1265.9C197.438 1255.26 170.628 1248.98 143.34 1247.34C141.697 1220.05 135.421 1193.24 124.78 1168.06C114.105 1193.33 107.821 1220.23 106.2 1247.61C59.2 1251.24 26.9399 1265.9 26.9399 1265.9C26.9399 1265.9 59.3199 1280.62 106.47 1284.21C110.06 1331.35 124.78 1363.74 124.78 1363.74C124.78 1363.74 139.44 1331.48 143.07 1284.48Z'
        fill='white'
      />
      <path
        d='M1650.46 2120.24C1677.84 2118.62 1704.74 2112.34 1730.01 2101.66C1704.83 2091.02 1678.03 2084.74 1650.74 2083.1C1649.09 2055.81 1642.81 2029.01 1632.18 2003.82C1621.51 2029.09 1615.22 2055.99 1613.6 2083.37C1566.6 2087 1534.34 2101.66 1534.34 2101.66C1534.34 2101.66 1566.72 2116.38 1613.87 2119.97C1617.46 2167.12 1632.18 2199.5 1632.18 2199.5C1632.18 2199.5 1646.84 2167.24 1650.46 2120.24Z'
        fill='white'
      />
      <path
        d='M1993.88 1437.14C2011.36 1436.1 2028.53 1432.09 2044.65 1425.28C2028.58 1418.5 2011.47 1414.49 1994.06 1413.44C1993.01 1396.03 1989 1378.92 1982.21 1362.85C1975.4 1378.97 1971.4 1396.14 1970.36 1413.61C1940.36 1415.93 1919.78 1425.28 1919.78 1425.28C1919.78 1425.28 1940.44 1434.67 1970.53 1436.97C1972.82 1467.05 1982.21 1487.72 1982.21 1487.72C1982.21 1487.72 1991.57 1467.13 1993.88 1437.14Z'
        fill='white'
      />
      <path
        d='M537.36 550C554.832 548.965 571.999 544.954 588.12 538.14C572.051 531.349 554.943 527.345 537.53 526.3C536.485 508.887 532.481 491.779 525.69 475.71C518.881 491.833 514.87 508.999 513.83 526.47C483.83 528.79 463.26 538.14 463.26 538.14C463.26 538.14 483.92 547.5 514 549.8C516.29 579.88 525.68 600.55 525.68 600.55C525.68 600.55 535.05 580 537.36 550Z'
        fill='white'
      />
    </svg>
  )
}

export default IRocket
