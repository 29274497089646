import { PORTAL } from '@src/constants/key.constants'
import { TAccount } from '@src/types/account'

import axiosClient from './axiosClient'

export const authService = {
  sign_in: async (params: any) => {
    return (await axiosClient.post(`${PORTAL}/auth/sign-in`, params)).data
  },

  get_refresh_token: async (params: any) => {
    return (await axiosClient.post(`${PORTAL}/user/refresh-token`, params)).data
  },
  myProfile: async () => {
    return (await axiosClient.get<TAccount>(`${PORTAL}/user/info`)).data
  },
  forgot_password: async (params: any) => {
    return (await axiosClient.post(`${PORTAL}/user/forgot-password`, params)).data
  },

  reset_password: async (params: any) => {
    return (await axiosClient.put(`${PORTAL}/user/forgot-password`, params)).data
  },
}
