export function IFilter() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.8334 2.5H4.16675C2.98824 2.5 2.39898 2.5 2.03286 2.8435C1.66675 3.187 1.66675 3.73985 1.66675 4.84555V5.4204C1.66675 6.28527 1.66675 6.7177 1.88308 7.07618C2.09942 7.43466 2.49464 7.65715 3.2851 8.10212L5.71262 9.46865C6.24297 9.7672 6.50814 9.91648 6.69801 10.0813C7.0934 10.4246 7.33681 10.8279 7.44711 11.3226C7.50008 11.5602 7.50008 11.8382 7.50008 12.3941L7.50008 14.6187C7.50008 15.3766 7.50008 15.7556 7.71002 16.0511C7.91996 16.3465 8.29282 16.4923 9.03854 16.7838C10.6041 17.3958 11.3868 17.7018 11.9435 17.3537C12.5001 17.0055 12.5001 16.2099 12.5001 14.6187V12.3941C12.5001 11.8382 12.5001 11.5602 12.553 11.3226C12.6633 10.8279 12.9068 10.4246 13.3022 10.0813C13.492 9.91648 13.7572 9.7672 14.2875 9.46865L16.7151 8.10212C17.5055 7.65715 17.9007 7.43466 18.1171 7.07618C18.3334 6.7177 18.3334 6.28527 18.3334 5.4204V4.84555C18.3334 3.73985 18.3334 3.187 17.9673 2.8435C17.6012 2.5 17.0119 2.5 15.8334 2.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}

export function IMagnifier() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='11.5' cy='11.5' r='9.5' stroke='currentColor' strokeWidth='1.5' />
      <path d='M18.5 18.5L22 22' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  )
}

export const IScan = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_203_1441)'>
        <path
          d='M8.33341 18.3333C5.19072 18.3333 3.61937 18.3333 2.64306 17.357C1.66675 16.3807 1.66675 15.6427 1.66675 12.5'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M18.3334 12.5C18.3334 15.6427 18.3334 16.3807 17.3571 17.357C16.3808 18.3333 14.8094 18.3333 11.6667 18.3333'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M11.6667 1.66675C14.8094 1.66675 16.3808 1.66675 17.3571 2.64306C18.3334 3.61937 18.3334 4.35738 18.3334 7.50008'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          d='M8.33341 1.66675C5.19072 1.66675 3.61937 1.66675 2.64306 2.64306C1.66675 3.61937 1.66675 4.35738 1.66675 7.50008'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path d='M1.66675 10H18.3334' stroke='var(--secondary-color)' strokeWidth='1.5' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_203_1441'>
          <rect width='20' height='20' rx='4.16667' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IArrowUp = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_108_12884)'>
        <path
          d='M10 6.6665L10 13.3332M10 13.3332L12.5 10.8332M10 13.3332L7.5 10.8332'
          stroke='#E39717'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.66675 9.99984C1.66675 6.07147 1.66675 4.10728 2.88714 2.88689C4.10752 1.6665 6.07171 1.6665 10.0001 1.6665C13.9285 1.6665 15.8926 1.6665 17.113 2.88689C18.3334 4.10728 18.3334 6.07147 18.3334 9.99984C18.3334 13.9282 18.3334 15.8924 17.113 17.1128C15.8926 18.3332 13.9285 18.3332 10.0001 18.3332C6.07171 18.3332 4.10752 18.3332 2.88714 17.1128C1.66675 15.8924 1.66675 13.9282 1.66675 9.99984Z'
          stroke='#008C4F'
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_108_12884'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IArrow = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_108_12883)'>
        <path
          d='M7.5 12.5L12.5 7.5M12.5 7.5H8.75M12.5 7.5V11.25'
          stroke='#E39717'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.66675 9.99984C1.66675 6.07147 1.66675 4.10728 2.88714 2.88689C4.10752 1.6665 6.07171 1.6665 10.0001 1.6665C13.9285 1.6665 15.8926 1.6665 17.113 2.88689C18.3334 4.10728 18.3334 6.07147 18.3334 9.99984C18.3334 13.9282 18.3334 15.8924 17.113 17.1128C15.8926 18.3332 13.9285 18.3332 10.0001 18.3332C6.07171 18.3332 4.10752 18.3332 2.88714 17.1128C1.66675 15.8924 1.66675 13.9282 1.66675 9.99984Z'
          stroke='#008C4F'
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_108_12883'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export function IArrowDownSmall() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M14 8L10.7071 11.6745C10.3182 12.1085 9.68182 12.1085 9.29293 11.6745L6 8'
        stroke='#666666'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export function IDetailDivider() {
  return (
    <div className='flex w-full items-stretch overflow-hidden'>
      <span>
        <svg className='translate-x-[0.4px]' width='15' height='30' viewBox='0 0 15 30' fill='none'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15 30H0C8.28427 30 15 23.2843 15 15V30ZM15 0V15C15 6.71573 8.28427 0 0 0H15Z'
            fill='currentColor'
          />
        </svg>
      </span>
      <span className='z-10 flex flex-1 items-center border border-white bg-white text-sub-300'>
        <svg width='100%' height='1' fill='none'>
          <line
            x1='0.5'
            y1='0.5'
            x2='100%'
            y2='0.5'
            stroke='currentColor'
            strokeLinecap='round'
            strokeDasharray='6 6'
          />
        </svg>
      </span>
      <span className='rotate-180'>
        <svg width='15' height='30' viewBox='0 0 15 30' fill='none'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15 30H0C8.28427 30 15 23.2843 15 15V30ZM15 0V15C15 6.71573 8.28427 0 0 0H15Z'
            fill='currentColor'
          />
        </svg>
      </span>
    </div>
  )
}

export function IShare() {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5 12C9.5 13.3807 8.38071 14.5 7 14.5C5.61929 14.5 4.5 13.3807 4.5 12C4.5 10.6193 5.61929 9.5 7 9.5C8.38071 9.5 9.5 10.6193 9.5 12Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M14.5 6.5L9.5 10' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M14.5 17.5L9.5 14' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
      <path
        d='M19.5 18.5C19.5 19.8807 18.3807 21 17 21C15.6193 21 14.5 19.8807 14.5 18.5C14.5 17.1193 15.6193 16 17 16C18.3807 16 19.5 17.1193 19.5 18.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M19.5 5.5C19.5 6.88071 18.3807 8 17 8C15.6193 8 14.5 6.88071 14.5 5.5C14.5 4.11929 15.6193 3 17 3C18.3807 3 19.5 4.11929 19.5 5.5Z'
        stroke='var(--secondary-color)'
        strokeWidth='1.5'
      />
    </svg>
  )
}

export function IInboxIn() {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 2L12.5 10M12.5 10L15.5 7M12.5 10L9.5 7'
        stroke='var(--secondary-color)'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 13H5.66026C6.56543 13 7.01802 13 7.41584 13.183C7.81367 13.3659 8.10821 13.7096 8.69729 14.3968L9.30271 15.1032C9.89179 15.7904 10.1863 16.1341 10.5842 16.317C10.982 16.5 11.4346 16.5 12.3397 16.5H12.6603C13.5654 16.5 14.018 16.5 14.4158 16.317C14.8137 16.1341 15.1082 15.7904 15.6973 15.1032L16.3027 14.3968C16.8918 13.7096 17.1863 13.3659 17.5842 13.183C17.982 13 18.4346 13 19.3397 13H22.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M17.5 2.12695C19.1251 2.28681 20.2191 2.64808 21.0355 3.46455C22.5 4.92902 22.5 7.28604 22.5 12.0001C22.5 16.7141 22.5 19.0712 21.0355 20.5356C19.5711 22.0001 17.214 22.0001 12.5 22.0001C7.78595 22.0001 5.42893 22.0001 3.96447 20.5356C2.5 19.0712 2.5 16.7141 2.5 12.0001C2.5 7.28604 2.5 4.92902 3.96447 3.46455C4.78094 2.64808 5.87486 2.28681 7.5 2.12695'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export function IHomeSmall() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_305_2229)'>
        <path
          d='M1.66669 10.1698C1.66669 8.26275 1.66669 7.30923 2.09935 6.51879C2.53202 5.72834 3.32247 5.23777 4.90338 4.25661L6.57005 3.22223C8.24118 2.18508 9.07674 1.6665 10 1.6665C10.9233 1.6665 11.7589 2.18508 13.43 3.22223L15.0967 4.25661C16.6776 5.23776 17.468 5.72834 17.9007 6.51879C18.3334 7.30923 18.3334 8.26275 18.3334 10.1698V11.4373C18.3334 14.688 18.3334 16.3134 17.357 17.3233C16.3807 18.3332 14.8094 18.3332 11.6667 18.3332H8.33335C5.19066 18.3332 3.61931 18.3332 2.643 17.3233C1.66669 16.3134 1.66669 14.688 1.66669 11.4373V10.1698Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
        <path d='M12.5 15H7.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_305_2229'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ICalendar = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M1.33337 7.99984C1.33337 5.48568 1.33337 4.2286 2.11442 3.44755C2.89547 2.6665 4.15255 2.6665 6.66671 2.6665H9.33337C11.8475 2.6665 13.1046 2.6665 13.8857 3.44755C14.6667 4.2286 14.6667 5.48568 14.6667 7.99984V9.33317C14.6667 11.8473 14.6667 13.1044 13.8857 13.8855C13.1046 14.6665 11.8475 14.6665 9.33337 14.6665H6.66671C4.15255 14.6665 2.89547 14.6665 2.11442 13.8855C1.33337 13.1044 1.33337 11.8473 1.33337 9.33317V7.99984Z'
        stroke='#99A0AE'
      />
      <path d='M4.66663 2.6665V1.6665' stroke='#99A0AE' strokeLinecap='round' />
      <path d='M11.3334 2.6665V1.6665' stroke='#99A0AE' strokeLinecap='round' />
      <path d='M1.66663 6H14.3333' stroke='#99A0AE' strokeLinecap='round' />
      <path
        d='M11.5 11.3333C11.5 11.4254 11.4253 11.5 11.3333 11.5C11.2412 11.5 11.1666 11.4254 11.1666 11.3333C11.1666 11.2413 11.2412 11.1667 11.3333 11.1667C11.4253 11.1667 11.5 11.2413 11.5 11.3333ZM11.5 8.66667C11.5 8.75871 11.4253 8.83333 11.3333 8.83333C11.2412 8.83333 11.1666 8.75871 11.1666 8.66667C11.1666 8.57462 11.2412 8.5 11.3333 8.5C11.4253 8.5 11.5 8.57462 11.5 8.66667Z'
        fill='#99A0AE'
        stroke='#99A0AE'
      />
      <path
        d='M8.66671 11.3333C8.66671 11.7015 8.36823 12 8.00004 12C7.63185 12 7.33337 11.7015 7.33337 11.3333C7.33337 10.9651 7.63185 10.6667 8.00004 10.6667C8.36823 10.6667 8.66671 10.9651 8.66671 11.3333Z'
        fill='#99A0AE'
      />
      <path
        d='M8.66671 8.66667C8.66671 9.03486 8.36823 9.33333 8.00004 9.33333C7.63185 9.33333 7.33337 9.03486 7.33337 8.66667C7.33337 8.29848 7.63185 8 8.00004 8C8.36823 8 8.66671 8.29848 8.66671 8.66667Z'
        fill='#99A0AE'
      />
      <path
        d='M5.33333 11.3333C5.33333 11.7015 5.03486 12 4.66667 12C4.29848 12 4 11.7015 4 11.3333C4 10.9651 4.29848 10.6667 4.66667 10.6667C5.03486 10.6667 5.33333 10.9651 5.33333 11.3333Z'
        fill='#99A0AE'
      />
      <path
        d='M5.33333 8.66667C5.33333 9.03486 5.03486 9.33333 4.66667 9.33333C4.29848 9.33333 4 9.03486 4 8.66667C4 8.29848 4.29848 8 4.66667 8C5.03486 8 5.33333 8.29848 5.33333 8.66667Z'
        fill='#99A0AE'
      />
    </svg>
  )
}
