import { useFilterStore } from '@src/store/states/filter.state'
import { TTransaction } from '@src/types/transaction'
import { useQueryClient } from '@tanstack/react-query'

import ModalHeader from '../modal/header/modal-header'
import ModalBottom from '../modal/modal-bottom'

import CalendarSection from './calendar-section'
import SaleLocationSection from './sale-location-section'

type Props = {
  isOpen: boolean
  onClose: () => void
  controlItemValue: TTransaction['payment_method'] | 'all'
}
export default function FilterModal({ isOpen, onClose, controlItemValue }: Props) {
  const { resetFilterOptions } = useFilterStore()

  const queryClient = useQueryClient()

  return (
    <>
      <ModalBottom
        isOpen={isOpen}
        onClose={onClose}
        className='min-h-[90dvh]'
        classNameIosPb='bg-white'
        header={<ModalHeader onClick={onClose}>Bộ lọc</ModalHeader>}
        footer={
          <div
            style={{
              boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.04)',
            }}
            className='flex items-center justify-center gap-[18px] bg-white px-4 pt-4'
          >
            <button
              onClick={() => {
                resetFilterOptions()
                queryClient.invalidateQueries({
                  queryKey: ['transactions' + controlItemValue],
                })
                onClose()
              }}
              className='flex-1 px-4 py-3 underline'
            >
              <span className='text-base font-medium leading-6 text-sub-600'>Mặc định</span>
            </button>

            <button
              onClick={() => {
                queryClient.invalidateQueries({
                  queryKey: ['transactions' + controlItemValue],
                })
                onClose()
              }}
              className='flex-1 rounded-full bg-primary/10 px-4 py-3'
            >
              <span className='text-base font-medium leading-6 text-primary'>Áp dụng</span>
            </button>
          </div>
        }
      >
        <div className='flex flex-col gap-2 p-4'>
          <CalendarSection />
          {/* <TransactionSection /> */}
          {/* <MoneyAmountSection /> */}
          <SaleLocationSection />
        </div>
      </ModalBottom>
    </>
  )
}
