import { useMemo } from 'react'
import { getRandomId } from '@src/utils/common'

import SwipeAreaChildren from './swipe-area-children'
import SwipeControl from './swipe-control'

type SwiperProps<T extends { id: string }> = {
  options: T[]
  value?: T
  onChange: (value: T) => void
  renderControlItem?: (item: T) => React.ReactNode
  children?: React.ReactNode[]
  controlItemContainerClassName?: string
  renderOnlyIfInview?: boolean
}

const Swiper = <T extends { id: string }>({
  onChange,
  options,
  value,
  renderControlItem,
  children,
  controlItemContainerClassName,
  renderOnlyIfInview,
}: SwiperProps<T>) => {
  const swipeAreaContainerId = useMemo(() => getRandomId(), [])
  return (
    <div className='relative flex size-full flex-col'>
      <div className={controlItemContainerClassName}>
        <SwipeControl
          options={options}
          value={value}
          onChange={onChange}
          swipeAreaContainerId={swipeAreaContainerId}
          renderItem={renderControlItem}
        />
      </div>
      <div
        data-inview={true}
        className='flex w-full flex-1 snap-x snap-mandatory flex-nowrap items-center overflow-x-auto scroll-smooth'
        onMouseEnter={(e) => {
          e.currentTarget.style.overflowX = 'hidden'
        }}
        onMouseMove={(e) => {
          e.currentTarget.style.overflowX = 'hidden'
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.overflowX = 'auto'
        }}
        onTouchStart={(e) => {
          e.currentTarget.style.overflowX = 'auto'
          e.currentTarget.setAttribute('data-inview', 'true')
        }}
      >
        {children?.map((item, index) => (
          <SwipeAreaChildren<T>
            key={index}
            swipeAreaContainerId={swipeAreaContainerId}
            controlItem={options[index]}
            value={value}
            onChange={onChange}
            renderOnlyIfInview={renderOnlyIfInview}
          >
            {item}
          </SwipeAreaChildren>
        ))}
      </div>
    </div>
  )
}

export default Swiper
