import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LayoutHeader } from '@src/components/common/layout-header'
import ModalDirection from '@src/components/modal/modal-direction'
import { openToast } from '@src/components/toaster/custom-toast'
import { logo } from '@src/constants/bank.constants'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import { IInboxIn, IShare } from '@src/constants/icons/transaction.icon'
import { MERCHANT_QR, OPEN } from '@src/constants/key.constants'
import { useFakeInvoice } from '@src/hooks/use-fake-invoice'
import { useQueryParams } from '@src/hooks/use-query-params'
import { useSocket } from '@src/hooks/use-socket'
import { useShopStore } from '@src/store/states/shop.state'
import { getThemeBank } from '@src/utils/theme-color'
import { useQueryClient } from '@tanstack/react-query'

export default function QRScan() {
  const { getParams, deleteParams } = useQueryParams()
  const openQR = getParams(OPEN) === MERCHANT_QR
  const navigate = useNavigate()
  const { currentShop } = useShopStore()
  const [qr, setQr] = useState('')
  const myAudio = useRef<HTMLAudioElement | null>(null)

  const handleDownload = async () => {
    const element = document.querySelector('img[width="302"]')

    if (element) {
      const svgData = new XMLSerializer().serializeToString(element)
      const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'qr-code.svg'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const { getQrPayment } = useFakeInvoice()

  const handleGetQrPayment = async () => {
    const res = await getQrPayment()
    if (res) setQr(res)
  }
  const queryClient = useQueryClient()

  useEffect(() => {
    if (openQR) handleGetQrPayment()
  }, [openQR])

  useSocket({
    url: 'https://bvbank-stag-socket.atompos.vn',
    emitEvent: {
      name: 'subscribe',
      payload: { merchant_id: String(2877) },
    },
    onEvent: {
      name: 'payment_success',
      func: async ({ data }) => {
        if (data) {
          myAudio.current && myAudio.current.play()
          queryClient.invalidateQueries({ queryKey: ['report-dashboard'] })
          handleGetQrPayment()
        }
      },
    },
  })
  const onClose = () => {
    try {
      navigate(-1)
    } catch (error) {
      deleteParams(OPEN)
    }
  }

  return (
    <>
      <audio
        ref={myAudio}
        src={'https://res.cloudinary.com/dgpmtcqtj/video/upload/v1705738800/lak03qewvuvvcihhqagv.mp3'}
      />
      <ModalDirection
        isOpen={openQR}
        onClose={onClose}
        className='!bg-weak-50'
        direction='right'
        header={<LayoutHeader onBack={onClose} title='Quét mã để chuyển tiền' />}
      >
        <div className='flex flex-col items-center justify-between bg-weak-50 p-4'>
          <div className='flex w-full flex-col gap-8 rounded-2xl bg-white px-4 py-6'>
            {/*  */}
            <div className='w-full px-[121px]'>
              <img src='./common/viet-qr.png' alt='' className='w-full' />
            </div>

            {/*  */}
            <div className='flex flex-col items-center justify-center gap-6 px-[66px]'>
              <div className='w-full'>
                {qr ? (
                  <img src={qr} alt='' className='w-full' />
                ) : (
                  <div className='aspect-square w-full animate-pulse rounded bg-soft-200'></div>
                )}
              </div>

              <div className='flex w-full items-center justify-between pr-3'>
                <img src='./common/napas.png' className='h-6' alt='' />
                <img src={logo[getThemeBank()]} alt='' className='h-6' />
              </div>
            </div>

            {/*  */}
            <div className='flex flex-col items-center justify-center gap-2'>
              <p className='text-lg font-bold text-primary'>{currentShop?.name}</p>

              <p className='text-sm font-normal leading-6 text-sub-600'>
                Tài khoản QR:
                <span className='font-semibold'> {currentShop?.va_account_no}</span>
              </p>

              <p className='text-sm font-normal leading-6 text-sub-600'>
                Số tài khoản:
                <span className='font-semibold'> 31052654</span>
              </p>
            </div>
          </div>

          <div className='mt-6 flex w-full flex-row-reverse items-center px-8'>
            {[
              { icon: IShare, title: 'Chia sẻ' },
              { icon: IInboxIn, title: 'Lưu ảnh' },
            ].map((item, index) => (
              <button
                key={index}
                onClick={index === 1 ? handleDownload : () => openToast({ message: 'Sắp ra mắt!' })}
                className='flex flex-1 flex-col items-center justify-center gap-2 active:opacity-active'
              >
                <IconWrapper size={48} rounded='16px'>
                  {item.icon()}
                </IconWrapper>
                <p className='text-sm font-medium text-strong-950'>{item.title}</p>
              </button>
            ))}
          </div>
        </div>
      </ModalDirection>
    </>
  )
}
