import { TAccount } from '@src/types/account'
import { create } from 'zustand'

type State = {
  myProfile?: TAccount
}

type Actions = {
  setMyProfile: (myProfile: TAccount) => void
}

export const useAccountStore = create<State & Actions>()((set) => ({
  myProfile: undefined,
  setMyProfile: (myProfile: TAccount) => set((state) => ({ ...state, myProfile })),
}))
