import { Navigate, Outlet } from 'react-router-dom'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { useAuth } from '@src/providers/auth-provider'

const PrivateRoute = () => {
  const user = useAuth()
  if (!user.token) {
    return <Navigate to={ROUTES.sign_in} />
  }

  return <Outlet />
}

export default PrivateRoute
