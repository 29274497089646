import { IArrowNext } from '@src/constants/icons/report.icon'

export const SelectInput = ({
  icon,
  label,
  defaultValue,
  onClick,
  isExpand,
}: {
  icon: JSX.Element
  label: string
  defaultValue: string
  onClick: () => void
  isExpand?: boolean
}) => {
  return (
    <div className='cursor-pointer' onClick={() => onClick()}>
      <p className='text-sm font-semibold leading-5 text-sub-600'>{label}</p>

      <div className='mt-[6px] flex h-11 items-center gap-2 rounded-[10px] border border-soft-200 px-3'>
        {icon}
        <span className='flex-1 text-sm leading-5 tracking-[-0.21px] text-strong-950'>{defaultValue}</span>
        <button className={`${isExpand ? '-rotate-90' : 'rotate-90'} transition-all duration-300`}>
          <IArrowNext />
        </button>
      </div>
    </div>
  )
}
