import { home_banner } from '@src/constants/bank.constants'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import { IArrowNext, ICard, ICash, IEcom, IVA, IWallet } from '@src/constants/icons/report.icon'
import { REPORT_PAYMENT } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { TPaymentMethod } from '@src/types/transaction'
import { getThemeBank } from '@src/utils/theme-color'
import { getTitlePaymentMethod } from '@src/utils/transaction'

import { LayoutHeader } from '../common/layout-header'

import { FilterReport } from './filter-report'

type TReportLine = {
  icon: () => JSX.Element
  description: string
  href: TPaymentMethod
}

const report_transactions: TReportLine[] = [
  {
    icon: IEcom,
    description: 'Thống kê giao dịch trực tuyến từ website và ứng dụng của bạn.',
    href: 'ecom',
  },
  {
    icon: ICard,
    description: 'Báo cáo giao dịch thẻ tín dụng và thẻ ghi nợ tại cửa hàng.',
    href: 'card',
  },
  {
    icon: IVA,
    description: 'Thống kê giao dịch qua tài khoản ảo, đảm bảo thanh toán hiệu quả.',
    href: 'va',
  },
  {
    icon: IWallet,
    description: 'Theo dõi giao dịch ví điện tử như MoMo, ZaloPay,... dễ dàng.',
    href: 'wallet',
  },
  {
    icon: ICash,
    description: 'Báo cáo chi tiết các giao dịch tiền mặt tại cửa hàng của bạn.',
    href: 'cash',
  },
]

export const Report = () => {
  const { setParams } = useQueryParams()
  return (
    <>
      <div className='flex h-full flex-col bg-weak-50'>
        <LayoutHeader title={'Báo cáo thống kê'} />
        <div className='flex-1 overflow-auto p-4'>
          <div className='flex flex-col gap-2'>
            {report_transactions.map((item, index) => (
              <div
                key={index}
                className='flex w-full cursor-pointer items-center gap-3 rounded-2xl bg-white p-3 active:opacity-active'
                onClick={() => setParams({ [REPORT_PAYMENT]: item.href })}
              >
                <IconWrapper size={48} rounded={'16px'}>
                  <>{item.icon()}</>
                </IconWrapper>
                <div className='flex-1'>
                  <p className='text-sm font-semibold text-strong-950'>
                    {getTitlePaymentMethod(item.href, 'Giao dịch ')}
                  </p>
                  <p className='text-sm font-normal text-sub-600'>{item.description}</p>
                </div>
                <button className='text-disable-300'>
                  <IArrowNext />
                </button>
              </div>
            ))}
          </div>
          <div className='mt-6 w-full'>
            <img src={home_banner[getThemeBank()]} alt='' className='h-[166px] w-full rounded-2xl object-cover' />
          </div>
        </div>
      </div>
      <FilterReport />
    </>
  )
}
