import {
  ACCESS_TOKEN,
  getAccessToken,
  getRefreshToken,
  REFRESH_TOKEN,
  setAccessToken,
  setRefreshToken,
} from '@src/utils/local-storage'
import axios from 'axios'

interface FailedQueueItem {
  resolve: (value?: unknown) => void
  reject: (value?: unknown) => void
}

const defaultHeader = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Accept: 'application/json',
  userAgent: navigator.userAgent,
}

// for multiple requests
let isRefreshing = false
let failedQueue: FailedQueueItem[] = []
let refreshAttemptCount = 0

const processQueue = (error: unknown, token: unknown = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

// @ts-ignore
const baseURL: string = String(import.meta.env.VITE_APP_API_ENDPOINT)
// Set up default config for http requests here
const axiosClient = axios.create({
  baseURL: baseURL,
})
// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

//Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    const originalRequest = error.config
    // const navigate = useNavigate()
    if (error.response.status === 401 || error.response.status === 403 || error.response.status === 500) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axiosClient.request(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = getRefreshToken()
      return new Promise(function (resolve, reject) {
        axios
          .post(
            `${baseURL}/portal/user/refresh-token`,
            { refresh_token: refreshToken },
            {
              headers: {
                Authorization: 'Bearer ' + getAccessToken(),
              },
            }
          )
          .then((res) => {
            const { data } = res
            // 1) put token to LocalStorage
            if (data) {
              setAccessToken(data.accessToken)
              setRefreshToken(data.refreshToken)
              refreshAttemptCount = 0
              // 2) Change Authorization header
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token
              originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token

              processQueue(null, data.access_token)

              // 3) return originalRequest object with Axios
              resolve(axiosClient.request(originalRequest))
            }
          })
          .catch((err) => {
            refreshAttemptCount += 1
            console.log('>>> check refreshAttemptCount: ', refreshAttemptCount)
            if (refreshAttemptCount >= 3) {
              localStorage.removeItem(ACCESS_TOKEN)
              localStorage.removeItem(REFRESH_TOKEN)
              window.location.href = '/sign_in'
            }
            processQueue(err, null)
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    return response.data || response
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem(ACCESS_TOKEN)
    }
    return Promise.reject(error)
  }
)

// const handleResponse = (res: unknown) => {
//   if (res) {
//     console.log('>>> response axios: ', res)
//   }

//   return res
// }

// const handleError = (error: { response: { data } }) => {
//   const data = error.response?.data;
//   console.error(error);

//   return data ? data : error.response;
// };

export default axiosClient
