import React from 'react'
import { IClose2 } from '@src/constants/icons/header.icon'

const ModalHeader = ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => {
  return (
    <div className='w-full px-5 py-2.5'>
      <div className='relative w-full text-center text-lg font-semibold text-strong-950'>
        {children}

        {onClick && (
          <button
            onClick={onClick}
            className='absolute right-0 top-1/2 flex size-8 -translate-y-1/2 items-center justify-center rounded-full border border-soft-200 bg-white text-sub-600'
          >
            <IClose2 />
          </button>
        )}
      </div>
    </div>
  )
}

export default ModalHeader
