import Calendar from '@src/components/common/calendar-custom'

import FilterSectionWrapper from '../filter-section-wrapper'

export default function CalendarSection() {
  return (
    <FilterSectionWrapper headerLabel='Lọc theo thời gian'>
      <Calendar />
    </FilterSectionWrapper>
  )
}
