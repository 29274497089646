import { useContext } from 'react'
import { ShopListContext, ShopListContextType } from '@src/providers/shop-provider'

export const useShopList = (): ShopListContextType => {
  const context = useContext(ShopListContext)
  if (!context) {
    throw new Error('useShopList must be used within a ShopListProvider')
  }
  return context
}
