import { useMemo, useState } from 'react'
import { TIME_FILTER } from '@src/constants/filter/filter.constant'
import { useFilterStore } from '@src/store/states/filter.state'
import { getAllDatesInRange, getDateRange } from '@src/utils/calendar'

const Calendar = () => {
  const { filterOptions, setFilterOptions } = useFilterStore()

  const [monthAndYear, setMonthAndYear] = useState<{
    month: number
    year: number
  }>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })

  const [monthAndYearBefore, setMonthAndYearBefore] = useState<{
    month: number
    year: number
  }>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const allDays = useMemo(() => {
    const { startDate, endDate } = getDateRange(monthAndYear.year, monthAndYear.month)
    return getAllDatesInRange(startDate, endDate)
  }, [monthAndYear])

  const allDaysBefore = useMemo(() => {
    const { startDate, endDate } = getDateRange(monthAndYearBefore.year, monthAndYearBefore.month)
    return getAllDatesInRange(startDate, endDate)
  }, [monthAndYearBefore])

  const handleDateClick = (date: Date) => {
    if (!filterOptions.startDate) {
      setFilterOptions({ ...filterOptions, startDate: date, endDate: null })
    } else if (!filterOptions.endDate && date > filterOptions.startDate) {
      setFilterOptions({ ...filterOptions, endDate: date })
    } else {
      setFilterOptions({ ...filterOptions, startDate: date, endDate: null })
    }
  }

  const getDateStatus = (date: Date) => {
    const { startDate, endDate } = filterOptions
    if (startDate && date.getTime() === startDate.getTime()) {
      return 'start'
    }
    if (endDate && date.getTime() === endDate.getTime()) {
      return 'end'
    }
    if (startDate && endDate && date > startDate && date < endDate) {
      return 'inRange'
    }
    return null
  }

  const handleOptionClick = (option: string) => {
    setFilterOptions({ ...filterOptions, rangeTimeOption: option })
    const today = new Date()

    setMonthAndYear({
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    })

    if (option === '1-day') {
      const endDate = new Date(today.setHours(0, 0, 0, 0))
      endDate.setMilliseconds(-1)
      const startDate = new Date(endDate)
      if (startDate.getDate() === 1) {
        const previousMonth = new Date(startDate.setMonth(startDate.getMonth() - 1))
        setMonthAndYearBefore({
          month: previousMonth.getMonth() + 1,
          year: previousMonth.getFullYear(),
        })
      }
      startDate.setHours(0, 0, 0, 0)
      setFilterOptions({ ...filterOptions, startDate, endDate: endDate, rangeTimeOption: '1-day' })
    } else if (option === 'today') {
      const startDate = new Date(today.setHours(0, 0, 0, 0))
      const endDate = new Date(today.setHours(23, 59, 59, 999))
      setFilterOptions({ ...filterOptions, startDate: startDate, endDate: endDate, rangeTimeOption: 'today' })
    } else if (option === '1-month') {
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      const endDate = new Date(today.setHours(0, 0, 0, 0))
      setFilterOptions({ ...filterOptions, startDate: startDate, endDate: endDate, rangeTimeOption: '1-month' })
    } else if (option === '3-month') {
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      const endDate = new Date(today.setHours(0, 0, 0, 0))
      setFilterOptions({ ...filterOptions, startDate: startDate, endDate: endDate, rangeTimeOption: '3-month' })
    } else {
      const endDate = new Date(today.setHours(0, 0, 0, 0))
      const startDate = new Date(endDate)
      startDate.setDate(endDate.getDate() - 6)
      endDate.setDate(endDate.getDate())
      setFilterOptions({ ...filterOptions, startDate, endDate, rangeTimeOption: '1-week' })
    }
  }

  const handleChangeMonth = () => {
    const newDate = new Date(monthAndYear.year, monthAndYear.month - 1, 1)
    newDate.setMonth(newDate.getMonth() - 1)
    setMonthAndYear({
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    })
    const startDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1)
    const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0)
    setFilterOptions({ ...filterOptions, startDate: startDate, endDate: endDate, rangeTimeOption: '1-month-before' })
  }

  return (
    <div className='flex flex-col'>
      <div className='flex items-center justify-center gap-[7px]'>
        {['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'].map((item, index) => (
          <p
            key={index}
            className='flex h-11 w-11 items-center justify-center text-center text-sm font-normal leading-5 text-[#99A0AE]'
          >
            {item}
          </p>
        ))}
      </div>

      {(filterOptions.rangeTimeOption === '1-day' || filterOptions.rangeTimeOption === '1-week') && (
        <div className='grid w-full grid-cols-7 gap-y-2 transition-all duration-150'>
          {allDaysBefore.slice(-14).map((item, index) => {
            const dateStatus = getDateStatus(item)
            const isThisMonth = item.getMonth() + 1 === monthAndYear.month

            return (
              <div
                key={index}
                onClick={() => handleDateClick(item)}
                className={`relative flex aspect-square ${isThisMonth ? 'invisible' : 'visible'} cursor-pointer items-center justify-center transition-all duration-150 ${
                  dateStatus === 'start'
                    ? 'rounded-l-full bg-[#F5F7FA]'
                    : dateStatus === 'end'
                      ? 'rounded-r-full bg-[#F5F7FA]'
                      : dateStatus === 'inRange'
                        ? 'bg-[#F5F7FA]'
                        : ''
                } `}
              >
                <p
                  className={`flex ${
                    dateStatus === 'start' || dateStatus === 'end'
                      ? 'rounded-full bg-black text-white'
                      : dateStatus === 'inRange'
                        ? 'bg-[#F5F7FA]'
                        : 'text-[#0E121B]'
                  } h-full w-full items-center justify-center rounded-full text-sm font-medium`}
                >
                  {item.getDate()}
                </p>
              </div>
            )
          })}
        </div>
      )}

      <p className='py-2 text-sm font-semibold leading-5 text-[#0E121B]'>
        Tháng {monthAndYear.month} {monthAndYear.year}
      </p>

      <div className='grid w-full grid-cols-7 gap-y-2 transition-all duration-150'>
        {allDays.map((item, index) => {
          const dateStatus = getDateStatus(item)
          const isThisMonth = item.getMonth() + 1 === monthAndYear.month

          return (
            <div
              key={index}
              onClick={() => handleDateClick(item)}
              className={`relative flex aspect-square ${isThisMonth ? 'visible' : 'invisible'} cursor-pointer items-center justify-center transition-all duration-150 ${
                dateStatus === 'start'
                  ? 'rounded-l-full bg-[#F5F7FA]'
                  : dateStatus === 'end'
                    ? 'rounded-r-full bg-[#F5F7FA]'
                    : dateStatus === 'inRange'
                      ? 'bg-[#F5F7FA]'
                      : ''
              } `}
            >
              <p
                className={`flex ${
                  dateStatus === 'start' || dateStatus === 'end'
                    ? 'rounded-full bg-black text-white'
                    : dateStatus === 'inRange'
                      ? 'bg-[#F5F7FA]'
                      : 'text-[#0E121B]'
                } h-full w-full items-center justify-center rounded-full text-sm font-medium`}
              >
                {item.getDate()}
              </p>
            </div>
          )
        })}
      </div>

      {filterOptions.rangeTimeOption !== '1-month-before' && (
        <p className='py-2 text-sm font-semibold leading-5 text-[#0E121B]'>
          Tháng {monthAndYear.month + 1} {monthAndYear.year}
        </p>
      )}

      <div className='my-5 h-[1px] w-full bg-[#E1E4EA]' />

      <div className='flex items-center gap-2 overflow-x-scroll'>
        {TIME_FILTER.map((item) => (
          <button
            onClick={() =>
              item.value === '1-day' ||
              item.value === '1-week' ||
              item.value === 'today' ||
              item.value === '1-month' ||
              item.value === '3-month'
                ? handleOptionClick(item.value)
                : handleChangeMonth()
            }
            key={item.id}
            className={`min-w-fit rounded-full outline-none focus-visible:!outline-none ${item.value === filterOptions.rangeTimeOption ? 'pointer-events-none border-2 border-primary bg-primary/10 text-primary' : 'border border-soft-200'} px-3 py-2`}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Calendar
