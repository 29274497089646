export const IConBg = ({
  children,
  onClick,
  className,
}: {
  children: JSX.Element
  onClick?: () => void
  className?: string
}) => {
  return (
    <span
      onClick={() => onClick && onClick()}
      className={`${className} flex size-[42px] items-center justify-center rounded-full bg-primary-darker text-white hover:bg-primary-darker/60 active:opacity-active`}
    >
      {children}
    </span>
  )
}
