export const IEmpty = () => {
  return (
    <svg width='148' height='148' viewBox='0 0 148 148' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='148' height='148' rx='74' fill='#F6F8FA' />
      <path
        d='M111.6 65.2315V78.6983C111.6 74.4374 108.15 70.9945 103.897 70.9945H38.9622C35.393 70.9945 32.478 68.2136 32.2476 64.7112C32.5152 60.6957 35.8614 57.5278 39.9363 57.5278H103.896C108.15 57.5278 111.6 60.9707 111.6 65.2315Z'
        fill='#CDD0D5'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M67.5613 21.267L38.3248 58.0966C36.8629 59.9382 37.1707 62.6163 39.0123 64.0782L61.3624 81.8204C63.204 83.2823 65.8821 82.9745 67.344 81.1329L96.5805 44.3033C98.0424 42.4617 97.7346 39.7836 95.893 38.3216L73.5429 20.5795C71.7013 19.1175 69.0232 19.4253 67.5613 21.267Z'
        fill='#E2E4E9'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M75.7582 22.3377L41.2275 65.8364L47.1995 70.5772L81.7302 27.0784L75.7582 22.3377Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M90.0835 32.6743L50.0992 57.4219C48.0998 58.6594 47.4822 61.2834 48.7197 63.2828L63.7378 87.5473C64.9752 89.5467 67.5992 90.1644 69.5986 88.9269L109.583 64.1793C111.582 62.9418 112.2 60.3178 110.962 58.3184L95.9444 34.0539C94.7069 32.0545 92.0829 31.4369 90.0835 32.6743Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.6145 46.2637L87.1943 50.8563C86.3637 51.3704 86.1072 52.4604 86.6212 53.291L89.4321 57.8324C89.9461 58.663 91.0361 58.9195 91.8667 58.4055L99.2869 53.8129C100.117 53.2988 100.374 52.2088 99.86 51.3782L97.0492 46.8368C96.5351 46.0062 95.4451 45.7496 94.6145 46.2637Z'
        fill='#E2E4E9'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.6 78.6981V115.001C111.6 119.262 108.15 122.705 103.897 122.705H39.9366C35.6832 122.705 32.2329 119.262 32.2329 115.001V65.2312C32.2329 65.053 32.2403 64.882 32.2478 64.7109C32.4782 68.2133 35.3932 70.9942 38.9625 70.9942H103.897C108.15 70.9942 111.6 74.4372 111.6 78.6981Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M112.575 91.5698H97.4739C95.7107 91.5698 94.2812 92.9992 94.2812 94.7625V101.296C94.2812 103.06 95.7107 104.489 97.4739 104.489H112.575C114.338 104.489 115.768 103.06 115.768 101.296V94.7625C115.768 92.9992 114.338 91.5698 112.575 91.5698Z'
        fill='#E2E4E9'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.798 100.785C102.319 100.785 103.553 99.5514 103.553 98.0297C103.553 96.508 102.319 95.2744 100.798 95.2744C99.2761 95.2744 98.0425 96.508 98.0425 98.0297C98.0425 99.5514 99.2761 100.785 100.798 100.785Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M90.7639 67.8195V71.0048H80.3677V67.8195C80.3677 64.9487 82.695 62.625 85.5658 62.625C87.003 62.625 88.3017 63.205 89.2428 64.1462C90.1839 65.0837 90.7639 66.3822 90.7639 67.8195Z'
        fill='#CDD0D5'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M85.5659 62.625C82.6951 62.625 80.3678 64.9487 80.3678 67.8195V97.7667C80.3678 101.269 77.5298 104.107 74.0243 104.107C70.5223 104.107 67.6807 101.269 67.6807 97.7667C67.6807 94.812 69.7016 92.3279 72.4374 91.6239L47.1109 96.8438L47.0889 96.8475L46.881 96.884C45.2322 97.2341 43.9956 98.7005 43.9956 100.455V67.8195C43.9956 64.9487 46.3229 62.625 49.1937 62.625H85.5659Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M74.0242 104.107H47.647C45.6298 104.107 43.9956 102.472 43.9956 100.455C43.9956 98.7006 45.2322 97.2342 46.881 96.8841L47.0889 96.8476L47.1109 96.8439L72.4374 91.624C69.7015 92.328 67.6807 94.8121 67.6807 97.7668C67.6807 101.269 70.5223 104.107 74.0242 104.107Z'
        fill='white'
        stroke='#CDD0D5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M74.0242 104.107H47.647C45.6298 104.107 43.9956 102.472 43.9956 100.455C43.9956 98.7006 45.2322 97.2342 46.881 96.8841L47.0889 96.8476L47.1109 96.8439L72.4374 91.624C69.7015 92.328 67.6807 94.8121 67.6807 97.7668C67.6807 101.269 70.5223 104.107 74.0242 104.107Z'
        fill='#CDD0D5'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M52.6611 74.8413L57.5408 79.7154L52.6611 74.8413Z' fill='white' />
      <path d='M52.6611 74.8413L57.5408 79.7154' stroke='#868C98' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M57.5386 74.8384L52.6646 79.7181L57.5386 74.8384Z' fill='white' />
      <path d='M57.5386 74.8384L52.6646 79.7181' stroke='#868C98' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M67.5591 74.8325L72.4388 79.7065L67.5591 74.8325Z' fill='white' />
      <path d='M67.5591 74.8325L72.4388 79.7065' stroke='#868C98' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M72.4361 74.8296L67.562 79.7093L72.4361 74.8296Z' fill='white' />
      <path d='M72.4361 74.8296L67.562 79.7093' stroke='#868C98' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M55.709 84.73L68.3538 84.7227L55.709 84.73Z' fill='white' />
      <path d='M55.709 84.73L68.3538 84.7227' stroke='#868C98' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M25.5718 43.134C25.5718 43.134 19.5645 41.3444 17.7606 47.3252C19.5644 41.3445 13.5693 39.514 13.5693 39.514C13.5693 39.514 19.5768 41.3035 21.3806 35.3228C19.5768 41.3035 25.5718 43.134 25.5718 43.134Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M135.607 79.4649C135.607 79.4649 130.476 85.4838 136.457 90.617C130.476 85.4836 125.305 91.4673 125.305 91.4673C125.305 91.4673 130.435 85.4485 124.455 80.3151C130.435 85.4485 135.607 79.4649 135.607 79.4649Z'
        fill='white'
        stroke='#868C98'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
