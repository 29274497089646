import { PORTAL } from '@src/constants/key.constants'
import { TPaymentMethod } from '@src/types/transaction'

import axiosClient from './axiosClient'

export type TReportList = {
  start_time: number
  end_time: number
  customer_id?: number
  page: number
  status: 'all' | 'success' | 'pending' | 'fail'
  bill_id?: number
}

export const reportService = {
  getTxn: async (merchant_id: number, transaction_type: TPaymentMethod | 'all', params?: string) => {
    return transaction_type === 'all'
      ? (
          await axiosClient.post(`/metabase/all-trans?${params ?? ''}`, {
            service: 'portal',
            merchant_id: merchant_id,
          })
        ).data
      : (await axiosClient.get(`${PORTAL}/transaction/${transaction_type}?${params ?? ''}`)).data
  },

  getDashboard: async (date_filter: string) => {
    return (await axiosClient.get(`${PORTAL}/dashboard-data${date_filter ? '?date_filter=' + date_filter : ''}`)) as any
  },
}
