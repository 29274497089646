export const CloseIcon = () => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width={31} height={31} rx='15.5' fill='white' />
    <rect x='0.5' y='0.5' width={31} height={31} rx='15.5' stroke='#EAEAEA' />
    <path
      d='M13.0355 13.1068L18.9645 19.0357M12.9644 19.1068L19.0355 13.0357'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ArrowDownIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} viewBox='0 0 20 20' fill='none'>
    <path
      d='M14 8L10.7071 11.6745C10.3182 12.1085 9.68182 12.1085 9.29293 11.6745L6 8'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const SearchIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <g clipPath='url(#clip0_76_8711)'>
      <circle cx='9.58341' cy='9.58317' r='7.91667' stroke='currentColor' strokeWidth='1.5' />
      <path d='M15.4167 15.4165L18.3334 18.3332' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    </g>
    <defs>
      <clipPath id='clip0_76_8711'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export const PlusIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z'
      fill='currentColor'
    />
    <path
      d='M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z'
      fill='currentColor'
    />
  </svg>
)
