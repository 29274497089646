import React, { useRef } from 'react'
import { IArrowDownSmall } from '@src/constants/icons/transaction.icon'

const Accordion = ({
  children,
  isLoading,
  title,
  className,
}: {
  isLoading?: boolean
  children: React.ReactNode
  title?: string
  className?: string
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  return (
    <div
      ref={ref}
      data-collapse={false}
      className={`${className} group w-full overflow-hidden rounded-2xl bg-white transition-all duration-300`}
      onClick={() => {
        if (ref.current) {
          ref.current.getAttribute('data-collapse') == 'false'
            ? ref.current.setAttribute('data-collapse', 'true')
            : ref.current.setAttribute('data-collapse', 'false')
        }
      }}
    >
      <div className='flex w-full items-center gap-4 px-3 py-2'>
        {isLoading ? (
          <>
            <p className='h-6 flex-1 animate-pulse rounded bg-soft-200'></p>
            <div className='size-6 animate-pulse rounded-md bg-soft-200'></div>
          </>
        ) : (
          <>
            <p className='flex-1 text-base font-semibold text-strong-950'>{title}</p>
            <button tabIndex={-1} className='flex size-6 items-center justify-center rounded-md border border-soft-200'>
              <span className='rotate-0 transition-all duration-300 group-data-[collapse=false]:rotate-180'>
                <IArrowDownSmall />
              </span>
            </button>
          </>
        )}
      </div>

      <div
        className='max-h-[10000px] overflow-hidden transition-all duration-300 group-data-[collapse=true]:max-h-0'
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
