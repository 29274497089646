import { useMemo } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { SERVICE, UTILITIES } from '@src/constants/key.constants'

function getTypeFeature(type: SERVICE | UTILITIES, navigate: NavigateFunction) {
  return {
    [UTILITIES.QR_CODE]: () => {
      navigate('/?open=merchant_qr')
    },
    [UTILITIES.REPORT]: () => {
      console.log('report')
    },
    [UTILITIES.PREFERENTIAL]: () => {
      console.log('preferential')
    },
    [UTILITIES.INSTRUCTIONS]: () => {
      console.log('instructions')
    },
    [SERVICE.CINEMA]: () => {},
    [SERVICE.PLANE]: () => {},
    [SERVICE.TRAIN]: () => {},
    [SERVICE.MOBILE]: () => {},
  }[type]
}

const useCarouselSection = () => {
  const navigate = useNavigate()

  return useMemo(() => ({ getTypeFeature: (type: SERVICE | UTILITIES) => getTypeFeature(type, navigate) }), [navigate])
}

export default useCarouselSection
