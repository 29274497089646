import { useMemo, useRef, useState } from 'react'
import { IArrowRight } from '@src/constants/icons/reward.icon'
import { IArrowDownSmall } from '@src/constants/icons/transaction.icon'
import { getThemeBank } from '@src/utils/theme-color'

import BlueNoteCard from './blue-note-card'

const BlueNote = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isBlock, setIsBlock] = useState(false)
  const [isOutdated, setIsOutdated] = useState(false)
  return (
    <div
      ref={ref}
      data-collapse={false}
      data-block={isBlock}
      data-outdated={isOutdated}
      className='group w-full overflow-hidden transition-all duration-300'
      onClick={() => {
        if (ref.current) {
          ref.current.getAttribute('data-collapse') == 'false'
            ? ref.current.setAttribute('data-collapse', 'true')
            : ref.current.setAttribute('data-collapse', 'false')
        }
      }}
    >
      <div className='flex w-full items-center px-4 pt-4'>
        <p className='flex-1 text-base font-semibold text-strong-950'>Bluenote</p>

        <button tabIndex={-1} className='flex size-6 items-center justify-center rounded-md border border-soft-200'>
          <span className='rotate-0 transition-all duration-300 group-data-[collapse=false]:rotate-180'>
            <IArrowDownSmall />
          </span>
        </button>
      </div>

      <div
        className='max-h-[10000px] overflow-hidden transition-all duration-300 group-data-[collapse=true]:max-h-0'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='w-full px-4 pt-2'>
          <div
            className='relative'
            onClick={() => {
              setIsBlock(!isBlock)
            }}
          >
            <BlueNoteCard isBlock={isBlock} />
            <BlueNoteCardContent isBlock={isBlock} />
          </div>
          <div className='relative px-4'>
            <div className='flex w-full items-center justify-center gap-[10px] rounded-b-2xl bg-white px-8 py-4 group-data-[outdated=true]:px-4'>
              <div className='flex-1 group-data-[outdated=false]:hidden'>
                <p className='flex items-center gap-1 text-sm font-semibold text-error-base'>
                  <IWarning /> <span>3.324.500 điểm</span>
                </p>
                <p className='mt-0.5 text-xs font-medium text-sub-600'>Hết hạn vào ngày 20/10/2024</p>
              </div>
              {/*  */}
              <button
                className={`w-full rounded-[10px] bg-primary px-[18.5px] py-3 text-center text-base font-medium transition-all active:opacity-active group-data-[block=true]:pointer-events-none group-data-[outdated=true]:w-fit group-data-[block=true]:bg-soft-200 ${getThemeBank() === 'ocb' ? 'text-white' : 'text-secondary'}`}
                onClick={() => setIsOutdated(!isOutdated)}
              >
                <span className='group-data-[block=true]:text-disable-300'>
                  Sử dụng
                  {isOutdated ? '' : ' điểm'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlueNote

function BlueNoteCardContent({ isBlock }: { isBlock?: boolean }) {
  const bankTheme = getThemeBank()
  const color = useMemo(() => {
    if (bankTheme === 'ocb') {
      return 'linear-gradient(90deg, #085F3B 0%, #AA5413 100%)'
    }
    return 'linear-gradient(90deg, #1A1F56 0%, #009FCB 100%)'
  }, [bankTheme])
  return (
    <div className='absolute inset-0 p-6'>
      <div className='flex size-full flex-col gap-4'>
        <div className='flex flex-1 flex-col justify-center'>
          {/*  */}
          <div className='flex items-center justify-between'>
            <p className='text-base font-semibold text-strong-950'>Bluenote</p>
            <span className='flex size-8 items-center justify-center rounded-full border border-primary bg-white text-primary'>
              <IArrowRight />
            </span>
          </div>
          <button className='mt-1 w-fit rounded-md bg-primary px-3 py-1 text-xs font-semibold text-white transition-all group-data-[block=true]:bg-faded-base'>
            {isBlock ? 'Khóa' : 'Hoạt động'}
          </button>
        </div>
        <div className='flex flex-1 flex-col justify-center'>
          <p className='text-sm font-normal text-primary group-data-[block=true]:text-soft-400'>Số điểm khả dụng</p>
          <p
            className={`mt-1 text-2xl font-bold transition-all group-data-[block=true]:text-soft-400`}
            style={
              isBlock
                ? {}
                : {
                    background: color,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }
            }
          >
            10.000.000 điểm
          </p>
        </div>
      </div>
    </div>
  )
}

function IWarning() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6666 8.00016C14.6666 4.31826 11.6819 1.3335 7.99998 1.3335C4.31808 1.3335 1.33331 4.31826 1.33331 8.00016C1.33331 11.6821 4.31808 14.6668 7.99998 14.6668C11.6819 14.6668 14.6666 11.6821 14.6666 8.00016ZM7.99998 4.16683C8.27612 4.16683 8.49998 4.39069 8.49998 4.66683V8.66683C8.49998 8.94297 8.27612 9.16683 7.99998 9.16683C7.72384 9.16683 7.49998 8.94297 7.49998 8.66683V4.66683C7.49998 4.39069 7.72384 4.16683 7.99998 4.16683ZM7.99998 11.3335C8.36817 11.3335 8.66665 11.035 8.66665 10.6668C8.66665 10.2986 8.36817 10.0002 7.99998 10.0002C7.63179 10.0002 7.33331 10.2986 7.33331 10.6668C7.33331 11.035 7.63179 11.3335 7.99998 11.3335Z'
        fill='currentColor'
      />
    </svg>
  )
}
