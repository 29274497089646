import { useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TBank, theme_bank } from '@src/constants/bank.constants'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { setShowIntro } from '@src/utils/local-storage'
import { getThemeBank, setThemeBank } from '@src/utils/theme-color'

import ModalBottom from '../modal/modal-bottom'

const SettingTheme = () => {
  const bankTheme = getThemeBank()
  const [bank, setBank] = useState<TBank>(bankTheme ?? theme_bank.bvb.id)
  const navigate = useNavigate()
  return (
    <React.Suspense>
      <div className='size-full'>
        <ModalBottom isOpen onClose={() => {}}>
          <div className='flex flex-col gap-3 px-5 pt-2'>
            {Object.keys(theme_bank).map((key) => {
              const item = theme_bank[key as TBank]
              const isActive = bank === item.id
              return (
                <div
                  key={item.id}
                  className={`overflow-hidden rounded-2xl border transition-all active:opacity-active ${isActive ? 'pointer-events-none' : 'border-opacity-0'}`}
                  onClick={() => {
                    setBank(item.id)
                  }}
                  style={
                    isActive
                      ? {
                          borderColor: item.primary,
                          backgroundColor: item.primary + '10',
                        }
                      : {
                          borderColor: 'transparent',
                          backgroundColor: 'white',
                        }
                  }
                >
                  <div className='flex items-center gap-3 px-3 py-2'>
                    <div
                      className='flex size-11 items-center justify-center rounded-full text-[50%] font-bold text-white'
                      style={{
                        backgroundColor: item.primary,
                      }}
                    >
                      <span>{item.title}</span>
                    </div>

                    <div className='flex-1'>
                      <p className='text-base font-semibold' style={{ color: item.primary }}>
                        {item.title}
                      </p>
                      <p className='mt-0.5 text-sm text-sub-600'>{item.title}</p>
                    </div>

                    <div
                      className='size-5 rounded-full p-1.5'
                      style={{
                        backgroundColor: !isActive ? item.primary + '20' : item.primary,
                      }}
                    >
                      {isActive && (
                        <span
                          className='block size-full rounded-full'
                          style={{
                            backgroundColor: 'white',
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}

            <button
              className='my-2 w-full rounded-xl border border-soft-200 bg-white py-3 text-center text-sm font-semibold text-black shadow-sm outline-none'
              onClick={() => {
                setThemeBank(bank)
                setShowIntro(true)
                navigate(ROUTES.home)
              }}
            >
              Chọn
            </button>
          </div>
        </ModalBottom>
      </div>
    </React.Suspense>
  )
}

export default SettingTheme
