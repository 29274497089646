import axiosClient from './axiosClient'

export const invoiceService = {
  getTables: async () => {
    return await axiosClient.get(`/fnb/table`, {
      baseURL: 'https://bvbank-stag.atompos.vn/v1',
    })
  },
  updateOrder: async (params: any) => {
    return await axiosClient.put(`/order/product`, params, {
      baseURL: 'https://bvbank-stag.atompos.vn/v1',
    })
  },
  createOrder: async (params: any) => {
    return await axiosClient.post(`/order`, params, {
      baseURL: 'https://bvbank-stag.atompos.vn/v1',
    })
  },
  getInvoice: async (order_id: number) => {
    return await axiosClient.get(`/invoice/${order_id}?is_orderid=true`, {
      baseURL: 'https://bvbank-stag.atompos.vn/v1',
    })
  },
  getQRPayment: async (invoice_id: number) => {
    return await axiosClient.get(`/payment/qr/${invoice_id}`, {
      baseURL: 'https://bvbank-stag.atompos.vn/v1',
    })
  },
}
