import { useEffect, useRef, useState } from 'react'
import { wait } from '@src/utils/common'
import { motion } from 'framer-motion'

type SwipeControlProps<T extends { id: string }> = {
  options: T[]
  value?: T
  onChange: (value: T) => void
  swipeAreaContainerId: string
  renderItem?: (item: T) => React.ReactNode
}

const SwipeControl = <T extends { id: string }>({
  options,
  value,
  onChange,
  swipeAreaContainerId,
  renderItem,
}: SwipeControlProps<T>) => {
  const container = useRef<HTMLDivElement | null>(null)

  return (
    <div ref={container} className='relative w-full overflow-x-auto overflow-y-hidden border-b border-soft-200'>
      <div className='flex flex-nowrap gap-2'>
        {options.map((item, index) => {
          const isActive = value?.id === item.id
          return (
            <button
              key={index}
              className={`min-w-fit px-3 py-2 text-center text-sm font-semibold transition-all first:!pl-7 last:pr-7 ${isActive ? 'text-primary' : 'text-soft-400'}`}
              onClick={async () => {
                const container = document.getElementById(swipeAreaContainerId + item.id)
                if (container) {
                  const parent = container.parentElement
                  if (parent) {
                    parent.setAttribute('data-inview', 'false')
                    const rect = container.getBoundingClientRect()
                    const res = rect.left - parent.getBoundingClientRect().left + parent.scrollLeft
                    parent.scrollTo({
                      left: res,
                      behavior: 'smooth',
                    })
                    await wait(50)
                    onChange(item)
                  }
                }
              }}
            >
              <span id={'search-control-' + item.id}>{renderItem ? renderItem(item) : item.id}</span>
            </button>
          )
        })}
      </div>
      <NavigateSign container={container} value={value} />
    </div>
  )
}

export default SwipeControl

const NavigateSign = <T extends { id: string }>({
  container,
  value,
}: {
  value?: T
  container: React.MutableRefObject<HTMLDivElement | null>
}) => {
  const [left, setLeft] = useState(-36)
  useEffect(() => {
    let move = -36
    if (value && container.current && container.current.parentElement) {
      const controlItem = document.getElementById('search-control-' + value.id)
      const parent = container.current.parentElement
      parent.style.overflowX = 'auto'
      if (controlItem) {
        const rect = controlItem.getBoundingClientRect()
        const res =
          rect.left + rect.width / 2 - parent.getBoundingClientRect().left + container.current.scrollLeft - 36 / 2

        container.current.scrollTo({
          left: res - container.current.offsetWidth / 2,
          behavior: 'smooth',
        })
        move = res
      }
    }
    setLeft(move)
  }, [value, container.current])

  return (
    <motion.div
      animate={{ left: `${left}px` }}
      initial={{ left: `-36px` }}
      className='pointer-events-none absolute bottom-0 h-0.5 w-[36px] rounded-full bg-primary'
    ></motion.div>
  )
}
