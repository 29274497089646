import React from 'react'
import { ICancelDelete, IConnectingCheck, ILockDevice, IPositionDevice } from '@src/constants/icons/device.icons'
import IconWrapper from '@src/constants/icons/icon-wrapper'

interface QuickActionData {
  Icon: React.FC
  title: string
}

const quickActionsData: QuickActionData[] = [
  { Icon: IPositionDevice, title: 'Định vị<br />thiết bị' },
  { Icon: IConnectingCheck, title: 'Kiểm tra <br />kết nối' },
  { Icon: ILockDevice, title: 'Khoá<br />thiết bị' },
  { Icon: ICancelDelete, title: 'Huỷ<br />đăng ký' },
]

const QuickActionItem = ({ Icon, title }: QuickActionData) => (
  <div className='flex flex-1 flex-col items-center px-1.5'>
    <IconWrapper size={48} rounded='16px' className='cursor-pointer'>
      <Icon />
    </IconWrapper>
    <p className='mt-2 text-center text-sm font-medium leading-5 tracking-normal text-neutral-900'>
      {title.split('<br />').map((line, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />}
          {line}
        </React.Fragment>
      ))}
    </p>
  </div>
)

const QuickActions = () => (
  <section className='flex items-start border-t-[0.5px] border-soft-200 px-4 pt-4'>
    {quickActionsData.map((action, index) => (
      <QuickActionItem key={index} {...action} />
    ))}
  </section>
)

export default QuickActions
