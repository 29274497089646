import { TModule } from '@src/types/modules'

import { IBook, IDocument, IPlane, ITelephoneCard, ITicket, ITrain, IVoucher } from './icons/modules.icon'
import { IScan } from './icons/transaction.icon'
import { MERCHANT_QR, SERVICE, UTILITIES } from './key.constants'

export const ex_modules: TModule[] = [
  {
    id: SERVICE.CINEMA,
    icon: <ITicket />,
    title: 'Đặt vé xem phim ',
  },
  {
    id: SERVICE.PLANE,
    icon: <IPlane />,
    title: 'Đặt vé máy bay',
  },
  {
    id: SERVICE.TRAIN,
    icon: <ITrain />,
    title: 'Đặt vé tàu hỏa',
  },
  {
    id: SERVICE.MOBILE,
    icon: <ITelephoneCard />,
    title: 'Nạp tiền điện thoại',
  },
  {
    id: SERVICE.CINEMA,
    icon: <ITicket />,
    title: 'Đặt vé xem phim ',
  },
  {
    id: SERVICE.PLANE,
    icon: <IPlane />,
    title: 'Đặt vé máy bay',
  },
  {
    id: SERVICE.TRAIN,
    icon: <ITrain />,
    title: 'Đặt vé tàu hỏa',
  },
  {
    id: SERVICE.MOBILE,
    icon: <ITelephoneCard />,
    title: 'Nạp tiền điện thoại',
  },
]

export const ex_feature: TModule[] = [
  {
    id: UTILITIES.QR_CODE,
    icon: <IScan />,
    title: 'Mã QR',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.REPORT,
    icon: <IDocument />,
    title: 'Báo cáo',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.PREFERENTIAL,
    icon: <IVoucher />,
    title: 'Ưu đãi',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.INSTRUCTIONS,
    icon: <IBook />,
    title: 'Hướng dẫn',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.QR_CODE,
    icon: <IScan />,
    title: 'Mã QR',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.REPORT,
    icon: <IDocument />,
    title: 'Báo cáo',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.PREFERENTIAL,
    icon: <IVoucher />,
    title: 'Ưu đãi',
    open: MERCHANT_QR,
  },
  {
    id: UTILITIES.INSTRUCTIONS,
    icon: <IBook />,
    title: 'Hướng dẫn',
    open: MERCHANT_QR,
  },
]
