export function formatTitleTransaction(date: string) {
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)

  if (date === yesterday.toISOString().split('T')[0]) {
    return 'Hôm qua'
  } else if (date === today.toISOString().split('T')[0]) {
    return 'Hôm nay'
  } else {
    const d = new Date(date)
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const day = d.getDate().toString().padStart(2, '0')
    const year = d.getFullYear()
    return `${day}-${month}-${year}`
  }
}

export function compareDateByDDMMYYYY(date1: Date, date2: Date) {
  console.log('>>>>Date 1: ', date1)
  console.log('>>>>Date 2: ', date2)
  const a = new Date(date1.toISOString().split('T')[0])
  const b = new Date(date2.toISOString().split('T')[0])
  if (a > b) {
    return 1
  }

  if (a < b) {
    return -1
  }

  return 0
}

export function isDateInRange(date: string | Date, startDate: Date | null, endDate: Date | null): boolean {
  const targetDate = new Date(date)
  targetDate.setHours(0, 0, 0, 0)

  const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null
  const end = endDate ? new Date(endDate).setHours(0, 0, 0, 0) : null

  if (start !== null && end !== null) {
    return targetDate.getTime() >= start && targetDate.getTime() <= end
  }

  if (start !== null && end === null) {
    return targetDate.getTime() >= start
  }

  if (start === null && end !== null) {
    return targetDate.getTime() <= end
  }

  return true
}
