export interface ModalProps {
  children?: React.ReactNode
  isOpen: boolean
  onClose: () => void
  className?: string
  classNameIosPb?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  disableChangeColorTheme?: boolean
}

export const ModalTransition = {
  transition: { duration: 0.2 },
}
