export const OPEN = 'open'
export const REPORT_PAYMENT = 'report_payment'
export const REPORT_FILTER = 'report_filter'
export const REPORT_RESULT = 'report_result'
export const TRANSACTION = 'transaction'
export const TIME_FILTER = 'time_filter'
export const BANK = 'theme-bank'

export const PORTAL = 'portal'
export const CUSTOMER_ID = 'customer_id'
export const START_TIME = 'start_time'
export const END_TIME = 'end_time'
export const METHOD = 'method'

export const MANAGEMENT_DEVICE = 'management_device'
export const MERCHANT_QR = 'merchant_qr'
export const DEVICE_DETAIL = 'device_detail'
export const CHAT_BOT = 'chatbot'
export const ADD_DEVICE = 'add_device'

export enum SERVICE {
  CINEMA = 'CINEMA',
  PLANE = 'PLANE',
  TRAIN = 'TRAIN',
  MOBILE = 'MOBILE',
}

export enum UTILITIES {
  QR_CODE = 'QR_CODE',
  REPORT = 'REPORT',
  PREFERENTIAL = 'PREFERENTIAL',
  INSTRUCTIONS = 'INSTRUCTIONS',
}
