import { useState } from 'react'
import { IEye, IHide } from '@src/constants/icons/icon-sign-in'
export type TAccount = {
  email: string
  password: string
}
export const InputField = ({
  title,
  required,
  placeholder,
  isPassword,
  register,
  registerValue,
  sign_in,
  clearButton,
  errorsMessage,
  className,
  icon,
  showError,
}: {
  title: string
  required?: boolean
  placeholder: string
  isPassword?: boolean
  register: any
  registerValue: string
  sign_in: () => void
  clearButton?: JSX.Element
  errorsMessage?: string
  className?: string
  icon?: JSX.Element
  showError?: boolean
}) => {
  const [showPassword, setShowPassword] = useState(!isPassword)
  return (
    <div>
      <p className='mb-1.5 text-sm font-semibold text-strong-950'>
        {title} {required && <span className='text-error-base'>*</span>}
      </p>
      <div
        className={`${showError ? 'border-error-base' : 'border-soft-200'} flex items-center gap-2 rounded-[10px] border p-3`}
      >
        {icon}
        <div className='flex flex-1 items-center justify-between'>
          <input
            autoComplete='off'
            type={showPassword ? 'text' : 'password'}
            {...register(registerValue)}
            className='w-full bg-transparent text-sm font-normal text-[#2C2C2C] outline-none'
            placeholder={placeholder}
            required
            onKeyDown={(e) => {
              if (e.key === 'Enter') sign_in()
            }}
          />
          {isPassword && (
            <button type='button' onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <IEye /> : <IHide />}
            </button>
          )}
          {clearButton}
        </div>
      </div>
      <div className='mt-1 text-sm font-medium text-red-400'>{errorsMessage}</div>
    </div>
  )
}
