import './styles/refreshing-content.css'

const RefreshingContent = ({ className }: { className?: string }) => {
  return (
    <div className={`lds-ellipsis ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default RefreshingContent
