import { useNavigate } from 'react-router-dom'
import { IConBg } from '@src/constants/icons/icon-bg'
import { IHome } from '@src/constants/icons/report.icon'
import { IArrow, IArrowUp } from '@src/constants/icons/transaction.icon'
import { CUSTOMER_ID, END_TIME, METHOD, REPORT_PAYMENT, REPORT_RESULT, START_TIME } from '@src/constants/key.constants'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { useQueryParams } from '@src/hooks/use-query-params'
import { useShopList } from '@src/hooks/use-shop-list'
import { reportService } from '@src/store/services/report.service'
import { TPaymentMethod } from '@src/types/transaction'
import { wait } from '@src/utils/common'
import {
  convertTimestampToDateString,
  formatCurrency,
  formatParams,
  getTitlePaymentMethod,
} from '@src/utils/transaction'
import { useQuery } from '@tanstack/react-query'

import { CustomButton } from '../common/custom-button'
import { LayoutHeader } from '../common/layout-header'
import ModalDirection from '../modal/modal-direction'

export const ResultReport = () => {
  const { getParams, setParams, deleteParams } = useQueryParams()
  const navigate = useNavigate()
  const method = (getParams(METHOD) as TPaymentMethod | 'all') ?? ''
  const isOpen = getParams(REPORT_PAYMENT) === REPORT_RESULT
  const start_date = getParams(START_TIME)
  const end_date = getParams(END_TIME)
  const store_id = Number(getParams(CUSTOMER_ID))

  const { data: stores } = useShopList()
  console.log(
    'stores',
    stores.find((store) => store.source_id === store_id)
  )
  const { data: reportResult, isLoading } = useQuery({
    queryKey: ['report-result'],
    queryFn: () =>
      reportService.getTxn(
        store_id,
        method,
        formatParams({
          customer_id: store_id,
          start_time: start_date,
          end_time: end_date,
          page: 1,
        })
      ),
    enabled: !!method,
  })

  console.log('reportResult', reportResult)

  const handleClose = () => {
    try {
      navigate(-1)
    } catch (error) {
      setParams({ [REPORT_PAYMENT]: method, [CUSTOMER_ID]: '', [METHOD]: '', [START_TIME]: '', [END_TIME]: '' })
    }
  }

  return (
    <ModalDirection
      isOpen={isOpen}
      onClose={() => handleClose()}
      header={
        <LayoutHeader
          onBack={() => handleClose()}
          title={'Báo cáo'}
          rightItem={
            <IConBg
              className='!bg-inherit !text-strong-950'
              onClick={async () => {
                deleteParams(REPORT_PAYMENT)
                await wait(400)
                navigate(ROUTES.home)
              }}
            >
              <IHome />
            </IConBg>
          }
        />
      }
    >
      <div className='flex flex-col gap-4 p-4'>
        <p className='text-2xl font-semibold tracking-[-0.48px] text-strong-950'>
          Báo cáo chi tiết giao dịch
          <br /> thanh toán
        </p>
        <div>
          <p className='text-base font-semibold leading-6 tracking-[-0.16px] text-strong-950'>Nội dung báo cáo:</p>
          <div className='mt-2 flex flex-col gap-3 rounded-xl border border-soft-200 p-4'>
            <div className='flex items-center justify-between'>
              <span className='text-sm leading-5 tracking-[-0.21px] text-neutral-300'>Loại giao dịch</span>
              <span className='text-sm font-semibold leading-5 tracking-[-0.14px] text-strong-950'>
                {getTitlePaymentMethod(method, 'GD ')}
              </span>
            </div>
            <div className='border-b border-dashed border-soft-200'></div>
            <div className='flex items-center justify-between'>
              <span className='text-sm leading-5 tracking-[-0.21px] text-neutral-300'>Trạng thái giao dịch</span>
              <button className='flex h-7 items-center rounded-full bg-success-base/10 px-2 text-success-base'>
                <span className='text-sm font-medium leading-5 tracking-[-0.14px]'>Thành công</span>
              </button>
            </div>
            <div className='border-b border-dashed border-soft-200'></div>
            <div className='flex items-center justify-between'>
              <span className='text-sm leading-5 tracking-[-0.21px] text-neutral-300'>Tại chi nhánh</span>
              <span className='text-sm font-semibold leading-5 tracking-[-0.14px] text-strong-950'>
                {stores.find((store) => store.source_id === store_id)?.name}
              </span>
            </div>
            <div className='border-b border-dashed border-soft-200'></div>
            <div className='flex items-center justify-between'>
              <span className='text-sm leading-5 tracking-[-0.21px] text-neutral-300'>Thời gian</span>
              <span className='text-sm font-semibold leading-5 tracking-[-0.14px] text-strong-950'>
                {start_date && convertTimestampToDateString(Number(start_date))} -{' '}
                {end_date && convertTimestampToDateString(Number(end_date))}
              </span>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-6 rounded-2xl border border-soft-200 p-4'>
          <div>
            <div className='flex items-center justify-between'>
              <p className='text-sm font-medium leading-5 tracking-[-0.14px] text-sub-600'>Tổng số lượng giao dịch</p>
              <div className='flex gap-2'>
                <button>
                  <IArrow />
                </button>
                <button>
                  <IArrowUp />
                </button>
              </div>
            </div>
            {isLoading ? (
              <div className='mt-1 h-8 w-[70%] animate-pulse rounded-full bg-[linear-gradient(90deg,#EBFAEC_0%,#FEFEFE_100%)]'></div>
            ) : (
              <p className='mt-1 text-2xl font-bold tracking-[-0.24px] text-primary'>
                {reportResult?.data?.length ?? 0}
              </p>
            )}
          </div>
          <div>
            <p className='text-sm font-medium leading-5 tracking-[-0.14px] text-sub-600'>
              Tổng số tiền giao dịch trước KM (VND)
            </p>
            {isLoading ? (
              <div className='mt-1 h-8 w-full animate-pulse rounded-full bg-[linear-gradient(90deg,#EBFAEC_0%,#FEFEFE_100%)]'></div>
            ) : (
              <p className='mt-1 text-2xl font-bold tracking-[-0.24px] text-primary'>
                {formatCurrency(reportResult?.total_amount ?? 0) ?? '0'} đ{}
              </p>
            )}
          </div>
        </div>
        <CustomButton
          onClick={() => {}}
          text={'Xuất báo cáo chi tiết'}
          className={'!rounded-[10px] !bg-primary text-white'}
        />
      </div>
    </ModalDirection>
  )
}
