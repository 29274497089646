import { useState } from 'react'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import { IConnect, IPosition } from '@src/constants/icons/management-device.icon'
import { TDevice } from '@src/types/device'

import ModalBottom from '../modal/modal-bottom'

const QuickActionModal = ({ device, onClose }: { device?: TDevice; onClose: () => void }) => {
  const [isLockDevice, setIsLockDevice] = useState(false)

  return (
    <ModalBottom isOpen={!!device?.id} onClose={() => onClose()} className='bg-white px-4'>
      <div className='flex flex-col items-center gap-1 border-b border-soft-200 py-4'>
        <p className='text-[18px] font-semibold tracking-[-0.36px] text-strong-950'>{device?.device_name}</p>
        <p className='text-sm leading-5 tracking-[-0.21px] text-soft-400'>{device?.store_name}</p>
        <button
          className={`mt-1 flex h-6 items-center rounded-lg px-3 ${device?.status === 'active' ? 'bg-primary' : device?.status === 'activation' ? 'bg-secondary' : 'bg-faded-base'}`}
        >
          <span className='text-xs font-semibold leading-4 tracking-[-0.12px] text-white'>
            {device?.status === 'active' ? 'Hoạt động' : device?.status === 'activation' ? 'Đợi kích hoạt' : 'Khóa'}
          </span>
        </button>
      </div>
      <div className='flex flex-col gap-2 py-4'>
        {[
          { icon: <IPosition />, title: 'Định vị thiết bị', onClick: () => {} },
          { icon: <IConnect />, title: 'Kiểm tra kêt nối', onClick: () => {} },
        ].map((item, index) => (
          <button
            className='flex h-11 cursor-pointer items-center gap-3 disabled:text-disable-300'
            key={index}
            disabled={device?.status === 'block'}
          >
            <IconWrapper
              rounded='full'
              size={34}
              className='!text-disable-300 [--secondary-color:theme(colors.disabled)]'
            >
              {item.icon}
            </IconWrapper>
            <p className='text-sm font-medium leading-5 tracking-[-0.14px]'>{item.title}</p>
          </button>
        ))}
        <div className='flex w-full justify-between gap-3 rounded-2xl bg-weak-50 p-4'>
          <div>
            <p className='text-base font-semibold leading-6 tracking-[-0.16px] text-strong-950'>
              Khoá/mở khoá thiết bị
            </p>
            <p className='mt-[2px] text-sm font-medium leading-5 tracking-[-0.14px] text-sub-600'>
              Thiết bị đang hoạt động
            </p>
          </div>
          <button
            className={`relative h-[30px] w-[52px] rounded-full ${isLockDevice ? 'bg-primary' : 'bg-soft-400'} p-1`}
            onClick={() => setIsLockDevice(!isLockDevice)}
          >
            <div
              className={`h-full w-[50%] rounded-full bg-white ${isLockDevice ? 'translate-x-[100%]' : ''} transition-all duration-300`}
            ></div>
          </button>
        </div>
      </div>
      <button className='mt-4 flex h-12 w-full items-center justify-center rounded-full bg-error-base/10 px-[10px]'>
        <span className='text-base font-medium leading-6 tracking-[-0.32px] text-error-base'>Huỷ đăng ký thiết bị</span>
      </button>
    </ModalBottom>
  )
}

export default QuickActionModal
