export const IArrowNext = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M8 6L11.6745 9.29293C12.1085 9.68182 12.1085 10.3182 11.6745 10.7071L8 14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const IService = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M11.17 1.3335H4.83001C4.05743 1.3335 3.67114 1.3335 3.35959 1.4419C2.76881 1.64747 2.30499 2.12496 2.1053 2.73314C2 3.05386 2 3.45153 2 4.24687V13.583C2 14.1551 2.65666 14.4587 3.07206 14.0786C3.31612 13.8552 3.68388 13.8552 3.92794 14.0786L4.25 14.3733C4.67772 14.7647 5.32228 14.7647 5.75 14.3733C6.17772 13.9819 6.82228 13.9819 7.25 14.3733C7.67772 14.7647 8.32228 14.7647 8.75 14.3733C9.17772 13.9819 9.82228 13.9819 10.25 14.3733C10.6777 14.7647 11.3223 14.7647 11.75 14.3733L12.0721 14.0786C12.3161 13.8552 12.6839 13.8552 12.9279 14.0786C13.3433 14.4587 14 14.1551 14 13.583V4.24687C14 3.45153 14 3.05386 13.8947 2.73314C13.695 2.12496 13.2312 1.64747 12.6404 1.4419C12.3289 1.3335 11.9426 1.3335 11.17 1.3335Z'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path d='M7 7.3335L11.3333 7.3335' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M4.66669 7.3335H5.00002' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M4.66669 5H5.00002' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M4.66669 9.6665H5.00002' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M7 5H11.3333' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M7 9.6665H11.3333' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
    </svg>
  )
}

export const IStatus = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_213_668)'>
        <path
          d='M14.6666 7.00016V8.00016C14.6666 11.1429 14.6666 12.7142 13.6903 13.6905C12.714 14.6668 11.1427 14.6668 7.99998 14.6668C4.85728 14.6668 3.28593 14.6668 2.30962 13.6905C1.33331 12.7142 1.33331 11.1429 1.33331 8.00016C1.33331 4.85747 1.33331 3.28612 2.30962 2.30981C3.28593 1.3335 4.85728 1.3335 7.99998 1.3335H8.99998'
          stroke='#99A0AE'
          strokeWidth='1.25'
          strokeLinecap='round'
        />
        <circle cx='12.6667' cy='3.3335' r='2' stroke='#99A0AE' strokeWidth='1.25' />
      </g>
      <defs>
        <clipPath id='clip0_213_668'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IBranch = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_213_962)'>
        <path d='M14.6666 14.6665L1.33331 14.6665' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
        <path
          d='M11.3334 14.6668V4.00016C11.3334 2.74308 11.3334 2.11454 10.9428 1.72402C10.5523 1.3335 9.92377 1.3335 8.66669 1.3335H7.33335C6.07627 1.3335 5.44774 1.3335 5.05721 1.72402C4.66669 2.11454 4.66669 2.74308 4.66669 4.00016V14.6668'
          stroke='#99A0AE'
          strokeWidth='1.25'
        />
        <path
          d='M14 14.6668V7.66683C14 6.73052 14 6.26237 13.7753 5.92607C13.678 5.78048 13.553 5.65548 13.4074 5.5582C13.0711 5.3335 12.603 5.3335 11.6667 5.3335'
          stroke='#99A0AE'
          strokeWidth='1.25'
        />
        <path
          d='M2 14.6668V7.66683C2 6.73052 2 6.26237 2.22471 5.92607C2.32199 5.78048 2.44699 5.65548 2.59257 5.5582C2.92887 5.3335 3.39703 5.3335 4.33333 5.3335'
          stroke='#99A0AE'
          strokeWidth='1.25'
        />
        <path d='M8 14.6665V12.6665' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
        <path d='M6.66669 3.3335H9.33335' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
        <path d='M6.66669 5.3335H9.33335' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
        <path d='M6.66669 7.3335H9.33335' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
        <path d='M6.66669 9.3335H9.33335' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_213_962'>
          <rect width='16' height='16' rx='3' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IStore = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M2.33331 7.3335V9.3335C2.33331 11.8477 2.33331 13.1047 3.11436 13.8858C3.89541 14.6668 5.15249 14.6668 7.66665 14.6668H8.33331C10.8475 14.6668 12.1045 14.6668 12.8856 13.8858C13.6666 13.1047 13.6666 11.8477 13.6666 9.3335V7.3335'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path
        d='M6.33336 1.3335H9.66669L10.1012 5.67841C10.2255 6.9215 9.24932 8.00016 8.00003 8.00016C6.75074 8.00016 5.77456 6.9215 5.89887 5.67841L6.33336 1.3335Z'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path
        d='M2.2198 3.56771C2.33851 2.97412 2.39787 2.67732 2.51857 2.43672C2.77288 1.92976 3.23049 1.55461 3.77748 1.40466C4.03707 1.3335 4.33975 1.3335 4.9451 1.3335H6.33331L5.85029 6.16368C5.74603 7.20623 4.86875 8.00016 3.82099 8.00016C2.53403 8.00016 1.56879 6.82276 1.82118 5.56078L2.2198 3.56771Z'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path
        d='M13.7802 3.56771C13.6615 2.97412 13.6021 2.67732 13.4814 2.43672C13.2271 1.92976 12.7695 1.55461 12.2225 1.40466C11.9629 1.3335 11.6602 1.3335 11.0549 1.3335H9.66669L10.1497 6.16368C10.254 7.20623 11.1312 8.00016 12.179 8.00016C13.466 8.00016 14.4312 6.82276 14.1788 5.56078L13.7802 3.56771Z'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path
        d='M6.33331 14.3332V12.3332C6.33331 11.7101 6.33331 11.3986 6.46729 11.1665C6.55506 11.0145 6.68129 10.8882 6.83331 10.8005C7.06536 10.6665 7.3769 10.6665 7.99998 10.6665C8.62306 10.6665 8.9346 10.6665 9.16665 10.8005C9.31867 10.8882 9.4449 11.0145 9.53267 11.1665C9.66665 11.3986 9.66665 11.7101 9.66665 12.3332V14.3332'
        stroke='#99A0AE'
        strokeWidth='1.25'
        strokeLinecap='round'
      />
    </svg>
  )
}

export const ITime = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M1.33331 7.99984C1.33331 5.48568 1.33331 4.2286 2.11436 3.44755C2.89541 2.6665 4.15249 2.6665 6.66665 2.6665H9.33331C11.8475 2.6665 13.1045 2.6665 13.8856 3.44755C14.6666 4.2286 14.6666 5.48568 14.6666 7.99984V9.33317C14.6666 11.8473 14.6666 13.1044 13.8856 13.8855C13.1045 14.6665 11.8475 14.6665 9.33331 14.6665H6.66665C4.15249 14.6665 2.89541 14.6665 2.11436 13.8855C1.33331 13.1044 1.33331 11.8473 1.33331 9.33317V7.99984Z'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path d='M4.66669 2.6665V1.6665' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M11.3333 2.6665V1.6665' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M1.66669 6H14.3334' stroke='#99A0AE' strokeWidth='1.25' strokeLinecap='round' />
      <path
        d='M11.375 11.3333C11.375 11.3563 11.3564 11.375 11.3334 11.375C11.3103 11.375 11.2917 11.3563 11.2917 11.3333C11.2917 11.3103 11.3103 11.2917 11.3334 11.2917C11.3564 11.2917 11.375 11.3103 11.375 11.3333ZM11.375 8.66667C11.375 8.68968 11.3564 8.70833 11.3334 8.70833C11.3103 8.70833 11.2917 8.68968 11.2917 8.66667C11.2917 8.64365 11.3103 8.625 11.3334 8.625C11.3564 8.625 11.375 8.64365 11.375 8.66667Z'
        fill='#99A0AE'
        stroke='#99A0AE'
        strokeWidth='1.25'
      />
      <path
        d='M8.66665 11.3333C8.66665 11.7015 8.36817 12 7.99998 12C7.63179 12 7.33331 11.7015 7.33331 11.3333C7.33331 10.9651 7.63179 10.6667 7.99998 10.6667C8.36817 10.6667 8.66665 10.9651 8.66665 11.3333Z'
        fill='#99A0AE'
      />
      <path
        d='M8.66665 8.66667C8.66665 9.03486 8.36817 9.33333 7.99998 9.33333C7.63179 9.33333 7.33331 9.03486 7.33331 8.66667C7.33331 8.29848 7.63179 8 7.99998 8C8.36817 8 8.66665 8.29848 8.66665 8.66667Z'
        fill='#99A0AE'
      />
      <path
        d='M5.33333 11.3333C5.33333 11.7015 5.03486 12 4.66667 12C4.29848 12 4 11.7015 4 11.3333C4 10.9651 4.29848 10.6667 4.66667 10.6667C5.03486 10.6667 5.33333 10.9651 5.33333 11.3333Z'
        fill='#99A0AE'
      />
      <path
        d='M5.33333 8.66667C5.33333 9.03486 5.03486 9.33333 4.66667 9.33333C4.29848 9.33333 4 9.03486 4 8.66667C4 8.29848 4.29848 8 4.66667 8C5.03486 8 5.33333 8.29848 5.33333 8.66667Z'
        fill='#99A0AE'
      />
    </svg>
  )
}

export const IEdit = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_213_2166)'>
        <path
          d='M9.57342 2.71927L10.1913 2.10135C11.2151 1.07755 12.8751 1.07755 13.8989 2.10135C14.9227 3.12515 14.9227 4.78506 13.8989 5.80887L13.2809 6.42679M9.57342 2.71927C9.57342 2.71927 9.65066 4.03235 10.8093 5.19095C11.9679 6.34955 13.2809 6.42679 13.2809 6.42679M9.57342 2.71927L3.89259 8.4001C3.50782 8.78487 3.31543 8.97726 3.14997 9.18939C2.9548 9.43962 2.78746 9.71037 2.65094 9.99685C2.5352 10.2397 2.44916 10.4978 2.27708 11.014L1.54791 13.2015M13.2809 6.42679L7.60011 12.1076C7.21533 12.4924 7.02295 12.6848 6.81082 12.8502C6.56059 13.0454 6.28984 13.2127 6.00336 13.3493C5.7605 13.465 5.50239 13.551 4.98616 13.7231L2.79865 14.4523M2.79865 14.4523L2.26393 14.6305C2.00989 14.7152 1.72981 14.6491 1.54046 14.4597C1.35111 14.2704 1.28499 13.9903 1.36967 13.7363L1.54791 13.2015M2.79865 14.4523L1.54791 13.2015'
          stroke='#99A0AE'
          strokeWidth='1.25'
        />
      </g>
      <defs>
        <clipPath id='clip0_213_2166'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IHome = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_229_1284)'>
        <path
          d='M1.66675 10.1698C1.66675 8.26275 1.66675 7.30923 2.09942 6.51879C2.53208 5.72834 3.32254 5.23777 4.90344 4.25661L6.57011 3.22223C8.24124 2.18508 9.0768 1.6665 10.0001 1.6665C10.9234 1.6665 11.7589 2.18508 13.4301 3.22223L15.0967 4.25661C16.6776 5.23776 17.4681 5.72834 17.9007 6.51879C18.3334 7.30923 18.3334 8.26275 18.3334 10.1698V11.4373C18.3334 14.688 18.3334 16.3134 17.3571 17.3233C16.3808 18.3332 14.8094 18.3332 11.6667 18.3332H8.33341C5.19072 18.3332 3.61937 18.3332 2.64306 17.3233C1.66675 16.3134 1.66675 14.688 1.66675 11.4373V10.1698Z'
          stroke='currentColor'
          strokeWidth='1.5'
        />
        <path d='M12.5 15H7.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
      </g>
      <defs>
        <clipPath id='clip0_229_1284'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ICard = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_535_2595)'>
        <path
          d='M20 6.3667L20 5.31201C20 4.10389 19.0206 3.12451 17.8125 3.12451L2.1875 3.12451C0.979375 3.12451 0 4.10389 0 5.31201L0 6.3667C0 6.47455 0.0874609 6.56201 0.195312 6.56201L19.8047 6.56201C19.9125 6.56201 20 6.47455 20 6.3667Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M0.000106812 8.00732L0.000106812 14.687C0.000106812 15.8951 0.979482 16.8745 2.18761 16.8745L17.8126 16.8745C19.0207 16.8745 20.0001 15.8951 20.0001 14.687L20.0001 8.00732C20.0001 7.89947 19.9126 7.81201 19.8048 7.81201L0.195419 7.81201C0.0875678 7.81201 0.000106812 7.89947 0.000106812 8.00732ZM5.00011 13.1245C5.00011 13.4697 4.72026 13.7495 4.37511 13.7495L3.75011 13.7495C3.40495 13.7495 3.12511 13.4697 3.12511 13.1245L3.12511 12.4995C3.12511 12.1544 3.40495 11.8745 3.75011 11.8745L4.37511 11.8745C4.72026 11.8745 5.00011 12.1544 5.00011 12.4995L5.00011 13.1245Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_535_2595'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IEcom = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_535_2505)'>
        <path
          d='M17.6543 0H2.3457C1.05234 0 0 1.05234 0 2.3457V4.6875H20V2.3457C20 1.05234 18.9477 0 17.6543 0ZM2.53906 2.92969C2.21563 2.92969 1.95312 2.66719 1.95312 2.34375C1.95312 2.02031 2.21563 1.75781 2.53906 1.75781C2.8625 1.75781 3.125 2.02031 3.125 2.34375C3.125 2.66719 2.8625 2.92969 2.53906 2.92969ZM4.88281 2.92969C4.55937 2.92969 4.29688 2.66719 4.29688 2.34375C4.29688 2.02031 4.55937 1.75781 4.88281 1.75781C5.20625 1.75781 5.46875 2.02031 5.46875 2.34375C5.46875 2.66719 5.20625 2.92969 4.88281 2.92969ZM17.4609 2.92969H8.00781C7.68437 2.92969 7.42188 2.66719 7.42188 2.34375C7.42188 2.02031 7.68437 1.75781 8.00781 1.75781H17.4609C17.7844 1.75781 18.0469 2.02031 18.0469 2.34375C18.0469 2.66719 17.7844 2.92969 17.4609 2.92969Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M8.00482 17.0703C8.32843 17.0703 8.59076 16.808 8.59076 16.4844C8.59076 16.1608 8.32843 15.8984 8.00482 15.8984C7.68122 15.8984 7.41888 16.1608 7.41888 16.4844C7.41888 16.808 7.68122 17.0703 8.00482 17.0703Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M13.5127 17.0703C13.8363 17.0703 14.0987 16.808 14.0987 16.4844C14.0987 16.1608 13.8363 15.8984 13.5127 15.8984C13.1891 15.8984 12.9268 16.1608 12.9268 16.4844C12.9268 16.808 13.1891 17.0703 13.5127 17.0703Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M14.4321 11.168C14.5907 11.3848 14.6349 11.6562 14.5532 11.9121L13.6552 14.7254H7.64346L6.92159 11.7453V11.7437L6.70206 10.8262H13.7599C14.0286 10.8262 14.2735 10.9508 14.4321 11.168Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M-0.000183105 5.85938V17.6543C-0.000183105 18.9477 1.05216 20 2.34552 20H17.6541C18.9475 20 19.9998 18.9477 19.9998 17.6543V5.85938H-0.000183105ZM15.6697 12.2691L14.7287 15.2172C15.0623 15.5371 15.2705 15.9867 15.2705 16.4844C15.2705 17.4535 14.4818 18.2422 13.5127 18.2422C12.5436 18.2422 11.7549 17.4535 11.7549 16.4844C11.7549 16.2785 11.7908 16.0813 11.8561 15.8977H9.66154C9.72677 16.0813 9.76271 16.2785 9.76271 16.4844C9.76271 17.4535 8.97404 18.2422 8.00489 18.2422C7.03575 18.2422 6.24708 17.4535 6.24708 16.4844C6.24708 16.0848 6.38107 15.7164 6.60646 15.4207L5.00997 8.78906H3.25724C2.93341 8.78906 2.6713 8.52656 2.6713 8.20313C2.6713 7.87969 2.93341 7.61719 3.25724 7.61719H5.47247C5.73736 7.61465 5.98314 7.80867 6.0424 8.0668L6.42208 9.65469H13.7604C14.4072 9.65469 14.9975 9.9543 15.3791 10.477C15.7608 10.9992 15.8666 11.6527 15.6697 12.2691Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_535_2505'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IVA = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='18' viewBox='0 0 22 18' fill='none'>
      <path
        d='M8.59375 10.9531H7.03125C6.81551 10.9531 6.64062 11.128 6.64062 11.3438V12.125C6.64062 12.3407 6.81551 12.5156 7.03125 12.5156H8.59375C8.80949 12.5156 8.98438 12.3407 8.98438 12.125V11.3438C8.98438 11.128 8.80949 10.9531 8.59375 10.9531Z'
        fill='var(--secondary-color)'
      />
      <path
        d='M15.625 8.21875H6.25C5.17311 8.21875 4.29688 9.09498 4.29688 10.1719V15.6406C4.29688 16.7175 5.17311 17.5938 6.25 17.5938H15.625C16.7019 17.5938 17.5781 16.7175 17.5781 15.6406V10.1719C17.5781 9.09498 16.7019 8.21875 15.625 8.21875ZM13.0859 10.5625H13.4766C13.6925 10.5625 13.8672 10.7372 13.8672 10.9531C13.8672 11.169 13.6925 11.3438 13.4766 11.3438H13.0859C12.87 11.3438 12.6953 11.169 12.6953 10.9531C12.6953 10.7372 12.87 10.5625 13.0859 10.5625ZM9.76562 12.125C9.76562 12.7712 9.23996 13.2969 8.59375 13.2969H7.03125C6.38504 13.2969 5.85938 12.7712 5.85938 12.125V11.3438C5.85938 10.6975 6.38504 10.1719 7.03125 10.1719H8.59375C9.23996 10.1719 9.76562 10.6975 9.76562 11.3438V12.125ZM11.3281 11.3438H10.9375C10.7216 11.3438 10.5469 11.169 10.5469 10.9531C10.5469 10.7372 10.7216 10.5625 10.9375 10.5625H11.3281C11.544 10.5625 11.7188 10.7372 11.7188 10.9531C11.7188 11.169 11.544 11.3438 11.3281 11.3438ZM15.625 12.9062H13.0859C12.87 12.9062 12.6953 12.7315 12.6953 12.5156C12.6953 12.2997 12.87 12.125 13.0859 12.125H15.625C15.8409 12.125 16.0156 12.2997 16.0156 12.5156C16.0156 12.7315 15.8409 12.9062 15.625 12.9062ZM15.625 11.3438H15.2344C15.0185 11.3438 14.8438 11.169 14.8438 10.9531C14.8438 10.7372 15.0185 10.5625 15.2344 10.5625H15.625C15.8409 10.5625 16.0156 10.7372 16.0156 10.9531C16.0156 11.169 15.8409 11.3438 15.625 11.3438Z'
        fill='currentColor'
      />
      <path
        d='M18.6484 5.15624C18.0078 3.99609 16.7969 3.23046 15.4492 3.14842C14.582 1.47656 12.8359 0.40625 10.9375 0.40625C9.03907 0.40625 7.29687 1.47265 6.42579 3.14842C5.09374 3.22264 3.87108 3.98827 3.22657 5.15624C1.3711 5.47655 0 7.09375 0 9C0 11.0195 1.54688 12.6914 3.51562 12.8867V10.1719C3.51562 8.66407 4.74219 7.4375 6.25 7.4375H15.625C17.1328 7.4375 18.3594 8.66407 18.3594 10.1719V12.8867C20.3281 12.6914 21.875 11.0195 21.875 9C21.875 7.09375 20.5039 5.47655 18.6484 5.15624Z'
        fill='var(--secondary-color)'
      />
    </svg>
  )
}

export const IWallet = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_535_2548)'>
        <path
          d='M4.67371 4.05545L12.6468 1.87914L12.2245 1.02569C11.9483 0.470943 11.2745 0.241858 10.7197 0.518107L3.58218 4.05545H4.67371Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M15.1219 1.94189C15.0231 1.94189 14.9242 1.95537 14.8254 1.98232L12.9523 2.49439L7.23193 4.05531H13.7249H16.5548L16.2044 2.77064C16.0697 2.2698 15.616 1.94189 15.1219 1.94189Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M17.7249 4.8418H17.4688H17.1207H16.7726H14.1157L4.35261 4.8418H3.07243H1.99439H1.7945H1.12521C0.770354 4.8418 0.453678 5.00575 0.247052 5.26403C0.152723 5.38307 0.0808535 5.52007 0.0404267 5.67054C0.0157215 5.76487 0 5.86369 0 5.96476L0 6.09952L0 7.3797L0 18.4768C0 19.0967 0.503088 19.5998 1.12296 19.5998H17.7226C18.3425 19.5998 18.8456 19.0967 18.8456 18.4768V15.3438L12.1819 15.3438C11.1286 15.3438 10.2729 14.4881 10.2729 13.4347V12.4061V12.058V11.7098V10.9372C10.2729 10.4207 10.4795 9.95131 10.8141 9.60768C11.1106 9.30221 11.5081 9.09559 11.9528 9.04393C12.027 9.03497 12.1033 9.03046 12.1797 9.03046H17.9113H18.2594H18.6075H18.8456V5.96476C18.8478 5.34489 18.3448 4.8418 17.7249 4.8418Z'
          fill='currentColor'
        />
        <path
          d='M19.6339 10.1038C19.5216 10.0005 19.3891 9.92184 19.2409 9.87019C19.1263 9.83203 19.005 9.80957 18.877 9.80957H18.8478H18.8254H18.4772H17.2218H12.1819C11.562 9.80957 11.0589 10.3126 11.0589 10.9325V11.4917V11.8399V12.188V13.4322C11.0589 14.0521 11.562 14.5552 12.1819 14.5552H18.8478H18.877C19.005 14.5552 19.1263 14.5327 19.2409 14.4946C19.3891 14.4451 19.5216 14.3643 19.6339 14.261C19.8585 14.0566 20 13.7601 20 13.4323V10.9325C20 10.6046 19.8585 10.3081 19.6339 10.1038ZM14.5266 12.4058C14.5266 12.7158 14.2751 12.9673 13.9652 12.9673H13.5923C13.2824 12.9673 13.0309 12.7158 13.0309 12.4058V12.033C13.0309 11.8533 13.114 11.6939 13.2465 11.5928C13.343 11.5187 13.4621 11.4715 13.5923 11.4715H13.6867H13.9652C14.2751 11.4715 14.5266 11.7231 14.5266 12.033V12.4058Z'
          fill='var(--secondary-color)'
        />
      </g>
      <defs>
        <clipPath id='clip0_535_2548'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ICash = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_535_3395)'>
        <path
          d='M0.272565 10.0258C0.0575725 10.105 -0.0532439 10.3433 0.0250737 10.5591L0.286744 11.2791L2.27913 9.28662L0.272565 10.0258Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M2.15829 16.4258L3.35825 19.7257C3.39575 19.8298 3.47407 19.9148 3.57407 19.9607C3.62988 19.9865 3.68988 19.9998 3.74988 19.9998C3.79906 19.9998 3.84738 19.9915 3.89405 19.974L5.21819 19.4857L2.15829 16.4258Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M19.9743 13.6087L18.6011 9.83301L15.3054 13.1287L16.9386 12.5271C17.1528 12.4454 17.3944 12.5571 17.4736 12.7737C17.5536 12.9895 17.4428 13.2295 17.2269 13.3087L15.152 14.0737C15.1045 14.0912 15.0562 14.0995 15.0078 14.0995C14.8387 14.0995 14.6787 13.9953 14.617 13.827C14.6162 13.8245 14.617 13.822 14.6162 13.8195L11.1238 17.3119L19.7268 14.142C19.9427 14.0629 20.0535 13.8245 19.9743 13.6087Z'
          fill='var(--secondary-color)'
        />
        <path
          d='M19.8777 6.7888L13.2112 0.122359C13.0487 -0.0401353 12.7846 -0.0401353 12.6221 0.122359L0.122512 12.6219C-0.0399827 12.7844 -0.0399827 13.0486 0.122512 13.2111L6.78896 19.8775C6.86977 19.9592 6.97645 20 7.08313 20C7.1898 20 7.29644 19.9592 7.37812 19.8783L19.8777 7.37878C20.0402 7.21547 20.0402 6.95212 19.8777 6.7888ZM4.8782 11.5453L3.21158 13.2119C3.12991 13.2927 3.02327 13.3336 2.91659 13.3336C2.80992 13.3336 2.70328 13.2928 2.6216 13.2119C2.45911 13.0494 2.45911 12.7852 2.6216 12.6227L4.28822 10.9561C4.45072 10.7936 4.71489 10.7936 4.87738 10.9561C5.03988 11.1187 5.0407 11.382 4.8782 11.5453ZM12.0738 12.0744C11.7788 12.3694 11.378 12.5111 10.9321 12.5111C10.1955 12.5111 9.33469 12.1253 8.61471 11.4045C8.08805 10.8778 7.71806 10.2478 7.57393 9.6312C7.41393 8.94373 7.54561 8.34543 7.94474 7.94544C8.3439 7.54546 8.9422 7.41378 9.6305 7.57464C10.2472 7.71881 10.8771 8.08794 11.4038 8.61542C12.5613 9.77202 12.8562 11.292 12.0738 12.0744ZM17.3778 7.37878L15.7111 9.0454C15.6295 9.12622 15.5228 9.16708 15.4162 9.16708C15.3095 9.16708 15.2028 9.12626 15.1212 9.0454C14.9587 8.88291 14.9587 8.61874 15.1212 8.45625L16.7878 6.78962C16.9503 6.62713 17.2144 6.62713 17.3769 6.78962C17.5394 6.95212 17.5403 7.21547 17.3778 7.37878Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_535_3395'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IOverview() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.9346 15.8715C15.7312 15.6935 15.4739 15.5888 15.2039 15.5743C14.9339 15.5597 14.6668 15.6361 14.4454 15.7911C13.4736 16.3954 12.3105 16.6141 11.1853 16.4042C10.2776 16.2321 9.44447 15.7863 8.79832 15.1267C8.15217 14.4671 7.72403 13.6255 7.5716 12.7153C7.41918 11.8052 7.5498 10.8702 7.94587 10.0365C8.34193 9.20281 8.98439 8.51043 9.78664 8.05271C10.1342 7.85427 10.5063 7.70223 10.8935 7.60046C11.1668 7.55261 11.4156 7.41294 11.5986 7.20458C11.7816 6.99622 11.8878 6.73168 11.8997 6.45477L11.7487 2.92725C11.7477 2.7992 11.7204 2.67274 11.6684 2.55569C11.6164 2.43864 11.5409 2.3335 11.4466 2.24681C11.3522 2.16012 11.241 2.09371 11.1199 2.05171C10.9988 2.00971 10.8704 1.99301 10.7425 2.00265C9.44176 2.17854 8.18759 2.60519 7.04975 3.25889C5.63062 4.07319 4.43178 5.22094 3.55718 6.60263C2.68259 7.98432 2.15864 9.55824 2.03076 11.1879C1.90289 12.8176 2.17495 14.4538 2.82336 15.9547C3.47176 17.4556 4.47694 18.7759 5.75173 19.8011C7.54809 21.2349 9.78194 22.0109 12.0814 21.9999C14.3809 21.9889 16.6072 21.1916 18.3897 19.7408C18.4868 19.6531 18.5644 19.5461 18.6175 19.4267C18.6707 19.3073 18.6981 19.178 18.6981 19.0473C18.6981 18.9166 18.6707 18.7874 18.6175 18.6679C18.5644 18.5485 18.4868 18.4415 18.3897 18.3539L15.9346 15.8715Z'
        fill='currentColor'
      />
      <path
        d='M13.7815 7.8112C14.3465 8.04833 14.8574 8.39781 15.2829 8.83838C15.7084 9.27896 16.0398 9.8014 16.2567 10.374C16.343 10.6313 16.5122 10.8528 16.738 11.0038C16.9638 11.1547 17.2334 11.2266 17.5044 11.2081L20.9859 10.7157C21.1154 10.7004 21.2403 10.6583 21.3525 10.592C21.4648 10.5257 21.5619 10.4367 21.6377 10.3307C21.7135 10.2247 21.7663 10.1041 21.7927 9.97652C21.819 9.84898 21.8184 9.71733 21.7909 9.59003C21.3662 7.86332 20.4905 6.28003 19.2531 5.00176C18.0157 3.72348 16.4609 2.79598 14.7474 2.31389C14.6209 2.28151 14.4889 2.27597 14.3601 2.29761C14.2312 2.31926 14.1083 2.36761 13.9993 2.43956C13.8903 2.51151 13.7976 2.60546 13.7272 2.71537C13.6567 2.82527 13.6101 2.94869 13.5903 3.07768L13.0369 6.53486C13.0069 6.79931 13.0631 7.06638 13.1973 7.29633C13.3314 7.52629 13.5363 7.70686 13.7815 7.8112Z'
        fill='var(--secondary-color)'
      />
      <path
        d='M21.0866 12.4446L17.5849 12.2637C17.3118 12.2635 17.0467 12.3558 16.8328 12.5256C16.619 12.6953 16.4691 12.9325 16.4077 13.1983C16.4077 13.2787 16.3574 13.3591 16.3372 13.4496C16.2359 13.7001 16.2225 13.9776 16.2992 14.2368C16.376 14.4959 16.5383 14.7215 16.7599 14.8767L19.7785 16.7258C19.8857 16.7934 20.0056 16.8383 20.1309 16.8576C20.2562 16.877 20.3841 16.8705 20.5067 16.8384C20.6294 16.8064 20.7441 16.7495 20.8439 16.6714C20.9436 16.5932 21.0262 16.4955 21.0866 16.3841C21.531 15.4733 21.8363 14.501 21.9921 13.4998C22.0091 13.3698 21.9985 13.2378 21.9611 13.1122C21.9236 12.9865 21.8601 12.8702 21.7747 12.7707C21.6893 12.6712 21.5839 12.5907 21.4654 12.5345C21.3468 12.4784 21.2177 12.4477 21.0866 12.4446Z'
        fill='var(--secondary-color)'
      />
    </svg>
  )
}
