export const IHome = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M2.3999 12.2039C2.3999 9.91549 2.3999 8.77128 2.9191 7.82274C3.4383 6.87421 4.38685 6.28551 6.28394 5.10813L8.28393 3.86687C10.2893 2.62229 11.292 2 12.3999 2C13.5078 2 14.5105 2.62229 16.5159 3.86687L18.5159 5.10812C20.413 6.28551 21.3615 6.87421 21.8807 7.82274C22.3999 8.77128 22.3999 9.91549 22.3999 12.2039V13.725C22.3999 17.6258 22.3999 19.5763 21.2283 20.7881C20.0568 22 18.1711 22 14.3999 22H10.3999C6.62867 22 4.74305 22 3.57148 20.7881C2.3999 19.5763 2.3999 17.6258 2.3999 13.725V12.2039Z'
        stroke='var(--decor-color)'
        fill='var(--fill-color)'
        strokeWidth='1.5'
      />
      <path d='M15.3999 18H9.3999' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  )
}

export const ITransaction = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M20.0281 3.17157C18.8566 2 16.9709 2 13.1997 2H11.1997C7.42847 2 5.54285 2 4.37128 3.17157C3.19971 4.34315 3.19971 6.22876 3.19971 10V14C3.19971 17.7712 3.19971 19.6569 4.37128 20.8284C5.54285 22 7.42847 22 11.1997 22H13.1997C16.9709 22 18.8566 22 20.0281 20.8284C20.9712 19.8853 21.1551 18.4796 21.191 16'
        stroke='var(--stroke-color)'
        strokeWidth='1.5'
        strokeLinecap='round'
      />

      <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          d='M13 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3 4.34315 3 6.22876 3 10V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284C20.7715 19.8853 20.9641 16.4796 21 14C21.0359 11.5204 21 12.5 21 10C21 7.5 21 4.34315 19.8284 3.17157C18.6569 2 16.7712 2 13 2Z'
          fill='var(--fill-color)'
        />
      </svg>

      {/* pencil */}
      <path
        d='M18.3797 8.03933L18.8432 7.57589C19.611 6.80804 20.856 6.80804 21.6238 7.57589C22.3917 8.34374 22.3917 9.58868 21.6238 10.3565L21.1604 10.82M18.3797 8.03933C18.3797 8.03933 18.4377 9.02414 19.3066 9.89309C20.1756 10.762 21.1604 10.82 21.1604 10.82M18.3797 8.03933L14.1191 12.2999C13.8305 12.5885 13.6862 12.7328 13.5622 12.8919C13.4158 13.0796 13.2903 13.2827 13.1879 13.4975C13.1011 13.6797 13.0365 13.8732 12.9075 14.2604L12.4943 15.5L12.3606 15.901M21.1604 10.82L16.8998 15.0806C16.6112 15.3692 16.4669 15.5135 16.3078 15.6376C16.1201 15.7839 15.9171 15.9094 15.7022 16.0118C15.5201 16.0986 15.3265 16.1632 14.9393 16.2922L13.6997 16.7054L13.2987 16.8391M13.2987 16.8391L12.8976 16.9728C12.7071 17.0363 12.497 16.9867 12.355 16.8447C12.213 16.7027 12.1634 16.4926 12.2269 16.3021L12.3606 15.901M13.2987 16.8391L12.3606 15.901'
        stroke='var(--stroke-color)'
        strokeWidth='1.5'
        fill='var(--fill-color)'
      />

      {/* line */}
      <path d='M8.19971 13H10.6997' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M8.19971 9H14.6997' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M8.19971 17H9.69971' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  )
}

export const IReport = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M17.625 20.9999C18.2666 20.4296 19.2334 20.4296 19.875 20.9999C20.3109 21.3874 21 21.0779 21 20.4947V3.50495C21 2.92172 20.3109 2.61227 19.875 2.99975C19.2334 3.57004 18.2666 3.57004 17.625 2.99975C16.9834 2.42945 16.0166 2.42945 15.375 2.99975C14.7334 3.57004 13.7666 3.57004 13.125 2.99975C12.4834 2.42945 11.5166 2.42945 10.875 2.99975C10.2334 3.57004 9.26659 3.57004 8.625 2.99975C7.98341 2.42945 7.01659 2.42945 6.375 2.99975C5.73341 3.57004 4.76659 3.57004 4.125 2.99975C3.68909 2.61227 3 2.92172 3 3.50494V20.4947C3 21.0779 3.68909 21.3874 4.125 20.9999C4.76659 20.4296 5.73341 20.4296 6.375 20.9999C7.01659 21.5702 7.98341 21.5702 8.625 20.9999C9.26659 20.4296 10.2334 20.4296 10.875 20.9999C11.5166 21.5702 12.4834 21.5702 13.125 20.9999C13.7666 20.4296 14.7334 20.4296 15.375 20.9999C16.0166 21.5702 16.9834 21.5702 17.625 20.9999Z'
        stroke='var(--decor-color)'
        strokeWidth='1.5'
        fill='var(--fill-color)'
      />
      <path d='M7.5 15.5H16.5' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M7.5 12H16.5' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M7.5 8.5H16.5' stroke='var(--stroke-color)' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  )
}

export const IReward = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M9.95297 5.40838C11.2196 3.13613 11.8529 2 12.7998 2C13.7467 2 14.38 3.13612 15.6466 5.40837L15.9743 5.99623C16.3343 6.64193 16.5143 6.96479 16.7949 7.17781C17.0755 7.39083 17.425 7.4699 18.1239 7.62805L18.7603 7.77203C21.2199 8.32856 22.4498 8.60682 22.7424 9.54773C23.035 10.4886 22.1966 11.4691 20.5197 13.4299L20.0859 13.9372C19.6094 14.4944 19.3711 14.773 19.2639 15.1177C19.1568 15.4624 19.1928 15.8341 19.2648 16.5776L19.3304 17.2544C19.5839 19.8706 19.7107 21.1787 18.9447 21.7602C18.1786 22.3417 17.0271 21.8115 14.7241 20.7512L14.1283 20.4768C13.4739 20.1755 13.1467 20.0248 12.7998 20.0248C12.453 20.0248 12.1257 20.1755 11.4713 20.4768L10.8755 20.7512C8.57248 21.8115 7.42098 22.3417 6.65495 21.7602C5.88893 21.1787 6.01568 19.8706 6.2692 17.2544L6.33479 16.5776C6.40683 15.8341 6.44285 15.4624 6.33567 15.1177C6.22848 14.773 5.99023 14.4944 5.51372 13.9372L5.0799 13.4299C3.40305 11.4691 2.56463 10.4886 2.85723 9.54773C3.14982 8.60682 4.37966 8.32856 6.83934 7.77203L7.47569 7.62805C8.17466 7.4699 8.52414 7.39083 8.80475 7.17781C9.08536 6.96479 9.26533 6.64194 9.62527 5.99623L9.95297 5.40838Z'
        stroke='var(--decor-color)'
        strokeWidth='1.5'
        fill='var(--fill-color)'
      />
    </svg>
  )
}

export const IMoreApp = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M3.1001 6.5C3.1001 4.29086 4.89096 2.5 7.1001 2.5C9.30924 2.5 11.1001 4.29086 11.1001 6.5C11.1001 8.70914 9.30924 10.5 7.1001 10.5C4.89096 10.5 3.1001 8.70914 3.1001 6.5Z'
        stroke='var(--decor-color)'
        fill='var(--fill-color)'
        strokeWidth='1.5'
      />
      <path
        d='M14.1001 17.5C14.1001 15.2909 15.891 13.5 18.1001 13.5C20.3092 13.5 22.1001 15.2909 22.1001 17.5C22.1001 19.7091 20.3092 21.5 18.1001 21.5C15.891 21.5 14.1001 19.7091 14.1001 17.5Z'
        stroke='var(--decor-color)'
        fill='var(--fill-color)'
        strokeWidth='1.5'
      />
      <path
        d='M22.1001 6.5C22.1001 4.61438 22.1001 3.67157 21.5143 3.08579C20.9285 2.5 19.9857 2.5 18.1001 2.5C16.2145 2.5 15.2717 2.5 14.6859 3.08579C14.1001 3.67157 14.1001 4.61438 14.1001 6.5C14.1001 8.38562 14.1001 9.32843 14.6859 9.91421C15.2717 10.5 16.2145 10.5 18.1001 10.5C19.9857 10.5 20.9285 10.5 21.5143 9.91421C22.1001 9.32843 22.1001 8.38562 22.1001 6.5Z'
        stroke='var(--decor-color)'
        fill='var(--fill-color)'
        strokeWidth='1.5'
      />
      <path
        d='M11.1001 17.5C11.1001 15.6144 11.1001 14.6716 10.5143 14.0858C9.92852 13.5 8.98572 13.5 7.1001 13.5C5.21448 13.5 4.27167 13.5 3.68588 14.0858C3.1001 14.6716 3.1001 15.6144 3.1001 17.5C3.1001 19.3856 3.1001 20.3284 3.68588 20.9142C4.27167 21.5 5.21448 21.5 7.1001 21.5C8.98572 21.5 9.92852 21.5 10.5143 20.9142C11.1001 20.3284 11.1001 19.3856 11.1001 17.5Z'
        stroke='var(--decor-color)'
        fill='var(--fill-color)'
        strokeWidth='1.5'
      />
    </svg>
  )
}

export const ISetting = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M13.7678 10.2322C14.7441 11.2085 14.7441 12.7915 13.7678 13.7678C12.7915 14.7441 11.2085 14.7441 10.2322 13.7678C9.25592 12.7915 9.25592 11.2085 10.2322 10.2322C11.2085 9.25592 12.7915 9.25592 13.7678 10.2322'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8489 4.1202L15.4319 4.3142C15.9659 4.4922 16.3269 4.9922 16.3269 5.5552V6.3922C16.3269 7.1042 16.8949 7.6852 17.6069 7.7002L18.4449 7.7182C18.9299 7.7282 19.3699 8.0072 19.5869 8.4412L19.8619 8.9912C20.1139 9.4952 20.0149 10.1032 19.6169 10.5012L19.0249 11.0932C18.5219 11.5962 18.5129 12.4092 19.0049 12.9232L19.5849 13.5292C19.9209 13.8802 20.0349 14.3872 19.8809 14.8482L19.6869 15.4312C19.5089 15.9652 19.0089 16.3262 18.4459 16.3262H17.6089C16.8969 16.3262 16.3159 16.8942 16.3009 17.6062L16.2829 18.4442C16.2729 18.9292 15.9939 19.3692 15.5599 19.5862L15.0099 19.8612C14.5059 20.1132 13.8979 20.0142 13.4999 19.6162L12.9079 19.0242C12.4049 18.5212 11.5919 18.5122 11.0779 19.0042L10.4719 19.5842C10.1209 19.9202 9.61391 20.0342 9.15291 19.8802L8.56991 19.6862C8.03591 19.5082 7.67491 19.0082 7.67491 18.4452V17.6082C7.67491 16.8962 7.10691 16.3152 6.39491 16.3002L5.55691 16.2822C5.07191 16.2722 4.63191 15.9932 4.41491 15.5592L4.13991 15.0092C3.88791 14.5052 3.98691 13.8972 4.38491 13.4992L4.97691 12.9072C5.47991 12.4042 5.48891 11.5912 4.99691 11.0772L4.41691 10.4712C4.07991 10.1192 3.96591 9.6112 4.11991 9.1512L4.31391 8.5682C4.49191 8.0342 4.99191 7.6732 5.55491 7.6732H6.39191C7.10391 7.6732 7.68491 7.1052 7.69991 6.3932L7.71791 5.5552C7.72991 5.0702 8.00791 4.6302 8.44191 4.4132L8.99191 4.1382C9.49591 3.8862 10.1039 3.9852 10.5019 4.3832L11.0939 4.9752C11.5969 5.4782 12.4099 5.4872 12.9239 4.9952L13.5299 4.4152C13.8809 4.0802 14.3889 3.9662 14.8489 4.1202V4.1202Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const INavigator = () => {
  return (
    <svg width='20' height='4' viewBox='0 0 20 4' fill='none'>
      <g clipPath='url(#clip0_1024_1165)'>
        <path
          d='M0 1.5C0 0.395431 0.895431 -0.5 2 -0.5H18C19.1046 -0.5 20 0.395431 20 1.5C20 2.60457 19.1046 3.5 18 3.5H2C0.895431 3.5 0 2.60457 0 1.5Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1024_1165'>
          <rect width='20' height='3.5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
